import React, { useEffect, useState } from "react";
import { hideComponentLoader, showComponentLoader, showGlobalToast } from "../../../../../utils/UI_methods/global";
import { printSummary } from "../../../../../utils/customizein3d/area3dmodel/helper";
import { applicationConfig, getObjectByParameter, waitFor } from "../../../../../utils/methods";
import { getRandomNumber } from "../../../../../utils/products/productviewer_shopify";
import { getTodayTimestampOnlyDate } from "../../../../../utils/store/crm/calendar";
import { getFormatedDate } from "../../../../../utils/store/crm/crm";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import PricingDetails from "./PricingDetails";
import ProductConfigSummary from "./ProductConfigSummary";

 
const Quotation: React.FC<{
    cartItems:any
    confirmHandle:()=>void
    isShareMode?:boolean
}> = (props) => {


    const[totalMRP,setTotalMrp] = useState(-STORE.currDiscount)
    const[QuotationNumber] = useState("Quo"+getRandomNumber(1,1000))
    const[configuration3D] = useState(getConfig3D())

    useEffect(()=>{
        
        for (const item of props.cartItems) {
            let configuration = item?.configuration

            configuration = JSON.parse(configuration)
        
            setTotalMrp(prevValue=>{
                return prevValue + Number(configuration.price)
            })
            
        }
    },[props.cartItems])

    function getConfig3D(){
        if(props.cartItems.length){
            let item = props.cartItems[0]
            let config3D = item?.configuration_3d
            config3D = JSON.parse(config3D)
            return config3D
        }
        return null
    }


    function DetailsLeft() {
        return(
            <div className="padding5">
                <div className="display-flex-row no-wrap">
                    <span className="heading3 font-md-small">Customer Name:</span>
                    <span className="heading4 font-md-small color-black marginleft5">{STORE.activeCustomer?.customerName}</span>
                </div>

                <div className="display-flex-row no-wrap ion-margin-top">
                    <span className="heading3 font-md-small">Customer Mobile:</span>
                    <span className="heading4 font-md-small color-black marginleft5">{STORE.activeCustomer?.mobile}</span>
                </div>

                <div className="display-flex-row no-wrap ion-margin-top">
                    <span className="heading3 font-md-small">Quotation number:</span>
                    <span className="heading4 font-md-small color-black marginleft5">{QuotationNumber}</span>
                </div>
            </div>
        )
    }

    function DetailsRight() {
        return(
            <div className="padding5 ion-margin-start">
                <div className="display-flex-row no-wrap">
                    <span className="heading3 font-md-small">Prepared by:</span>
                    <span className="heading4 font-md-small color-black marginleft5">TestUser</span>
                </div>

                <div className="display-flex-row no-wrap ion-margin-top">
                    <span className="heading3 font-md-small">Date Prepared:</span>
                    <span className="heading4 font-md-small color-black marginleft5">{getFormatedDate(Number(new Date()))}</span>
                </div>

            </div>
        )
    }


    async function createQuotationHandle() {
        showComponentLoader("mainLoaderSpinner")
        await waitFor(500)
        const customerId = STORE.activeCustomer.id
        if(STORE.activeCustomer){
            let quoteDetails = {
                quoteNo:QuotationNumber,
                quotationDate:getTodayTimestampOnlyDate(),
                cartItems:props.cartItems
            }
            STORE.storeApi.quotation.add(customerId,quoteDetails).then((data:any)=>{
                if(!data.data.error){
                    props.confirmHandle()
                    showGlobalToast("Quotation Created Successfully")
                }else{
                    showGlobalToast(data.data.message,2000,"error")
                }
                hideComponentLoader("mainLoaderSpinner")
            })
        }else{
            alert("Please start customer session")
        }
        hideComponentLoader("mainLoaderSpinner")
        
    }
    
 
  return (
     <div className="full-height-width">
        <div id="cartQuotationWrapper"  className="quote-container border ion-padding bg-color-white hide-scroll-bar overflow-y-scroll" style={{height:"100%"}}>
            {!props.isShareMode?
                <div className="heading1 text-center font-large color-primary">Quotation</div>
            :null}

            {/* {!props.isShareMode?
                <div className="customer-detials display- marginBottom10">
                    <div className="display-flex-row width80 no-wrap ion-margin-top">
                        <div className=""> <DetailsLeft/> </div>
                        <div className=""><DetailsRight/> </div>
                    </div>
                </div>
            :null} */}


            

            <div className="pricing-details padding5 ">
                {configuration3D?
                <PricingDetails configuration3D={configuration3D} cartItems={props.cartItems}/>
                :null}
            </div>


            <div className="pricing-details padding5 marginTop10">
                {props.cartItems.map((currItem:any,index:number)=>{
                    const product = getObjectByParameter(applicationConfig?.data?.productsList,"product_id",currItem.product_id)
                    const configuration = JSON.parse(currItem.configuration)
                    const configuration3D = JSON.parse(currItem.configuration_3d || currItem.configuration3D || currItem.configuration)
                    return(
                        <div className="full-width" key={index}>
                            {props.cartItems.length>1?
                                <div className="heading3 bg-color-primary text-center padding5 color-white">Configuration for product - {product?.product_name}</div>
                            :null}
                            <ProductConfigSummary  product={product} configuration={configuration} configuration3D={configuration3D} />
                        </div>
                    )
                })}
            </div>

            
        </div>

        {!props.isShareMode?
        <div className="actions container center-aligned-column  bottom-middle ion-padding">
        {props.confirmHandle?
            <div className="display-flex-row no-wrap ">
            <Button2
                configObj={{id:""}}
                action={()=>{createQuotationHandle()}}
                label={"Confirm"}
                fontAwesomeIcon={"fas fa-share color-white"}
                iconPos=""
                classNames={"no-shado button-large cart-button bg-color-warning"}
            />
            </div>
        :
            <div className="display-flex-row no-wrap ">
            <Button2
                configObj={{id:""}}
                action={()=>{printSummary("cartQuotationWrapper")}}
                label={"Download Pdf"}
                fontAwesomeIcon={"fas fa-download color-white"}
                iconPos="right"
                classNames={"no-shado button-large cart-button bg-color-warning"}
            />
            </div>}
        </div>
        :
        null}


        
     </div>   
  );
};

export default Quotation;
