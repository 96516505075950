import { IonModal } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { hideProductCheckout } from "../../../../../utils/store/product-cart";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import CartItemsList from "./CartItemsList";
import CheckoutReview from "./CheckoutReview";
import PriceDetails from "./PriceDetails";
import Quotation from "../quotation/Quotation";
import StartCustomerSession from "../customer/StartCustomerSession";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import { getFormattedPrice } from "../../../../../utils/products/productviewer_shopify";
import ShareLink from "../quotation/approval/ShareLink";

 

const ProductCheckout: React.FC<{
  cartItems:any
}> = (props) => {

 
  const[isOpenReview,setIsOpenReview] = useState(false)
  const[isOpenQuote,setIsOpenQuote] = useState(false)
  const[isOpenStartSession,setIsOpenStartSession] = useState(false)

  const[cartItems,setCartItems] = useState(props.cartItems)
  const[isCheckoutFromQuotation,setIsCheckoutFromQuotation] = useState(false)

  const[totalPrice,setTotalPrice] = useState(0)
  const[priceWithDiscount,setPriceWithDiscount] = useState(0)

 

  useEffect(()=>{
    STORE.functionRefs.checkout.setCartItems = setCartItems
    STORE.functionRefs.checkout.setIsCheckoutFromQuotation = setIsCheckoutFromQuotation
  },[])

  useEffect(()=>{
      let sum = 0
      for (const item of cartItems) {
        let configuration = JSON.parse(item.configuration)
        let price = configuration.price
        sum += price
      }
      setTotalPrice(sum)
      setTimeout(() => {
        updatePriceWithDiscount(sum)
      }, 500);
  },[cartItems])


  function updatePriceWithDiscount(total:number = totalPrice) {
    let discount = 0
    for (const item of cartItems) {
      let id = item.id
      discount += STORE.cartItemsDiscount[id] || 0
    }
    STORE.currDiscount = discount
    setPriceWithDiscount(total - STORE.currDiscount)
  }


  function Header() {
    return(
      <div className="checkout-header padding-container display-flex-row no-wrap position-relative" style={{height:"55px"}}>
        <div className="center-aligned-column">
          <Button2
            configObj={{id:""}}
            action={()=>{hideProductCheckout()}}
            label={""}
            fontAwesomeIcon={"fas fa-arrow-left color-black"}
            iconPos="left"
            classNames={"no-shadow margin0 bg-color-light"}
          />
        </div>
        <div className="heading1 font-large marginleft10 center-aligned-column">Your cart ({cartItems.length} Item)</div>
      </div>
    )
  }


  function Proceed() {
    return(
      <div className="display-flex-row marginTop10 shadow checkout-buttons-container">
        
        <div className="display-flex-row container">
          <div className="center-aligned-column price-container">
            <div className="total-price display-flex-row marginRight10">
              <span className="heading3 font-medium center-aligned-column">Total Price - </span>
              <span className="heading1 font-large marginleft5 total_price">{getFormattedPrice(priceWithDiscount)}</span>
            </div>
          </div>
          <Button2
              configObj={{id:""}}
              action={()=>{setIsOpenReview(true)}}
              label={"Proceed to checkout"}
              fontAwesomeIcon={"fas fa-checkout color-warning"}
              iconPos="left"
              classNames={"no-shadow text-center margin0 button-large cart-button bg-color-warning cart-button-warning"}
            />
          {!isCheckoutFromQuotation?
            <>
            <Button2
              configObj={{id:""}}
              action={()=>{setIsOpenQuote(true)}}
              label={"Save As Quotation"}
              fontAwesomeIcon={"fas fa-save color-warning"}
              iconPos="left"
              classNames={"no-shadow hide-button-text-in-mobile text-center button-large cart-button  bg-color-warning-outline"}
            />
            
            <ShareLink cartItems={props.cartItems} Content={()=>(
              <Button2
                configObj={{id:""}}
                action={()=>{}}
                label={"Share Confirmation Link"}
                fontAwesomeIcon={"fas fa-share color-primary"}
                iconPos="left"
                classNames={"no-shadow hide-button-text-in-mobile text-center button-large cart-button  bg-color-warning-outline"}
              />
            )}/>

            
            </>
          :null}
        </div>
          
      </div>
    )
  }

  function confirmQuatationHandle() {
      setIsOpenQuote(false)
      hideProductCheckout()
      setIsOpenStartSession(true)
  }
 
  return (
     <div className="top-right full-height-width display-none product-checkout-wrapper overflow-y-scroll" style={{top:"var(--main-header-height)"}}>

        <IonModal
          id=""
          cssClass="vertical-modal"
          onDidDismiss={()=>setIsOpenReview(false)}
          isOpen={isOpenReview}
          >
          <CheckoutReview totalPrice={totalPrice} setIsOpenReview={setIsOpenReview}/>
        </IonModal>

        <IonModal
          id=""
          cssClass="vertical-modal"
          onDidDismiss={()=>setIsOpenQuote(false)}
          isOpen={isOpenQuote}
          >
          <Quotation confirmHandle={confirmQuatationHandle} cartItems={cartItems}/>
        </IonModal>

        <StartCustomerSession isOpen={isOpenStartSession} setIsOpen={setIsOpenStartSession} createCustomerCallback={()=>{}}/>

        <Header/>
        <div className="display-flex-row product-checkout-container padding-container ">
          <div className="inner-container white-card shadow-light border cart-items padding5">
              <CartItemsList updateTotalPrice={updatePriceWithDiscount} cartItems={cartItems}/>
              <Proceed/>
          </div>
          <div className="inner-container price-summary padding5">
            <div className="checkout-cart-summary white-card shadow-light padding5">
              <div className="heading2" style={{padding:"5px 0"}}>Price summary</div>
              <PriceDetails cartItems={cartItems}/>
            </div>
          </div>
        </div>
     </div>
  );
};

export default ProductCheckout;
