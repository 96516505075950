import $ from "jquery";
import * as TWEEN from "@tweenjs/tween.js"
import THREE, {
  ACESFilmicToneMapping,
  Box3,
  Color, DoubleSide, EdgesGeometry, Group,
  LineBasicMaterial,
  LineSegments, Mesh,
  MeshPhongMaterial,
  MeshStandardMaterial, PlaneGeometry, Quaternion, RepeatWrapping, Sprite, SpriteMaterial, Vector2
} from "three";
import { DragControls } from "three/examples/jsm/controls/DragControls";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { Vector3 } from "three/src/math/Vector3";
import { getProductSavedConfigs, saveProductConfiguration, saveSharedProductConfiguration } from "../../../services/api";
import { closeSummary, getMobileOperatingSystem, getParentContainer, getWindowHeight, getWindowWidth, hideComponentLoader, setElementInnerContent, showCartToast, showComponentLoader, showGlobalToast, showToast, updateCartCounter, updateLoaderProgress } from "../../UI_methods/global";
import { checkFromCache } from "../../cache/cache";
import { captureAndSaveScreenshot, getFileKey } from "../../cache/indexdb";
import { addItemToLocalStorage, getCartKey, getItemFromLocalStorage } from "../../cache/localstorage";
import { TextureInfo } from "../../customizer/ProjectConfiguration";
import { foregroundParts } from "../../data";
import { pointerMoveResizer } from "../../image-viewer/imageviewer";
import { applicationConfig, categoryPartTypeFilteredArray, disposeVariables, getArrayOfDistinctValues, getFilteredArray, getMetalnessMapURL, getModulePrice, getNormalMapURL, getObjectByParameter, getRandomArray, getRoughnessMapURL, getSpecularMapURL, stringWithoutNumbers, stringWithoutWhiteSpace, waitFor } from "../../methods";
import { getFormattedPrice } from "../../products/productviewer_shopify.js";
import { STORE } from "../../store/storeConfiguration";
import { Module } from "../Configuration";
import { pointerMoveAnnotationDrag, pointerUpAnnotationDrag } from "../area3dmodel/annotations";
import { areaModel, projectConfiguration } from "../area3dmodel/area3dModel";
import { addModuleToConfiguration, attachModules, customizein3dInitialLayout, detachModule, detachModules, iPadPotraitModeLayout, isPotraitMode, removeObjectFromModulesList, updateFraming, updateGroupCenter } from "../common";
import { toggleRendererTransparency, updateCamera } from "../controls";
import { generatedEnvironmentMap } from "../enviornment";
import { addRaycastingListenerToImage } from "../raycasting";
import { tweenModelPosition, tweenScale } from "../tween";
import { generateModel } from "../webxr";
import { enableConfigureMode, hideActionTabs, resetControlTabs, showCustomizeButton } from "./UI_methods";
import { pointerMoveDragIcon, pointerUpDragIcon, updateDragIconPosition } from "./annotations";
import { IS_SPACE_PLANNER_MODE, addAddonToConfiguration, appendModel, camera, canvasDimensions, composer, configuration, controls, debugScene, delete3dObject, dimensions, disableLoading, dragging, element, enableLoading, enableSpacePlannerForSS, fitToModuelsScene, floorplanner, goBackToPreviousPage, group, groupBoundingBox, labelRenderer, renderer, scene, spacePlannerImagesGroup, updateCanvasDimensions } from "./customizein3d";
import { allowDropProduct, isDraggingProduct, onTouchMoveProductDrag } from "./imageDragging";
import { CURR_SELECTED_MODULE, applyRaycasting, deselectModule, getDetectedObject, pointerDownRaycasting, sceneHoverInteraction, updateBoxHelper } from "./raycasting";
import { ModuleClass } from "./ModuleClass";


const target = new Vector3();
const extensions = [".png",".jpg",".webp"]
const reclinerProductForOffset = ["GT3","GT2"]


let pricingExcelData = []


var sectionalDuplicateObjects = []

var textMaterial = new MeshPhongMaterial({color:0x000000});
var MaterialWhite = new MeshPhongMaterial({ color: 0xffffff});
const geometry = new PlaneGeometry( 34, 16 );
const planeBehindText = new Mesh( geometry, MaterialWhite );

var ALL_TEXTURES:Array<any> = []
export var dimensionsGroup:any = new Group()
export var IS_DIMENSION_VISIBLE = false

export var isChangingControls = false

let axis = new Vector3(0,1,0)
let posX = 0
let posY = 0

export function disposeVariableHelperFile(){
  textMaterial = null
  MaterialWhite = null
  disposeGeometry(...ALL_TEXTURES)
}

export function getTextures(){
  return ALL_TEXTURES
}

export function getDefaultTexture(textures:any,objectMaterialTypes:any,partName:string,categoryName:string){
    partName = stringWithoutNumbers(partName)
    let updatedTextures = categoryPartTypeFilteredArray(textures,objectMaterialTypes,partName,categoryName)
    if(updatedTextures.length==0){
      return new TextureInfo()
    }
    let randomObject = getRandomArray(updatedTextures)
    return new TextureInfo(randomObject)
  }



  export function getMaterialTypeDefaultConfigs(textures:any) {
    let mapping:any = {}
    let materialTypes = getArrayOfDistinctValues(textures,"material_type")
    for (let index in materialTypes) {
        let materialType:any = materialTypes[index]
        let updatedArray = getFilteredArray(textures,"material_type",materialType)
        mapping[materialType] = getRandomArray(updatedArray)
    }
  }

  export function getModelDimensions(modelObject:any,boundingBox:any) {
    let box:any = boundingBox?.setFromObject( modelObject ) || new Box3().setFromObject( modelObject )
    let dimX = box.getSize(target).x
    let dimY = box.getSize(target).y
    let dimZ = box.getSize(target).z
    box = null
    return {dimX:dimX,dimY:dimY,dimZ:dimZ}
  }

  export function getModelPositions(modelObject:any,boundingBox:any) {
    let box:any = boundingBox.setFromObject( modelObject );
    return box
  }
  
  
  export function getObjectDimensionPosition(bounding:any,object:any) {
    let boundingBox:any = new Box3()
    let center = new Vector3()
    let box:any = boundingBox?.setFromObject( object );
    boundingBox.getCenter(center)
    let dimX = box.getSize(target).x  
    let dimY = box.getSize(target).y 
    let dimZ = box.getSize(target).z 
    let result = new BoundingBoxProperties(box,center,{dimX:dimX,dimY:dimY,dimZ:dimZ}) 
    disposeVariables([boundingBox,center,box])
    return result
  }


  export function getObjectBoxFromSelectedMeshes(selectedMesh:Array<Mesh>) {
    var boundingBox = new Box3();
    selectedMesh.forEach(mesh => {
        var meshBoundingBox = new Box3().setFromObject(mesh);
        boundingBox.union(meshBoundingBox);
    });
    let dimX = boundingBox.getSize(target).x
    let dimY = boundingBox.getSize(target).y
    let dimZ = boundingBox.getSize(target).z
    var center = new Vector3();
    boundingBox.getCenter(center);
    let result = new BoundingBoxProperties(boundingBox,center,{dimX:dimX,dimY:dimY,dimZ:dimZ}) 
    disposeVariables([boundingBox,center,boundingBox])
    return result
  }

  export class BoundingBoxProperties{
    dimensions:any
    positions:Box3
    center:Vector3
    constructor(box:Box3,center:Vector3,dimensions:any){
      this.dimensions = dimensions
      this.positions = box
      this.center = center
    }
  }



  export function getObjectByName(scene:any,name:string) {
    return scene?.getObjectByName(name) || null
  }


  function disposeVaiables(...variables) {
    for (let variable of variables) {
      variable = null
    }
  }

  export function disposeGeometry(...objects) {
    for (let object of objects) {
      object?.dispose()
      object = null
    }
  }

  function disposeMesh(...meshes) {
    for (let mesh of meshes) {
      mesh.material.dispose();
      mesh.geometry.dispose();
      mesh.geometry = null; // or undefined .
      mesh.material = null
      delete mesh.geometry 
      delete mesh.material 
    }
  }

  function addPlanesBehindText(group:any,position:any,isRotate=false) {
    // material.opacity = 0.5;
    // plane.rotation.x = Math.PI / 2;
    try {
    
      planeBehindText?.position.set(position.x,position.y,position.z );
      // plane.position.set(0,0,0 );
      planeBehindText.material.side = DoubleSide
      MaterialWhite.envMap = generatedEnvironmentMap.texture
      if(isRotate){
        planeBehindText.rotation.y = Math.PI/2;
      }
      group.add(planeBehindText)
      disposeVaiables(geometry,planeBehindText)
    } catch (error) {
      
    }
  
  }

  export function getConvertedLengthBreadth(l:any,b:any,unit:string) {
    let breadthConvertedValue = ""
    let lengthConvertedValue = ""
    let convertedBreadthInches = 0
    let convertedLengthInches = 0
    let breadthFeet:any
    let breadthInches:any
    let breadth = ""
    let length  = ""

    l = l * 100
    b = b * 100

    if(unit==="feet"){
      breadth = String((parseFloat(b)/30.48).toFixed(2))
      length = String((parseFloat(l)/30.48).toFixed(2))

      breadthFeet = breadth.substr(0,breadth.indexOf("."))
      breadthInches = breadth.substr(breadth.indexOf(".")+1)

      let lengthFeet = length.substr(0,length.indexOf("."))
      let lengthInches = length.substr(length.indexOf(".")+1)

      convertedBreadthInches = Math.ceil(parseFloat("0."+breadthInches) * 12)  
      breadthInches = String(convertedBreadthInches)
      
      convertedLengthInches = Math.ceil(parseFloat("0."+lengthInches) * 12)  
      lengthInches = String(convertedLengthInches)

      breadthConvertedValue = Number(breadthInches) === 12 ? Number(breadthFeet) + 1 + " ' ": breadthFeet + " '- " + breadthInches + " ''"
      lengthConvertedValue = Number(lengthInches) === 12 ? Number(lengthFeet) + 1 + " ' ": lengthFeet + " '- " +  lengthInches   + " ''"

    }
    if(unit==="inch"){
      breadthConvertedValue = String(Math.floor(b/2.54) + " In ")
      lengthConvertedValue = String(Math.floor(l/2.54) + " In ")
    }
    if(unit=="cm"){
      breadth = String(b.toFixed(2))
      length = String(l.toFixed(2))

      breadthConvertedValue = breadth.substr(0,breadth.indexOf("."))
      lengthConvertedValue = length.substr(0,length.indexOf("."))
    }
    return {
      breadth:breadthConvertedValue,
      length:lengthConvertedValue,
      feet:breadthFeet,
      inch:breadthInches
    }
  }

  export function getFormatedFeetInchDimensions(totalInches:number) {
    let feets = totalInches / 12
    let inches = totalInches % 12
    return Math.round(feets) + " ' - " + inches + " '' " 
  }

  export function addTextToScene(scene:any,fontLoader:any,text:string,positions:any,rotations:any,params:any,name:string,isSpacePlannerMode=false) {
  
    return new Promise((resolve:any,reject:any)=>{
      try {
          fontLoader.load(`${applicationConfig.aws_public_url}assets/fonts/Roboto_Regular.json`,function(font:any){
            var textGeo = new TextGeometry( text,{
              font: font,
              size: params.fontSize,
              height: 0.5/100,
              curveSegments: 12,
              bevelEnabled: false,
              bevelThickness: 0.05,
              bevelSize: 0.1,
              bevelSegments: 0.1
            });
            var textMaterial = new MeshStandardMaterial( { color: 0x000000} );
            var mesh = new Mesh( textGeo, textMaterial );
            mesh.position.set(positions.x, positions.y, positions.z);
            mesh.rotation.set(rotations.x,rotations.y,rotations.z,"XYZ")
            mesh.name = "testing"
            scene.add(mesh)
            disposeVaiables(textGeo)
            // let mesh = addText(scene,loadedFont,params,text,positions,rotations,name,isSpacePlannerMode)
            resolve(mesh)
          })
        // let mesh = addText(scene,font,params,text,positions,rotations,name,isSpacePlannerMode)
        // resolve(mesh)
      } catch (error) {
        console.log(error)
        reject(error)
      }
     
    })
 
  }


  function addText(scene:any,loadedFont:any,params:any,text:string,positions:any,rotations:any,name:string,isSpacePlannerMode:boolean) {
    let parameters = {
      font: loadedFont,
      size: params.fontSize,
      height: 0.5,
      curveSegments: 12,
      bevelEnabled: false,
      bevelThickness: 0.05,
      bevelSize: 0.1,
      bevelSegments: 0.1
    }
    let textGeometry = new TextGeometry( text,parameters);
    var textMesh = new Mesh(textGeometry, textMaterial);
    textMesh.position.set(positions.x, positions.y , positions.z);
    textMesh.rotation.set(rotations.x,rotations.y,rotations.z,"XYZ")
    scene.add(textMesh)
    disposeVaiables(textGeometry,textMesh)
    return textMesh
  }

  export function getTotalPrice(configuration:any,configObj:any) {
    let price = 0
    for (const index in configuration) {
      let collectionName = ''
      let config = configuration[index]

      for (const key in config.configuration) {
          collectionName = config?.configuration[key]?.collectionName

      }

      price = price + getModulePrice(configObj?.data?.productModulesPrice,collectionName,config.moduleId) || 0
    }
    return getFormattedPrice(String(price)) 
  }


  export function updateTotalPrice(customizeConfigObj:any,configObj:any) {
    let totalPrice = getTotalPrice(customizeConfigObj?.getConfiguration(),configObj)
    setElementInnerContent(String(totalPrice),"modulesPrice")
  }

  export function showObjects(scene:any,objectNames:Array<string>) {
    objectNames.forEach(name=>{
      showObject(scene,name)
    })
  }

  export function showObject(scene:any,name:any) {
    let object = getObjectByName(scene,name)
    if(object){
      object.visible = true
      object.bVisible = true
    }
  }

  export function hideObjects(scene:any,objectNames:Array<string>) {
    objectNames.forEach(name=>{
      hideObject(scene,name)
    })
  }

  export function hideObject(scene:any,name:any) {
    let object = getObjectByName(scene,name)
    if(object){
      object.visible = false
    }
  }

  
  export function updatePositionOfAddon(modelObject:any,configuration:any) {
    if(configuration.modules.length === 0 && configuration.addons.length === 0){
      modelObject.position.set(0,0,0)
      return true
    }

    let subCategory = configuration.product.subCategoryName
    let data = getObjectDimensionPosition(null,group)
    let currObjData:any = getObjectDimensionPosition(null,modelObject)

    modelObject.rotation.set(0,0,0,"XYZ")
    let posX = subCategory !== "Recliner" ? data.positions.max.x : data.positions.min.x
    let posY = data.positions.min.y
    let posZ = data.positions.max.z + 0.6

    //For Single Seater 
    // if(group.children.length === 1 || (configuration.isLshapeWithSingleSeater && configuration.addons.length === 0)){
      posX = data.positions.max.x + currObjData.dimensions.dimZ + 0.3
    // }

    let addonsList  =  getStandaloneModules()

    if(subCategory === "Recliner" && addonsList.length === 1){
      let moduleData = getObjectDimensionPosition(null,modelObject)
      posX = data.positions.max.x - moduleData.dimensions.dimX
      posZ = data.positions.max.z - moduleData.dimensions.dimZ
    }

    if(subCategory !== "Recliner"){
      modelObject.rotateOnWorldAxis(axis,-Math.PI/2)
    }
    modelObject.position.copy(new Vector3(posX,posY,posZ))
  }

  export function getAddedProductFromScene() {
    return group.children.find(currObject=> currObject.userData.configuration.productName === configuration.product.productName)
  }

  var offSetsArray = [
    {
      offsetX : 1,
      offsetZ : 0
    },
    // {
    //   offsetX : 1,
    //   offsetZ : 1
    // },
    {
      offsetX : 0,
      offsetZ : 1
    },
    // {
    //   offsetX : -1,
    //   offsetZ : 1
    // },
    {
      offsetX : -1,
      offsetZ : 0
    },
    {
      offsetX : 0,
      offsetZ : -1
    },
  ]

  export function updateModelPositionOfLShape(modelObject:any,configuration:any,boundingBox:any,axis:any,isTween:boolean) {
    if(!axis){
      axis = new Vector3(0,1,0)
    }
    //Prev model used when rearranging the models 
    let module = configuration.getPrevModuleFromModule(modelObject) 


    let previousModelObj = getObjectByObjectId(scene || areaModel,module.moduleObjectId)
    let previousElementModuleType = module.moduleType


    //Needed when rearranging the model 
    let cornerModulesLength = configuration.getNumberOfCornerModulesTillLength(modelObject)

    modelObject.rotation.set(0,0,0,"XYZ")
    
    let rotationOffset = 0
    let offsetX = 0
    let offsetZ = 0

    //Set model initial rotation (USE case in delete)

    if(previousElementModuleType.toLowerCase() === "corner"){
      rotationOffset = -Math.PI/2
    }

    let previousModelObjDimensions:any = getModelDimensions(previousModelObj,new Box3())

    let previousModelWidth_X = previousModelObjDimensions.dimX
    let previousModelWidth_Z = previousModelObjDimensions.dimZ

    // if(configuration.groupRotation === 90 || configuration.groupRotation === 270){
    //   previousModelWidth_X = previousModelObjDimensions.dimZ
    //   previousModelWidth_Z = previousModelObjDimensions.dimX
    // }    
    
    let worldPosition = new Vector3()
    previousModelObj.getWorldPosition(worldPosition)

    // let worldPosition = getObjectDimensionPosition(null,previousModelObj).positions.min

    let positions={
      posX : worldPosition.x, 
      posY : worldPosition.y,
      posZ : worldPosition.z
    }

    let deltaX = 0
    let deltaZ = 0

    //
    let arrayPointerMoveTo = 0 

    if(configuration.groupRotation === 90){
      arrayPointerMoveTo = 1
    }
    if(configuration.groupRotation === 180){
      arrayPointerMoveTo = 2
    }
    if(configuration.groupRotation === 270){
      arrayPointerMoveTo = 3
    }

    let offsetResult:any = null

    
    if(cornerModulesLength === 0){
      offsetResult = offSetsArray[(0 + arrayPointerMoveTo) % offSetsArray.length]
    }

    if(cornerModulesLength === 1 ){
      offsetResult = offSetsArray[(1 + arrayPointerMoveTo) % offSetsArray.length]
    }

    if(cornerModulesLength === 2){
      offsetResult = offSetsArray[(2 + arrayPointerMoveTo) % offSetsArray.length]
    }

    if(offsetResult){
      offsetX = offsetResult.offsetX
      offsetZ = offsetResult.offsetZ
    }

    let XdistanceScaleToAttach = STORE.getCurrCategory() === "Wardrobe" ? 1 : 0.97
    let ZdistanceScaleToAttach = 1
    if(cornerModulesLength === 1){
      XdistanceScaleToAttach = 1
      ZdistanceScaleToAttach = 0.97
    }

    // if(configuration.groupRotation === 180){
    //   XdistanceScaleToAttach = 0.98
    //   ZdistanceScaleToAttach = 1
    // }
   

    deltaX = (previousModelWidth_X * XdistanceScaleToAttach * offsetX) + getDefaultOffset(configuration,previousElementModuleType,cornerModulesLength).x
    deltaZ = (previousModelWidth_Z * ZdistanceScaleToAttach * offsetZ) + getDefaultOffset(configuration,previousElementModuleType,cornerModulesLength).z


    if(previousElementModuleType.toLowerCase() === "corner"){
      if(offsetResult.offsetX === 0){
        deltaX = deltaX + (previousModelWidth_X * offsetResult.offsetZ)
      }
      if(offsetResult.offsetZ === 0){
        deltaZ = deltaZ - (previousModelWidth_Z * offsetResult.offsetX)
      }
    }

    let deltaForRecliner = getDeltaXForRecliner(modelObject,module)
   
    tweenModelPosition(modelObject,{x:positions.posX + deltaX + deltaForRecliner,y:positions.posY,z:positions.posZ + deltaZ},isTween)
    
    let worldRotation = new Quaternion()
    previousModelObj.getWorldQuaternion(worldRotation)
    modelObject.applyQuaternion(worldRotation)
    modelObject.rotateOnAxis(axis,rotationOffset)

    
  }



  function getDeltaXForRecliner(modelObject:any,prevModule:any){
    let delta = 0
    let productName = configuration.product.productName 
    if(reclinerProductForOffset.includes(productName) && configuration.product.subCategoryName === "Recliner" && !modelObject.name.includes("Console") && prevModule.moduleName.includes("Console")){
      let offset = 0.04
      let consoleModulesLength = configuration.getNumberOfConsoleModulesTillLength(modelObject)
      delta += offset * consoleModulesLength
    }
    return -delta
  }


  function getDefaultOffset(configuration:any,previousElementModuleType:any,cornerModulesLength:number){
    if(configuration.product.productName.toLowerCase().includes("elizabeth")){
      if(previousElementModuleType.toLowerCase() === "corner" && cornerModulesLength === 1){
        return{
          x:0,
          z:0.10
        }
      }
      if(previousElementModuleType.toLowerCase() === "corner" && cornerModulesLength === 2){
        return{
          x:-0.10,
          z:0
        }
      }
    }
    return {
      x:0,
      z:0
    }
  }

  export function updateModelPositionOf321(modelObject:any,configuration:any,boundingBox:any){
    // let rotation = 0
    var axis = new Vector3(0,1,0)
    let rotationOffset = 0

     //Prev model used when rearranging the models 
    let module = configuration.getPrevModuleFromModule(modelObject) 

    let previousModelObj = getObjectByObjectId(scene || areaModel,module.moduleObjectId)

    modelObject.rotation.set(0,0,0,"XYZ")
    // rotation = -Math.PI/2
    rotationOffset = -Math.PI/2
    let delta = 0.2

    let prevObjData:any = getObjectDimensionPosition(boundingBox,previousModelObj)
    let currObjData:any = getObjectDimensionPosition(boundingBox,modelObject)


    // previousModelObj.getWorldPosition( target );
   
    let posX = prevObjData.positions.max.x
    let posZ = prevObjData.positions.max.z + delta

    if(configuration.getModulesList().length===1){
      posX = prevObjData.positions.max.x + currObjData.dimensions.dimZ + (0.3)
    }
   
    modelObject.position.set(posX, previousModelObj.position.y , posZ)
    modelObject.rotateOnWorldAxis(axis,rotationOffset)

  }

  export function updateModelPosInTestMode(modelObject:any,configuration:any,boundingBox:any){
    // let rotation = 0
     //Prev model used when rearranging the models 
    let module = configuration.getPrevModuleFromModule(modelObject) 
    if(configuration.modules.length === 0){
      modelObject.position.set(0,0 ,0)
      return
    }
    let previousModelObj = getObjectByObjectId(scene || areaModel,module.moduleObjectId)
    // modelObject.rotation.set(0,0,0,"XYZ")
    let prevObjData:any = getObjectDimensionPosition(boundingBox,previousModelObj)
    let currObjData:any = getObjectDimensionPosition(boundingBox,modelObject)
    let posX = prevObjData.positions.max.x
    let posZ = prevObjData.positions.min.z
    posX = prevObjData.positions.max.x + currObjData.dimensions.dimZ + (0.05)
    modelObject.position.set(posX, previousModelObj.position.y , posZ)
  }


  export function updateModelPosition(modelObject:any,configuration:any,boundingBox:any,axis:any,subCategory:string,isTween=true) {
    subCategory = stringWithoutWhiteSpace(subCategory).toLowerCase()
    let categoryName = STORE?.currProduct?.category_name || configuration?.product?.categoryName
    if(subCategory.toLowerCase() === "setsofas"){
      subCategory = "3+2+1"
    }
    if(subCategory.toLowerCase() === "recliner" || categoryName === "Wardrobe"){
      subCategory = "lshape"
    }
    switch(subCategory){
      case "lshape":
        updateModelPositionOfLShape(modelObject,configuration,boundingBox,axis,isTween)
      break;
  
      case "3+2+1":
        updateModelPositionOf321(modelObject,configuration,boundingBox)
      break;
  
      default:
        updateModelPositionOf321(modelObject,configuration,boundingBox)
        return
    }
  }


  export function getObjectByObjectId(scene:any,uuid:any) {
    let object = null
    try {
      scene?.traverse(element => {
        if(element.uuid === uuid){
          object = element
        }
      });
      return object
    } catch (error) {
      return object
    }
    
  }

  
export function getTarFileKey(url:string) {
  return url.replace(".jpg",".tar")
}

export function getLocalstorageFinishConfigKey(productName:string,moduleName:string) {
  return `${productName}_Finish_${moduleName}`
}


//Add Lines from given points
  export function addEdges(scene:any,object:any,outlineName:string,visibility=true) {
    let geometry = object?.geometry
    let edges = new EdgesGeometry( geometry );
    let line = new LineSegments( edges, new LineBasicMaterial( {color: 0x000000,toneMapped:false} ) );
    // line.rotateX( Math.PI/2);
    // line.position.set(object.position.x,object.position.y,object.position.z)
    line.position.set(0,0,-0.1) // Position y is -1 to disable the shadows
    line.name = outlineName
    line.visible = visibility
    line.castShadow = false
    line.receiveShadow = false
    object.add( line );
    disposeVaiables(edges,line)
  }

  export function getDefaultModel(subCategory:string,productModules:Array<any>) {
    let filteredArray:any = []
    //For L shape


    if(subCategory.toLowerCase() === "setsofas"){
      subCategory = "3+2+1"
    }
  
    switch(subCategory.toLowerCase()){
      
      case "lshape":
        
      return productModules.find(currModule => currModule.module_type.toLowerCase().includes("left") && !currModule.module_name.toLowerCase().includes("lounger") ) || productModules[0]
      break;

      case "recliner":
        return productModules.find(currModule => currModule.module_type.toLowerCase().includes("left"))  
      break;
  
      case "3+2+1":
      return getObjectByParameter(productModules,"module_type","FourSeater") || getObjectByParameter(productModules,"module_type","ThreeSeater") || getObjectByParameter(productModules,"module_type","DoubleSeater") || productModules[0]
      break;

      case "setsofas":
        return getObjectByParameter(productModules,"module_type","FourSeater") || getObjectByParameter(productModules,"module_type","ThreeSeater") || getObjectByParameter(productModules,"module_type","DoubleSeater") || productModules[0]
        break;

      case "chairs":
        return getObjectByParameter(productModules,"module_type","Chair") || productModules[0]
      break;

      case "swingdoor":
        return productModules.find(currModule => currModule.module_name.includes("2Door_A_L") || currModule.module_name.includes("2Door_C_L") || currModule.module_name.includes("2Door_B_L")) || getObjectByParameter(productModules,"module_type","2Door") || getObjectByParameter(productModules,"module_type","2Door") || productModules[0]
      break;

      case "floorstanding":
        return productModules.find(currModule => currModule.module_name.includes("Swing_5.0"))|| productModules[0]
      break;
  
      default:

      return productModules[0]
    }
  }


  export function getDefaultModuleType(subCategory:string) {
    if(subCategory.toLowerCase() === "setsofas"){
      subCategory = "3+2+1"
    }
    if(subCategory.toLowerCase() === "recliner"){
      subCategory = "lshape"
    }
    switch(subCategory){
      
      case "lshape":
      return "Left"
      break;
  
      case "3+2+1":
      
      return "3Seater"
      break;
  
      default:
      return "Left"
    }
  }



  export function removeEdges(scene:any,name:string) {
    let object = scene.getObjectByName(name)
    scene.remove(object)
  }

  export function removeObjectFromScene(group:any,object:any) {
    removeObjectFromModulesList(object)
    detachModule(object)
    updateGroupCenter(group)
  }


  export function getSpacePlannerImageUrl(BASE_URL:string,clientName:string,productName:string) {
    return `spacePlannerImages/${productName}/${productName}.png`
  }

  export  function updateOtherModelPositions(group:Group,configuration:any,boundingBox:any,axis:any,subCategory:string,isTween:boolean,isAttachModule:boolean = true) {
    let categoryName = STORE?.currProduct?.category_name || configuration?.product?.categoryName || ""
    if(subCategory.toLowerCase() === "lshape" || subCategory.toLowerCase() === "recliner" || categoryName === "Wardrobe"){
      let allModules = configuration.getModulesList()
      if(allModules.length<2){
        return
      }
      for (let index = 0; index < allModules.length; index++) {
        if(index !== 0){
          let object:any = allModules[index]
          let objectId = object.moduleObjectId
          let modelObject = getObjectByObjectId(scene||areaModel,objectId)
          //Reuired in delete, not when update dimensions
          if(isAttachModule){
            detachModule(modelObject)
          }
          // let modelObject = getObjectByParameter(MODULES_LIST,"uuid",objectId)
          updateModelPosition(modelObject,configuration,boundingBox,axis,subCategory,isTween)
          if(isAttachModule){
            group.attach(modelObject)
          }
        }
      }
      // resetModulesListBoundingBox(MODULES_LIST)
      updateGroupCenter(group)
    }
  }



  export  function updateAllAddonsPositions(configuration:any,boundingBox:any,axis:any) {
    let allModules = configuration.getAddonsList()
    if(allModules.length){
      for (let index = 0; index < allModules.length; index++) {
          let module:any = allModules[index]
          let modelObject = getObjectByObjectId(scene,module.moduleObjectId)
          if(index===0){
            updateModelPositionOfLShape(modelObject,configuration,boundingBox,axis,false)
          }else{
            updatePositionOfAddon(modelObject,configuration)
          }
      }
    }
  
  }
  
  export function getParent(object:any) {
    // return object?.parent
    if(object?.userData?.configuration){
      return object
    }
    if(!object?.parent){
      return null
    }
    return getParent(object?.parent)
  }
  



export function createBoxBelowModel(scene:any,boundingBox:any,object:any,SELECTED_OBJECT_OUTLINE_NAME:string) {
  if(SELECTED_OBJECT_OUTLINE_NAME != ""){
    let delta = 0.05
    let dim = getModelDimensions(object,boundingBox)
    // red  0xd92550
    // blue  0x36d6d9
    const material = new MeshStandardMaterial({ color: 0x808080, depthWrite: false });
      // material.opacity = 0.5;
      // plane.rotation.x = Math.PI / 2;
    const geometry = new PlaneGeometry( dim.dimX + delta, dim.dimZ + delta );
    const plane:any = new Mesh( geometry, material );
    let positions = getModelPositions(object,boundingBox)
    plane.position.x = positions.min.x + dim.dimX / 2
    plane.position.y = 0
    plane.position.z = positions.min.z + dim.dimZ / 2
    material.envMap = generatedEnvironmentMap.texture


    // addEdges(scene,plane,SELECTED_OBJECT_OUTLINE_NAME)
  
    plane.name = SELECTED_OBJECT_OUTLINE_NAME
    plane.visible = true
    plane.material.side = DoubleSide
    plane.rotateX( Math.PI/2); 

    plane.scale.set(0.95,0.95,0.95)
    scene.add(plane)
    tweenScale(plane,250)
    disposeVaiables(material,geometry,plane)
  }
}

var renderOrderSprite = 5

export async function addImage(scene:any,textureLoader:any,key:string,positions:any,width:number,name:string) {
  // let img:any = new MeshBasicMaterial({ 
  //   map:ImageUtils.loadTexture('assets/images/opus.png')
  // });
  // img.map.needsUpdate = true; 
  renderOrderSprite = renderOrderSprite + 1
  return new Promise((resolve,reject)=>{
    checkFromCache(key)?.then((url:any)=>{
      textureLoader.load(url,function(textureMap:any){
        const material = new SpriteMaterial( { map: textureMap } );
        material.color.set("0x000000")
        const sprite = new Sprite(material);
        let imageWidth = textureMap.image.naturalWidth
        let imageHeight = textureMap.image.naturalHeight
        let aspectRatio = imageWidth / imageHeight
        // plane.material.side = DoubleSide;
        // sprite.rotation.x = -Math.PI/2
        sprite.renderOrder = renderOrderSprite
        sprite.name = name

        //Check if already exists
        let object = scene.getObjectByName(name)
        if(object){
          positions.z = positions.z + Math.floor(width/aspectRatio)/100
        }  
        sprite.position.set(positions.x,positions.y,positions.z)
        sprite.scale.set(width/100,Math.floor(width/aspectRatio)/100,1)
        //(saperate group) To make it select individual if overlapped image : Not solved in this way 
        scene.add(sprite)
        disposeVaiables(material,sprite)
        resolve(sprite)
      },function(loaded:any){
        // console.log(loaded)
      },function(error:any) {
        console.log(error)
        reject(error)
      })
    }).catch(err=>{
      console.log(err)
      reject(err)
    })
  })
  
 

 
}

export function makeObjectDraggable(scene:any,camera:any,renderer:any,SELECTED_OBJECT_OUTLINE_NAME:string,control:any,objects:Array<any>,isGroupDraggable = false) {
  control = new DragControls( objects, camera, renderer.domElement );
  control.transformGroup = isGroupDraggable
  control.enabled = false


  // if(objects[0].isSprite){
  objects[0].userData.control = control
  // }
  // add event listener to highlight dragged objects
  if(spacePlannerImagesGroup){
    control.addEventListener( 'dragstart', function ( event:any ) {
      stopDraggingOtherElements(objects[0],spacePlannerImagesGroup?.children)
    });
  }
  return control
}

export function stopDraggingOtherElements(currObject:any,childrens:Array<any>) {
    for (const object of childrens) {
      if(object.userData.control){
        if(object.uuid === currObject.uuid){
          object.userData.control.enabled = true
        }else{
          object.userData.control.enabled = false
        }
      }
    }
}

export function stopDraggingAllElements(childrens:Array<any>) {
  for (const object of childrens) {
    if(object?.userData?.control){
      object.userData.control.enabled = false
      // object.userData.control = null
    }
  }
}

export function disableDraggingInModel(object:any) {
  if(object?.userData?.control){
    object.userData.control.enabled = false
    object.userData.control = null
  }
}

export function getMaterialUpdated(textureLoader:any,textureInfo:any,material:any = null){
  return new Promise((resolve,reject)=>{
    let values = getValuesFromMaterialType(textureInfo?.materialType)
    let allMaps = getAllMapsArray(textureInfo,values)
    createMeshStandardMaterial(allMaps,textureLoader,JSON.stringify(textureInfo),material).then(material=>{
      resolve(material)
    })
  })
}

export async function createMeshStandardMaterial(allMaps:any,textureLoader:any,name:string,material:any = null) {
  return new Promise(async (resolve,reject)=>{

    let textureInfo = JSON.parse(name)

    // let sheenColor = config.sheenColor

    material.name = name 
    material.userData.textureInfo = textureInfo

    let companyName = textureInfo?.companyName || textureInfo?.company_name 

    let tilingScale = getUvValue(companyName)
   
    let i = 0
    for (const currMap of allMaps) {
      let url = currMap.url
      if((companyName === "Sarom" || companyName === "Darpan") && currMap.mapName === "roughnessMap"){
        url = null
      }
      if(currMap.mapName.toLowerCase().includes("normal") && !material.userData.isAllowNormalMap){
        continue
      }
      await getTextureMap(url,textureLoader).then((textureMap:any)=>{
        textureMap.wrapS = RepeatWrapping;
        textureMap.wrapT = RepeatWrapping;

        textureMap.repeat.set(tilingScale,tilingScale);
        
        if(!currMap.mapName.toLowerCase().includes("normal")){
          textureMap.colorSpace  = "srgb"
        }
      
        textureMap.repeat.set(tilingScale,tilingScale)
        material[currMap.mapName] = textureMap
        material.color.setHex(0xffffff)
        material.normalScale = new Vector2(1,1)
        material.sheen = getSheenValue(textureInfo?.materialType) || 0


        // material.sheenColor.setHex(sheenColor)

        if(currMap.mapName.toLowerCase().includes("roughnessmap")){
          material[currMap.valueName] = 1
        }
        material.envMap = generatedEnvironmentMap.texture
        material.side = 2;
        material.needsUpdate = true

        i++
        if(i===allMaps.length-1){
          resolve(material)
        }
      }).catch(err=>{
        material[currMap.mapName] = ""
        material[currMap.valueName] = debugScene.isEnabled?(currMap.debugValue || 0):currMap.value
        material.needsUpdate = true;
        material.normalScale = new Vector2(1,1)
        i++
        if(i===allMaps.length-1){
          resolve(material)
        }
      })
    }
  })
}


function getSheenValue(materialType:string){
  let result = 0
  if(materialType === "Fabric"){
    result = 0.5
  }
  if(materialType === "Velvet"){
    result = 1
  }
  return result
}

function getUvValue(companyName:string){
  if(debugScene.isEnabled){
    return debugScene.uvScale
  }

  let result = 0
  let currProductTiling = STORE?.currProduct?.tiling_scale_3d
  if(currProductTiling){
    result = currProductTiling
  }
  if(companyName === "Darpan"){
    result = 24
  }
  if(companyName === "Mittal"){
    result = 16
  }
  if(companyName === "Tesa"){
    result = 12
  }
  if(projectConfiguration){
    result = 2
  }
  return result
}
 
export function getAllUrls(config:any) {
  return(
    {
      // textureUrl: getTextureUrl("",config?.companyName,config?.collectionName,config?.materialCode),
      textureUrl: `thumbnails/OVL/${config?.companyName}/${config?.collectionName}/${config?.materialCode}.png`,
      roughnessMapUrl :getRoughnessMapURL("",config?.companyName,config?.collectionName,config?.materialCode),
      normalMapUrl: getNormalMapURL("",config?.companyName,config?.collectionName,config?.materialCode),
      metalnessMapUrl: getMetalnessMapURL("",config?.companyName,config?.collectionName,config?.materialCode),
      specularMapUrl: getSpecularMapURL("",config?.companyName,config?.collectionName,config?.materialCode),
      transmissionMapUrl:"",
    }
  )
}


export function getAllMapsArray(config:any,values:any) {
  let urls = getAllUrls(config)
  let allMaps = [
    {
      mapName:"map",
      valueName:"color",
      url:urls?.textureUrl,
      value:values?.colorValue,
    },
    {
      mapName:"roughnessMap",
      valueName:"roughness",
      url:urls.roughnessMapUrl,
      value:values?.roughnessValue,
      debugValue:debugScene.roughness
    },
    {
      mapName:"normalMap",
      valueName:"normalScale",
      url:urls?.normalMapUrl,
      value:values?.normalValue
    },
    {
      mapName:"metalnessMap",
      valueName:"metalness",
      url:null,
      value:values?.metalnessValue,
      debugValue:debugScene.metalness
    },
    {
      mapName:"displacementMap",
      valueName:"displacementScale",
      url:null,
      value:values?.displacementValue
    },
    {
      mapName:"transmissionMap",
      valueName:"transmission",
      url:urls.transmissionMapUrl,
      value:values?.transmissionValue,
      debugValue:debugScene.transmission
    },
    {
      mapName:"clearcoatMap",
      valueName:"clearcoat",
      url:null,
      value:values?.clearCoatValue,
      debugValue:debugScene.clearcoat
    },
  ]
  return allMaps
}



export function getValuesFromMaterialType(materialType:string) {
  let values:any = {}
  switch (materialType?.toLowerCase() || "Default") {
    case "fabric":
      values = {
        metalnessValue : 0,
        roughnessValue : 0.9,
        displacementValue : 0,
        normalValue : 1,
        colorValue : new Color(0xffffff),
        clearCoatValue : 0 ,
        transmissionValue : 0,
      }
    break;

    case "velvet":
      values = {
        metalnessValue : 0,
        roughnessValue : 0.9,
        displacementValue : 0,
        normalValue : 1,
        colorValue : new Color(0xffffff),
        clearCoatValue : 0 ,
        transmissionValue : 0,
      }
    break;

    case "leather":
      values = {
        metalnessValue : 0,
        roughnessValue : 0.5,
        displacementValue : 0,
        normalValue : 1,
        colorValue : new Color(0xffffff),
        clearCoatValue : 0 ,
        transmissionValue : 0,
      }
    break;

    case "wood":
      values = {
        metalnessValue : 0,
        roughnessValue : 0.3,
        displacementValue : 0,
        normalValue : 1,
        colorValue : new Color(0xffffff),
        clearCoatValue : 1 ,
        transmissionValue : 0,
      }
    break;

    case "laminate":
      values = {
        metalnessValue : 0,
        roughnessValue : 0.3,
        displacementValue : 0,
        normalValue : 1,
        colorValue : new Color(0x000000),
        clearCoatValue : 1 ,
        transmissionValue : 0,
      }
    break;

    case "glass":
      values = {
        metalnessValue : 0,
        roughnessValue : 0,
        displacementValue : 0,
        normalValue : 1,
        colorValue : new Color(0x000000),
        clearCoatValue : 0,
        transmissionValue : 1,
      }

    break;
      
    case "metal":
      values = {
        metalnessValue : 1,
        roughnessValue : 0.1,
        displacementValue : 0,
        normalValue : 1,
        colorValue : new Color(0xdbe4eb),
        clearCoatValue : 0,
        transmissionValue : 0,
      }
    break;
    case "acrylic":
      values = {
        metalnessValue : 0,
        roughnessValue : 0.2,
        displacementValue : 0,
        normalValue : 1,
        colorValue : new Color(0xdbe4eb),
        clearCoatValue : 1,
        transmissionValue : 0.25,
      }
    break;
    default:
        values = {
          metalnessValue : 0,
          roughnessValue : 0.8,
          displacementValue : 0,
          normalValue : 1,
          colorValue : new Color(0xffffff),
          clearCoatValue : 0 ,
          transmissionValue : 0,
        }
    break;
  }
  return values
}

export function setMeshProperties(mesh:any) {
  mesh.castShadow = true; 
  mesh.receiveShadow = true;
  mesh.renderOrder = 1
  if(mesh.material.map) mesh.material.map.anisotropy = 16;
}

export function setModuleProperties(model:any) {
  model.traverse((mesh:any) => { if ( mesh.isMesh ) {
    setMeshProperties(mesh)}
  })
}

 
export function getTextureMap(url:string, textureLoader:any, valuesFromDatabase=null) {
  return new Promise(async (resolve,reject)=>{

    let textureFound = false;
    for (let i = 0; i<extensions.length; i++) {
      let extension = extensions[i];
      let lastDotIndex = url?.lastIndexOf('.');
      let defaultExtension = url?.substring(lastDotIndex);
      let otherUrl = url?.replace(defaultExtension,extension);
      if(!otherUrl){
        reject(new Error("Invalid URL"));
        return; // Exiting the loop if URL is invalid
      }
      try {
        const textureMap = await createTextureMap(otherUrl, textureLoader);
        if (textureMap) {
          textureFound = true;
          resolve(textureMap);
          break; // Exiting the loop if texture is found
        }
      } catch (error) {
        // Handle any errors in texture creation
        console.error(`Error creating texture map for ${otherUrl}: ${error}`);
      }
    }
    // If texture is not found for any extension, reject the promise
    if (!textureFound) {
      reject(new Error("Texture not found for any extension"));
    }
  });
}


export function createTextureMap(url:string,textureLoader:any,valuesFromDatabase=null) {
  return new Promise(async (resolve,reject)=>{
    await checkFromCache(url).then(data=>{
      textureLoader.load(data,function(textureMap:any){
        // let uvScale = null
        // if(valuesFromDatabase){
        //   uvScale = Number(valuesFromDatabase.uv_scale)
        // }
        // textureMap.wrapS = RepeatWrapping;
        // textureMap.wrapT = RepeatWrapping;
        // textureMap.repeat.set(4, 4);
        ALL_TEXTURES.push(textureMap)
        resolve(textureMap)
      },function(loaded:any){
        // console.log(loaded)
      },function(error:any) {
        reject(error)
      })
    }).catch(err=>{
      resolve(null)
    })
  })
}


export function shortListItem(productName:string,configuration:any,customizeConfiguration:any){
  let cartKey = getCartKey()
  let itemId:string = randomToken()
  // let key:string = getShortlistKey("Customizein3D",productName)
  let item = {
    productName:productName,
    modules:configuration.modules,
    finish:customizeConfiguration.getConfiguration()[0]?.configuration,
    itemId:itemId
  }


  //Is already exits append data 
  let items:any = getItemFromLocalStorage(cartKey)

  if(items){
    //append to the list 
    for (const currItem of items) {
      if(currItem.modules.length === item.modules.length
        && JSON.stringify(currItem.finish) === JSON.stringify(item.finish)
        ){
          // showToast(`Config Already exists`,2000,"error")
          showCartToast("Item already exists","error")
          return
        }
    }
    items = [...items,item]
    addItemToLocalStorage(cartKey,items)
    showCartToast("Item added to cart")
    captureScreenshot(itemId)
    updateCartCounter()
    return
  }
  addItemToLocalStorage(cartKey,[item])
  // setElementInnerContent("1","cartItemCounter")
  updateCartCounter()
  // showToast(`${productName} Added to cart`,2000)
  showCartToast("Item added to cart")
  captureScreenshot(itemId)
  // logger?.info("customizein3d","Shortlist")
}

export function randomToken() {
  return "id_" + String(Math.random().toString(36).substr(2)) // remove `0.`
};

export function captureScreenshot(itemId:string) {
  var canvas = document.querySelector("canvas")   
  if (canvas) {
    let dataURL = canvas.toDataURL("image/png");
    captureAndSaveScreenshot(dataURL,"cart",itemId)
  }
}
export function enableDragging(control:any) {
  control.enabled = true
}

export function disableDragging(control:any) {
  // control.enabled = false
  control?.dispose()
}


export function getDefaultConfig(partName:string,categoryName:string){
  let textures = categoryPartTypeFilteredArray(applicationConfig?.data?.materials,applicationConfig?.data?.objectMaterialTypes,partName,categoryName)
  if(textures.length){
    return  new TextureInfo(textures[0])
  }else{
    return  new TextureInfo()
  }
}

export function getDatabaseStoresValues(textures:any,textureInfo:any) {
  return textures?.find(texture=>texture.company_name===textureInfo.companyName && texture.collection_name===textureInfo.collectionName && texture.material_code===textureInfo.materialCode) || null
}




export function dragStart(event: any) {
  // event.dataTransfer.setData("product", event.target.id);
  event.dataTransfer.setData("companyName", event.target.getAttribute("data-company-name"))
  event.dataTransfer.setData("collectionName", event.target.getAttribute("data-collection-name"))
  event.dataTransfer.setData("materialCode", event.target.getAttribute("data-material-code"))
  event.dataTransfer.setData("materialType", event.target.getAttribute("data-material-type"))

  event.dataTransfer.setData("materialId", event.target.getAttribute("data-material-id"))
  event.dataTransfer.setData("uvScale", event.target.getAttribute("data-uv-scale"))
  event.dataTransfer.setData("roughness", event.target.getAttribute("data-roughness-value"))
  event.dataTransfer.setData("metalness", event.target.getAttribute("data-metalness-value"))

  event.dataTransfer.setData("specular", event.target.getAttribute("data-specular-value"))
  event.dataTransfer.setData("clearcoat", event.target.getAttribute("data-clearcoat-value"))

  event.dataTransfer.setData("sheen", event.target.getAttribute("data-sheen-value"))
}


export function allowDrop(event: any) {
  event.preventDefault();
  if(posX === event.pageX && posY === event.pageY){
    return
  }
  posX = event.pageX
  posY = event.pageY
  if(!isDraggingProduct){
    sceneHoverInteraction(posX,posY)
  }
  allowDropProduct(event)
}

 



export function getPartNameFromMeshName(meshName:string,categoryName:string="Sofa"){
  // return getOriginalPartName(categoryName,meshName)
  let productParts = foregroundParts[categoryName]
  for (const part of productParts) {
    if(meshName.startsWith(part)){
      return part
    }
  }
  return null
}


 export function get3DModelSrc(productName:string,moduleName:string){
    return `assets/model/${productName}_${moduleName}.glb`
}

export function getProductModuleUrl(BASE_URL: string,clientName: string,moduleName: string,categoryName:string,subCategoryName:string) {
  moduleName = moduleName.replace("_XL","")
  return `moduleImages/${moduleName?.replace(/ /g,'')}.png`;
}


export function getAddonsImagesUrl(BASE_URL: string,clientName: string,moduleName: string,categoryName:string,productName:string) {
  return `addons/${clientName}/${categoryName?.replace(" ","")}/${productName?.replace(/ /g,'')}_${moduleName?.replace(/ /g,'')}.png`;
}


export function createDefaultMaterial(DEFAULT_MATERIALS:any,categoryName:string){
  //Mesh materials 
  //Assing properties

  for (const iterator of DEFAULT_MATERIALS[categoryName]) {
    //Get the partName

    //Material Types frmo partName

    //Create default material
  }


}





export function addEventListeners(manager:any,renderer:any,element:any) {
  
  
  window.addEventListener('resize',function() {
     updateCanvasDimensions()
      customizein3dInitialLayout()
      iPadPotraitModeLayout("custmoizein3d")
      updateFraming(group,groupBoundingBox,camera,controls)
      composer?.onWindowResize(renderer,camera,labelRenderer,IS_SPACE_PLANNER_MODE)
      if(getWindowWidth() > 480){
        setTimeout(() => {
          enableConfigureMode()
        }, 200);
      }
  } )

  enableInteraction(element)

  $(window).on('popstate',()=>{
    goBackToPreviousPage()
  });

  window.addEventListener("orientationchange", function() {
    resetControlTabs()
  }, false);

  window.addEventListener("touchmove",onTouchMoveProductDrag);

  controls.addEventListener("change", function() {
    if(dimensionsGroup?.visible){
      scaleDimensions()
    }   
    if(IS_SPACE_PLANNER_MODE){
      updateDragIconPosition(group,renderer,camera)
    }
    dimensions.updateSvgLinesPosition()
    isChangingControls = true
  }, false);

  controls.addEventListener("end", function() {
    isChangingControls = false
  }, false);

}

    
export function checkForLandscapeMode(){
  let width = getWindowWidth()
  let height = getWindowHeight()
  if((window.orientation == 90 || window.orientation == -90)){
    //Show selector
    height = getWindowHeight()
    if((width < 480 || height < 480) && getMobileOperatingSystem() !== "ios"){
      $("#landscapeWarningWrapper").removeClass("display-none")
    }
  }else{
    height = getWindowWidth()
    $("#landscapeWarningWrapper").addClass("display-none")
  }
  hideActionTabs()
  showCustomizeButton()
}


export function scaleDimensions() {
  let zoom = 1
  if(IS_SPACE_PLANNER_MODE){
    zoom = camera.zoom / 100
  }else{
    zoom = controls?.target?.distanceTo( controls.object.position ) / 10
  }
  zoom = 1 + (zoom)
  dimensionsGroup?.children.forEach(mesh => {
    if(mesh.name.includes("test")){
      mesh.scale.set(zoom,zoom,zoom)
    }
  });
}




function enableInteraction(element:any) {
    element.style.cursor = 'grab';
    element.addEventListener('pointerdown',function() {
      element.style.cursor = 'grabbing';
    });
    window.addEventListener('touchmove',pointerMoveAnnotationDrag);
    element.addEventListener('pointermove',pointerMoveAnnotationDrag);
    element.addEventListener('pointerup',pointerUpAnnotationDrag);
}



export async function addImageToScene(productName:string,configObj:any,GROUND_WIDTH:any,GROUND_HEIGHT:any,groundGroup:any,spacePlannerImagesGroup:any,textureLoader:any,dragControlsImages:any,controls:any,scene:any,camera:any,renderer:any,SELECTED_OBJECT_OUTLINE_NAME:string) {
  let key = getSpacePlannerImageUrl("/",configObj?.clientName,productName)
  let width = 100
  let positions={
    x:GROUND_WIDTH/2,y:2,z:GROUND_HEIGHT/2
  }
  if(productName==="Door"){
    positions = {x:-width/400,y:2,z:width/400}
  }
  if(productName==="TV_Unit"){
    width = 274
    let dim = getObjectDimensionPosition(null,groundGroup)
    positions = {x:dim.positions.max.x - (GROUND_WIDTH / 2),y:2,z:dim.positions.max.z - 20/100}
  }
  if(productName==="Lamp"){
    width = 50
    let dim = getObjectDimensionPosition(null,groundGroup)
    positions = {x:dim.positions.min.x + (width/200),y:2,z:dim.positions.max.z - (width/200)}
  }
  if(productName==="Bed"){
    width = 198
  }
  if(productName==="Dining"){
    width = 213
  }

  try {
    let sprite = await addImage(spacePlannerImagesGroup,textureLoader,key,positions,width,productName) 
    dragControlsImages = makeObjectDraggable(scene,camera,renderer,SELECTED_OBJECT_OUTLINE_NAME,dragControlsImages,[sprite],false)
    addRaycastingListenerToImage(dragControlsImages,controls)
    // logger?.info("customizein3d","add-img")
    return sprite
  } catch (error) {
    console.log(error)
    return null
  }
}


export async function updateScreenshot(){

  try {
    return
    await waitFor(1000)
    let mode = "normalMode"
    if(IS_SPACE_PLANNER_MODE){
      mode = "spacePlannerMode"
    }
    let parentContainer = getParentContainer()
    let screenshotCanvas:any = parentContainer.querySelector("canvas")
    if(screenshotCanvas){
      let image = screenshotCanvas.toDataURL("image/png")
      let fileKey = getFileKey("customizein3d",{productName:applicationConfig?.productName,mode:mode})
      captureAndSaveScreenshot(image,"customizein3d",fileKey)
      disposeVariables([screenshotCanvas,image])
    } 
  } catch (error) {
    
  }

}

export async function resetScreenshots(){

  await waitFor(1000)
  let parentContainer = getParentContainer()
  let screenshotCanvas:any = parentContainer.querySelector("canvas")
  if(screenshotCanvas){
    let image = screenshotCanvas.toDataURL("image/png")
    //get view name from product name 
    // let viewDetails = currentConfigObject.projectProductsList.find(product=>stringWithoutWhiteSpace(product.product_name).includes(productName)) || null
    let fileKey = getFileKey("customizein3d",{productName:applicationConfig?.productName,mode:"normalMode"})
    captureAndSaveScreenshot(image,"customizein3d",fileKey)
    fileKey = getFileKey("customizein3d",{productName:applicationConfig?.productName,mode:"spacePlannerMode"})
    captureAndSaveScreenshot(image,"customizein3d",fileKey)
    disposeVariables([screenshotCanvas,image])
  }
}

export function showObjectByOpacity(scene:any,name:string,object:any=null) {
  if(!object){
    object = getObjectByName(scene,name)
  }
  object.material.opacity = 1
  object.material.color.setHex("0x000000") 
}


export function hideObjectByOpacity(scene:any,name:string,object:any=null) {
  if(!object){
    object = getObjectByName(scene,name)
  }
  object.material.opacity = 0
  object.material.color.setHex("0xffffff") 
}


export function updateLayer(scene:any) {
  //Keep everything in the same layer
  scene.traverse(mesh=>{
    mesh.layers.set(0)
  })
}



export function getFormattedConfig(config:any) {
  let newConfig = []
  for (const module of config) {
    let modelObject = getObjectByObjectId(scene,module.moduleObjectId || module.moduleObjectId)
    let configArray = []
    for (const partName in module.configuration) {
      let currObject = module.configuration[partName]
      currObject.partName = partName
      currObject.sheen = 0
      configArray.push(currObject)
    }
    module.configuration = configArray
    module.moduleName = module.moduleName.substring(0,module.moduleName.indexOf("-")) || module.moduleName
    module.moduleId = module.moduleId
    module.transform = {
      position:modelObject?.position,
      rotation:modelObject?.rotation
    }
    newConfig.push(module)
  }
  for (const currModule of configuration.addons) {
    let module = new Module(currModule.module,currModule.moduleObjectId)
    let modelObject = getObjectByObjectId(scene,currModule.moduleObjectId || currModule.moduleObjectId)
    let configArray = []
    for (const partName in currModule.configuration) {
      let currObject = currModule.configuration[partName]
      currObject.partName = partName
      currObject.sheen = 0
      configArray.push(currObject)
    }
    module.configuration = configArray
    module.transform.position = modelObject?.position
    module.transform.rotation = modelObject?.rotation
    newConfig.push(module)
  }
  return newConfig
}

export async function confirmConfigurationFromCustomer() {
  showComponentLoader("mainLoader")
  updateLoaderProgress("mainLoader",1,1,"Sending Request")
  await waitFor(1000)
  closeSummary()

  saveSharedProductConfiguration(configuration.modules).then(data=>{
    hideComponentLoader("mainLoader")
    $("#confirmationMessageWrapper").removeClass("display-none")
  }).catch(err=>{
    hideComponentLoader("mainLoader")
    console.log(err)
  })
  
}


export async function setCurrConfigAsDefault() {
  enableLoading()
  await generateModel(configuration.product,configuration,configuration.modules.concat(configuration.addons),true,null,true).then((url:any)=>{
    url = url.replace("https://opusassets.s3.ap-south-1.amazonaws.com/","")
    let categoryName = configuration.product.categoryName
    let subCategoryName = configuration.product.subCategoryName
    let productName = configuration.product.productName
    let product = STORE.currProduct
    let clientName = applicationConfig?.clientName
    if(product){
      clientName = product?.storefront_name === "OVL" ? "OVL" : applicationConfig?.clientName
    }
    let destination = `public/models/${clientName}/${categoryName}/${subCategoryName}/${productName}.glb`
    applicationConfig.awsConfig.copyObject(url,destination)
  })
  disableLoading()
}



export async function saveConfiguration(isDefault:boolean,modelUrl:string) {
    let data = {
      clientId:applicationConfig?.clientId,
      configHash:configuration.configHash,
      configuration:configuration,
      productId:configuration.product.productId,
      isDefault:isDefault,
      modelUrl:modelUrl
    }
    await saveProductConfiguration(data).then(async res=>{
      await getProductSavedConfigs().then(data=>{
        applicationConfig.updateData("savedProductConfiguration",data)
      })
    })
}


export function getSavedConfigList(productId:number = configuration.product.productId) {
  let savedConfigFromDatabase = applicationConfig?.data?.customizerSavedConfigs
  if(savedConfigFromDatabase){
    savedConfigFromDatabase = savedConfigFromDatabase.filter(config=>config.client_id == applicationConfig.clientId && config.product_id == productId)
    if(savedConfigFromDatabase.length){
      return savedConfigFromDatabase
    }
  }
  return []
}

export function pointerMoveAnnotationDragAction(event:any) {
  pointerMoveDragIcon(event,group,element,camera)
  pointerMoveResizer(event)
  pointerMoveAnnotationDrag(event)
  dragging.pointerMove(event)
}

export function onPointerUp(event:any) {
  applyRaycasting(event)
  pointerUpDragIcon(event)
  pointerUpAnnotationDrag(event)
  // if(!IS_SPACE_PLANNER_MODE){
  //   dragging.pointerUp(event)
  // }
  //Tracking object on mouse down in case of space planner 
  // if(IS_SPACE_PLANNER_MODE){
  //   dragging.resetObject()
  // }
  dragging.pointerUp(event)

}

export function onPointerDown(event:any) {
  pointerDownRaycasting(event)
  dragging.pointerDown(event)

  let object = getDetectedObject(event)
  if(object && CURR_SELECTED_MODULE){
    dragging.allowDragging = object.uuid === CURR_SELECTED_MODULE.uuid
  }else{
    dragging.allowDragging = false
  }

  // if(IS_SPACE_PLANNER_MODE || true){
  //   applyRaycasting(event)
  //   if(CURR_SELECTED_MODULE){
  //     controls.enabled = false
  //     dragging.pointerDown(event)
  //   }
  // }
}

export function udpateConfigurationNameInHeader() {
  let variantName = "1 Lounger"
  let modulesList = configuration.modules
  if(modulesList?.length){
    if(isCorner(modulesList)){
      let count = modulesAfterCorder(modulesList)>2?3:2
      variantName = `2+Corner+${count}${count===3?"_RHS":""}`
    }else{
      let count = modulesList.length>2?3:2
      if(modulesList[0].displayName.includes("Chaise")){
        variantName = `2+Lounger${"_LHS"}`
      }else if(modulesList[modulesList.length-1].displayName.includes("Chaise")){
        variantName = `2+Lounger${"_RHS"}`
      }else{
        variantName = `${count}Seater`
      }
    }
    $(".current-configuration").text(variantName)
  }
  
}

function isCorner(modulesList:any) {
  return getObjectByParameter(modulesList,"moduleType","Corner")
}

function modulesAfterCorder(modulesList) {
  let index = 2
  let isFoundCorner = false
  for (const currModule of modulesList) {
    if(isFoundCorner){
      index += 1
    }
    if(currModule.moduleType === "Corner"){
      isFoundCorner = true
    }
  }
  return index
}

export function updateReclinerHeight(action:string) {
  let delta = 0.05
  let posY = 0
  let modules = []
  let object = CURR_SELECTED_MODULE
  if(object && !object.isSprite){
    let isAddon = object.userData.configuration.isAddedAsAddon
    if(isAddon){
      modules = [CURR_SELECTED_MODULE]
    }else{
      modules = getSectionalModules()
    }
    switch(action){
      case "plus":
        modules.forEach(module => {
          module.position.y += delta;
          posY = module.position.y
        });
      break;
  
      case "minus":
        modules.forEach(module => {
          module.position.y -= delta;
          posY = module.position.y
        });
      break;
    }
    let convertedUnits = getConvertedLengthBreadth(posY,posY,"inch")
    $("#reclinerHeightValue").text(convertedUnits.length)
    updateBoxHelper()
  }else{
    showToast("Please select a module",2000)
  }
  
}

export function getSectionalModules() {
  let modules = []
  group.children.forEach(object => {
    let isAddon = object.userData?.configuration?.isAddedAsAddon
    if(!isAddon){
      modules.push(object)
    }
  });
  return modules
}

export function getDuplicatedSectionalModules() {
  let modules = []
  group.children.forEach(object => {
    let isDuplicatedSectional = object.userData?.configuration?.isDuplicatedSectional
    if(isDuplicatedSectional){
      modules.push(object)
    }
  });
  return modules
}



export function getStandaloneModules() {
  let modules = []
  group.children.forEach(object => {
    let isAddon = object.userData?.configuration?.isAddedAsAddon
    if(isAddon){
      modules.push(object)
    }
  });
  return modules
}



export async function saveScreenShotToS3(isSummarySS:boolean = false) {
  if(isSummarySS){
    enableSpacePlannerForSS()
    await waitFor(1000)
    // topView.create()
    // await waitFor(1200)
    let center = getObjectDimensionPosition(null,group).center
    camera.position.set(center.x, center.y + 5, center.z);  
    camera.lookAt(center.x, center.y, center.z);
    camera.updateProjectionMatrix();
    controls.target.set(center.x, center.y + 5, center.z);
    controls.update();
    updateZoom()
    // topView.updateZoom()
    // enableSpacePlannerForSS()
    // await waitFor(500)
  }
  uploadCavasScreenshot(isSummarySS)
}


export async function saveScreenshotins3ForSummary(){
  deselectModule()
  updateCamera(TWEEN,configuration,camera,groupBoundingBox,group,controls)
  await waitFor(1200)
  await uploadCavasScreenshot(false)
  saveScreenShotToS3(true)
}

export async function uploadCavasScreenshot(isSummarySS:boolean){
  return new Promise(async (resolve,reject)=>{
    if(composer && configuration){
      composer.render()
      fitToModuelsScene()
      await waitFor(500)
      var canvas = document.querySelector("canvas")   
      if (canvas && STORE && configuration) {
        let dataURL = canvas.toDataURL("image/png")
        let categoryName = STORE.currProduct.category_name
        let productName = STORE.currProduct.product_name
        let configName = configuration.configId || "Default"
        let fileName = `${configName}.png`
        let albumName = "public"
        let albumPhotosKey = encodeURIComponent(albumName) + "/store/savedConfigs/" + encodeURIComponent(applicationConfig?.clientName) + "/" + encodeURIComponent(categoryName) + "/" + encodeURIComponent(productName) + "/";
        if(isSummarySS){
          albumPhotosKey = encodeURIComponent(albumName) + "/store/summaryTopView/" + encodeURIComponent(applicationConfig?.clientName) + "/" + encodeURIComponent(categoryName) + "/" + encodeURIComponent(productName) + "/";
        }
        applicationConfig.awsConfig.uploadFile(dataURL,"",albumPhotosKey,fileName,null).then(data=>{
          resolve("done")
        }).catch(err => {
          reject(err)
        })
      }
    }
  })
  
}

function updateZoom(){
  const box = new Box3().setFromObject(group);
  const size = box.getSize(new Vector3());
  const center = box.getCenter(new Vector3());

  // Get the aspect ratio of the canvas
  const aspectRatio = canvasDimensions.width / canvasDimensions.height;
  // const aspectRatio = window.innerWidth / window.innerHeight;

  // Calculate the width and height needed to fit the group
  const width = size.x;
  const height = size.y;

  // Calculate the zoom factor to fit the group into the camera's frustum
  let zoom;
  if (aspectRatio >= 1) {
      zoom = camera.right / width;
  } else {
      zoom = camera.top / height;
  }
  // Set the camera's zoom and update its projection matrix
  let factor = 1
  if(   isPotraitMode()){
    factor = 2.5
  }

  camera.zoom = zoom / factor;
  camera.updateProjectionMatrix();
}


export function saveScreenShotForBackground() {
  return new Promise(async (resolve, reject) => {
    if (composer && configuration) {
      toggleRendererTransparency(renderer, true);
      scene.background = null
      composer.render();
      fitToModuelsScene();
      await waitFor(500);
      var parentContainer = document.getElementById("mainCustomizein3DWrapper");
      var canvas = parentContainer.querySelector("canvas");
      var canvases = parentContainer.querySelectorAll("canvas");
      if(canvases.length > 1){
        canvas = canvases[1]
      }
      if (canvas && STORE && configuration) {
        let dataURL = canvas.toDataURL("image/png");
        let categoryName = STORE.currProduct.category_name;
        let productName = STORE.currProduct.product_name;
        let configName = configuration.configId || "Default";
        let fileName = `${configName}.png`;
        let albumName = "public";
        let albumPhotosKey = encodeURIComponent(albumName) + "/store/productWithBackground/" + encodeURIComponent(applicationConfig?.clientName) + "/" + encodeURIComponent(categoryName) + "/" + encodeURIComponent(productName) + "/";
        applicationConfig.awsConfig
          .uploadFile(dataURL, "", albumPhotosKey, fileName, null)
          .then((data) => {
            console.log(data);
            toggleRendererTransparency(renderer, false);
            scene.background = new Color(0xffffff)
            resolve(true);
          })
          .catch((err) => {
            toggleRendererTransparency(renderer, false);
            scene.background = new Color(0xffffff)
            console.log(err);
            reject(false);
          });
      } else {
        reject(false);
      }
    } else {
      reject(false);
    }
  });
}




export async function updateModulePricing(module:any,collectionName:string) {
  if(applicationConfig.clientName === "HomeZone"){
  
    let reuslt = 0
    let moduleName = module.description
  
    if(pricingExcelData){
      try {
        for (let i = 0; i < pricingExcelData.length; i++) {
            let currModuleName = pricingExcelData[i].ModuleName;
            let currCollection = pricingExcelData[i].Catalogname;
            if(stringWithoutWhiteSpace(moduleName?.toLowerCase()) === stringWithoutWhiteSpace(currModuleName?.toLowerCase()) && currCollection === collectionName){
              reuslt = Number(pricingExcelData[i].Price);
            }
        }
        module.updatePrice(reuslt)
      } catch (error) {
        showGlobalToast("Error updating price",2000,"error")
        console.log(error)
        
      }
    }else{
      showGlobalToast("Pricing not found",2000,"error")
    }
  }
}



export async function setPricingExcelData(){
  return new Promise(async (resolve,reject)=>{
    let csvUrl = "https://opusassets.s3.ap-south-1.amazonaws.com/public/store/HomeZonePricingCSV.csv"
 
    let windowObject: any = window;
    let Papa: any = windowObject.Papa;
      fetch(csvUrl).then(response => {
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              return response.text(); // Read the response as text
          })
          .then(csvData => {
              // Parse CSV data using PapaParse
              Papa.parse(csvData, {
                  header: true, // Treat the first row as headers
                  skipEmptyLines: true,
                  complete: function(results) {
                      let data = results.data
                      pricingExcelData = data.filter(currdata => stringWithoutWhiteSpace(currdata.ModelName)  === stringWithoutWhiteSpace(STORE.currProduct?.product_name))
                  },
                  error: function(error) {
                      console.error('Error parsing CSV:', error);
                  }
              });
          })
          .catch(error => {
              console.error('Error fetching the CSV file:', error);
          });
  });
}
   


export async function duplicationSectionalModules(){
  resetSectionalDuplicateObjects()
  let modules = configuration.modules;
  let modulesGroup = new Group();
  group.add(modulesGroup); // Add modulesGroup instead of `group` again


  for (const module of modules) {
    let object = getObjectByObjectId(scene, module.moduleObjectId);
    if (!object) continue; // Ensure object exists before proceeding

    let clonedObject = object.clone(true); // Deep clone the object to prevent reference issues
    modulesGroup.add(clonedObject);

    let currModule = new ModuleClass(module); // Create a new instance of ModuleClass
    currModule.allow_duplication = false;
    currModule.allow_movement = true;
    currModule.allow_rotation = true;
    currModule.is_addon = true;
    //Will be used to drag the duplicated sectional
    currModule.isDuplicatedSectional = true;
    // currModule.module_type = "SingleSeater";
    currModule.is_addon = true
    // You can now associate `currModule` with `clonedObject` if needed:
    addAddonToConfiguration(currModule,clonedObject,configuration)

    // clonedObject.userData.configuration = currModule;

    sectionalDuplicateObjects.push(clonedObject)
  }

  let data = getObjectDimensionPosition(null,group)

  let posX = data.positions.max.x + 0.3
  let posY = data.positions.min.y
  let posZ = data.positions.max.z + 0.6

  await waitFor(500)

  modulesGroup.rotateOnWorldAxis(axis,-Math.PI/2)
  modulesGroup.position.copy(new Vector3(posX,posY,posZ))
  updateFraming(group,groupBoundingBox,camera,controls)

  detachModules(sectionalDuplicateObjects)
  attachModules(sectionalDuplicateObjects,group)
  // modulesGroup.position.set(1.5,0,0)
}

function resetSectionalDuplicateObjects(){
  for (const currModel of sectionalDuplicateObjects) {
    delete3dObject(currModel)
  }
  // detachModules(sectionalDuplicateObjects)
  sectionalDuplicateObjects = []
}
 