import { IonIcon, IonModal } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { fullScreenBrowserToggle, toggleUserOption } from "../../../../../utils/UI_methods/global";
import { applicationConfig, getRandomString, getUser } from "../../../../../utils/methods";
import { openSideMenu } from "../../../../../utils/store/UI_methods";
import { showProductCart, updateCartIconCounter } from "../../../../../utils/store/product-cart";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import "../../../../style/store/header.css";
import ProductCart from "../cart/ProductCart";
import ProductCheckout from "../checkout/ProductCheckout";
import CreateCustomOrder from "../custom-order/CreateCustomOrder";
import StartCustomerSession from "../customer/StartCustomerSession";
import SideMenu from "./SideMenu";
import StoreProductSearch from "./StoreProductSearch";
import UserOptions from "./UserOptions";
import { getItemFromLocalStorage } from "../../../../../utils/cache/localstorage";
import { expand } from "ionicons/icons";

 
const MainHeader: React.FC<{
}> = (props) => {


  const history = useHistory()
  const[isOpenStartSession,setIsOpenStartSession] = useState(false)
  const[isOpenAddProduct,setIsOpenAddProduct] = useState(false)
  
  const[isOpenOrders,setIsOpenOrders] = useState(false)
  // const[isOpenSearch,setIsOpenSearch] = useState(false)

  const[refresh,setRefresh] = useState(getRandomString(10))
  const[refreshCustomer,setRefreshActiveCustomer] = useState(getRandomString(10))
  const[cartItems,setCartItem] = useState([])
  const[logoSrc,setLogoSrc] = useState("assets/images/opus.png")

  useEffect(()=>{
    if(STORE?.activeCustomer || true){
      fetchCartItems()
    }else{
      setCartItem([])
      updateCartIconCounter(0)
    }
  },[refresh])

  function fetchCartItems() {

    let cartItems = getItemFromLocalStorage("store_cart") || []
    STORE.cartItems = cartItems
    updateCartIconCounter(cartItems.length)
    setCartItem(cartItems)
    return
    STORE.storeApi.cart.getCartItems(STORE?.activeCustomer.id).then((data:any)=>{
      if(!data.error){
        let cartList = data.data?.data
        if(!cartList?.length){
          cartList = []
        }
        setCartItem(cartList)
        STORE.cartItems = cartList
        updateCartIconCounter(cartList.length)
      }
    })
  }
  
  
  useEffect(()=>{
    applicationConfig?.setFunctionRef("store_MainHeader",{refreshCartItems:refreshCartItems})
    applicationConfig?.setFunctionRef("store_MainHeader",{refreshActiveCustomer:refreshActiveCustomer})
    applicationConfig?.setFunctionRef("store_MainHeader",{openOrders:openOrders})

    if(applicationConfig?.user?.isAllowedWhitelabeling){
      let clientName = applicationConfig.clientName
      setLogoSrc(applicationConfig.aws_public_url + `assets/clientLogos/${clientName}.png`)
    }
  },[])

  function refreshCartItems() {
    setRefresh(getRandomString(10))
  }

  function openOrders() {
    setIsOpenOrders(true)
  }

  function closeOrders() {
    setIsOpenOrders(false)
  }

  function refreshActiveCustomer() {
    setRefreshActiveCustomer(getRandomString(10))
  }
   

  function gotoHome() {
    if(!getUser().shareItemDetails){
      history.push("/products")
    }
  }

  function onsuccessCreateOrder() {
    setIsOpenAddProduct(false)
  }

 
  return (
    <>
      <IonModal
          id=""
          isOpen={isOpenAddProduct}
          cssClass="medium-x-modal"
          onDidDismiss={()=>setIsOpenAddProduct(false)}
      ><CreateCustomOrder onSuccess={onsuccessCreateOrder} /></IonModal>

    
    <SideMenu/>
    <ProductCart cartItems={cartItems}/>
    <ProductCheckout cartItems={cartItems}/>
    <div className="main-header-wrapper padding-container position-relative display-flex-row no-wrap">


      <div className="header-left-content">
      <div className="full-height padding5 company-logo pointer hide-in-mobile" onClick={gotoHome}>
            <img src={logoSrc} alt=""  className="full-height" />
        </div>
        <div className="hide-in-desktop">
          <Button2
            configObj={{id:""}}
            action={openSideMenu}
            label={""}
            fontAwesomeIcon={"fas fa-bars color-warning"}
            iconPos="left"
            classNames={"no-shadow button-large margin0 cart-button cart-button-warning-outline"}
          />
        </div>
      </div>

      <div className="header-middle-content middle">
        <div className="hide-in-mobile full-height padding5 center-aligned-column">
          <div className="heading1 center-aligned-column">Welcome - {applicationConfig?.user?.clientDisplayName || applicationConfig?.clientName}</div>
          {/* <img src="/assets/images/logos/estre.png" alt=""  className="full-height" /> */}

        </div>
      </div>

      <div className="header-right-content position-relative">

        <div className="display-flex-row no-wrap">

        {/* <StoreProductSearch isOpen={isOpenSearch} setIsOpen={setIsOpenSearch} /> */}

        <StartCustomerSession isOpen={isOpenStartSession} setIsOpen={setIsOpenStartSession} createCustomerCallback={()=>{}}/>

        {/* <div className="display-flex-row no-wrap pointer icon-wrapper" onClick={()=>{setIsOpenSearch(true)}}>
          <span className="center-aligned-column pointer-none position-relative "><i className="fas fa-search color-warning font-large"></i>
          </span>
        </div> */}


        {!applicationConfig?.isLiveClient && !getUser()?.isShareMode?
          <>
          {STORE?.activeCustomer?
          <div className="center-aligned-column">
          <div className="display-flex-row border-success position-relative no-wrap white-card shadow-light" style={{height:"40px"}} onClick={()=>{setIsOpenStartSession(true)}}>
            <div className="center-aligned-column">
              <div className="center-alinged-row no-wrap pointer icon-wrapper" >
                <span className="center-aligned-column pointer-none position-relative "><i className="fas fa-user color-warning font-large"></i>
                </span>
              </div>
            </div>
            <span className="top-left padding0 bg-color-success white-card shadow-light" style={{margin:"0.2rem",width:"0.4rem",height:"0.4rem",borderRadius:"50%",right:"-0.6rem",top:"0.3rem"}}></span>
            

            <span style={{padding:"0 10px 0 0"}} className="center-aligned-column heading3 font-small hide-in-mobile">{STORE.activeCustomer?.customerName}</span>
          </div>

          </div>
          
            
          :
          <div className="center-aligned-column">
          <div className="display-flex-row no-wrap  white-card shadow-light pointer icon-wrapper" style={{height:"40px",paddingLeft:"10px"}} onClick={()=>{setIsOpenStartSession(true)}}>
            <span className="center-aligned-column pointer-none position-relative "><i className="fas fa-user-plus color-warning font-large"></i>
            </span>
            <span style={{padding:"0 10px 0 0"}} className="center-aligned-column heading3 font-small marginleft5 hide-in-mobile">Start Session</span>
          </div>
          </div>
          }
        </>
        :null}

        

        

        {!getUser()?.isShareMode?
        <div className="display-flex-row no-wrap pointer icon-wrapper" onClick={showProductCart}>
          <span className="center-aligned-column pointer-none position-relative "><i className="fas fa-cart-plus color-warning font-large"></i>
            <div className="badge cart-item-counter" id="cartItemCounterInner">{cartItems?.length}</div>
          </span>
        </div>
        :null}


        {!applicationConfig?.isLiveClient && !getUser()?.isShareMode && !getUser()?.shareItemDetails?
          <div className="center-aligned-column" style={{marginLeft:"1.2rem"}}>
            <Button2
              configObj={{id:""}}
              action={()=>{setIsOpenAddProduct(true)}}
              label={""}
              fontAwesomeIcon={"fas fa-plus-square "}
              iconPos="left"
              classNames={"no-shadow   margin0 cart-button cart-button-warning"}
            />
          </div>
        :null}

        {getUser()?
            <div className="center-aligned-column user-options-link marginleft10 " onClick={toggleUserOption}>
              <div className="bg-color-warning color-white pointer-none uppercase center-aligned-column white-card no-shadow" style={{width:"2.5rem",height:"2.5rem",borderRadius:"50%"}}>
                {getUser()?.username.substring(0,2)}
              </div>
            </div>
          :
            <div className="center-aligned-column user-options-link">
                <div className="bg-color-warning color-white pointer-none uppercase center-aligned-column white-card no-shadow" style={{width:"2.5rem",height:"2.5rem",borderRadius:"50%"}}>
                  <i className="fas fa-user color-white"></i>
                </div>
            </div>
        }
        

        {!getUser()?.isShareMode?
        <UserOptions />
        :null}


        <div className="center-aligned-column">
          <div className="position-relative cart-icon click-effect white-card shadow-light center-aligned-column ion-margin-start" style={{width:"28px",height:"28px"}} onClick={fullScreenBrowserToggle}>
            <IonIcon className="color-primary font-normal" icon={expand} />
          </div>
        </div>

        

          


        </div>

        
      </div>

    </div>
    </>     
  );
};

export default MainHeader;
