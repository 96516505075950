import { IonIcon } from "@ionic/react";
import { ellipsisVertical } from "ionicons/icons";
import React from "react";
import { pointerDownAnnotationDrag, pointerMoveAnnotationDrag, pointerUpAnnotationDrag } from "../../../../../utils/customizein3d/area3dmodel/annotations";
import { configuration, performObjectAction } from "../../../../../utils/customizein3d/modules/customizein3d";
import { showHeightWindow, showResizerWindow } from "../../../../../utils/customizein3d/modules/UI_methods";
import { applicationConfig } from "../../../../../utils/methods";
import { openWardrobeModulePanel, toggleAttachWardrobeModule } from "../../../../../utils/customizein3d/modules/wardrobe";
import { STORE } from "../../../../../utils/store/storeConfiguration";
  
  
  const Annotations: React.FC<{
    configObj:any
  }> = (props) => {

 
    return (
    <div id="productAnnotationWrapper" className="display-flex-row hide-on-rotation no-wrap product-annotation zindex30">


          <div className="center-aligned-column marginRight5" id="annotationDragIcon">
            <div className="center-aligned-column border white-card shadow-light" onPointerDown={pointerDownAnnotationDrag}  onPointerMove={pointerMoveAnnotationDrag}  onPointerUp={pointerUpAnnotationDrag} style={{width:"15px",height:"25px",backgroundColor:"#fff",cursor:"grab"}}> 
                <div className="display-flex-row no-wrap pointer-none">
                  <IonIcon className="color-dark padding0  pointer-none" icon={ellipsisVertical}></IonIcon>
                </div>
            </div>
          </div>


          <div className="display-flex-row no-wrap">
            <div className="center-aligned-column pointer-none">
              <div id="" className="product-name module-name-heading border color-primary white-card shadow-light">
                
              </div>
            </div>

            {/* <div id="productInfoAnnotation" className="icon-container center-aligned-column border shadow-light" onClick={showActionPanel}>
                  <IonIcon className="icon   center-aligned-column" icon={constructOutline}></IonIcon>
              </div>

              <div id="productFinishAnnotation" className="icon-container center-aligned-column border shadow-light" onClick={showFinishPanel}>
                  <IonIcon className="icon   center-aligned-column" icon={colorPaletteOutline}></IonIcon>
              </div> */}

              
              <div id="moduleAttachAnnotation" className="icon-container display-none center-aligned-column border shadow-light" onClick={()=>toggleAttachWardrobeModule()}>
                <i className="fas fa-paperclip color-black font-small"></i>
              </div>

              {STORE.getCurrCategory() !== "Swing"?
               <div id="productDuplicateAnnotation" className="icon-container center-aligned-column border shadow-light"onClick={()=>performObjectAction("Duplicate")}>
                <i className="far fa-clone color-black font-small"></i>
              </div>
              :null}

            

              {STORE.getCurrCategory() !== "Swing"?
              <div id="productDeleteAnnotation" className="icon-container center-aligned-column border shadow-light" onClick={()=>performObjectAction("Delete")}>
                <i className="far fa-trash-alt color-black font-small"></i>
              </div>
              :null}

              


              <div id="productRotateAnnotation" className="icon-container display-none center-aligned-column border shadow-light" onClick={()=>performObjectAction("Rotate")}>
                  <i className="fas fa-undo color-black font-small"></i>
              </div>

              {configuration.isEnableModuleResizing && (STORE?.currProduct?.category_name === "Sofa")?
              <div id="productResizeAnnotation" className="icon-container display-none center-aligned-column border shadow-light" onClick={()=>showResizerWindow()}>
                  <img src={`${applicationConfig.aws_public_url}assets/icon/svg/dimensions.svg`} width={15} alt="" />
              </div>
              :null}

              {configuration.isEnableModuleResizing?
                <div id="productHeightAnnotation" className="icon-container display-none center-aligned-column border shadow-light" onClick={()=>showHeightWindow()}>
                <img src={`${applicationConfig.aws_public_url}assets/icon/svg/height.svg`} width={15} alt="" />
              </div>
              :null}

              <div id="wardrobeModuleAnnotation" className="icon-container display-none center-aligned-column border shadow-light" onClick={()=>openWardrobeModulePanel()}>
                <i className="fas fa-cog color-danger font-small"></i>
              </div>

             
        
          </div>
        </div> 
    );
  };
  
  export default Annotations;
  