import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import '../../theme/variables.css';
import '../style/admin/product.css';
import '../style/animations.css';
import '../style/controls/controls.css';
import '../style/cssvariables.css';
import '../style/ionic.css';
import '../style/layout.css';
import '../style/measurement-tool/measurement.css';
import '../style/style.css';
import '../style/ui-components/borders.css';
import '../style/ui-components/buttons.css';
import '../style/ui-components/design.css';
import '../style/ui-components/form.css';
import '../style/ui-components/tables.css';

import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';

import {
  API_INIT,
  apiGatwayAuthentication,
  createSession,
  getIpAddress,
  logout,
  tokenVerification
} from '../../services/api';
import PrivateRoute from '../../utils/PrivateRoute';
import PublicRoute from '../../utils/PublicRoute';
import { hideComponentLoader, showComponentLoader } from '../../utils/UI_methods/global';
import { Logging } from '../../utils/logging/logs';
import {
  generateSessionId,
  getToken,
  getUser,
  getUserType,
  globalMethodsInit,
  removeUserSession,
  showTokenExpiryScreen,
  updateUserSession
} from '../../utils/methods';
import { applicationConfig } from '../../utils/moodboard/applicationConfig';
import { Store } from '../../utils/store/storeConfiguration';
import Customers from '../admin/products/store/CRM/modules/customers/Customers';
import Leads from '../admin/products/store/CRM/modules/leads/Leads';
import Visitors from '../admin/products/store/CRM/modules/visitors/Visitors';
import Login from '../login';
import AccessDenied from '../common/modules/access-denied/AccessDenied';
import Tickets from '../admin/products/store/CRM/modules/tickers/Tickets';
import CustomerCreatedTickets from '../admin/products/store/CRM/modules/CustomerCreatedTickets/CustomerCreatedTickets';
import CreateTicketServiceDesk from '../admin/products/store/CRM/modules/CustomerCreatedTickets/CreateTicketServiceDesk';

 

const CrmController: React.FC = () => {
  

  const [clientName,setClientName] = useState<string>("");
  const [CLIENT_ID,setClientId] = useState<number>(0);


  const [BASE_PATH] = useState<string>("http://13.233.90.128/model-viewer/model-viewer/");
  // const [BASE_PATH_API] = useState<string>("https://ogtv5ck91f.execute-api.ap-south-1.amazonaws.com/");
 
 


  const [isLoadedPageConfiguration,setIsLoadedPageConfiguration]:Array<any> = useState(false);


  //Authentication and authorization
  const [isLogin, setIsLogin] = useState<boolean>(false);
  // const[token,setToken]:any = useState(null)
  

  //config objects
  const[applicationConfigObject,setApplicationConfigObject] = useState<applicationConfig>(null)
  const[storeConfig,setStoreConfig] = useState<any>(null)

  const[mainLoaderId] = useState<string>("mainLoader")




  const[isLoadedLogs,setIsLoadedLogs] = useState(false)


  var logs = null

  useEffect(()=>{
    let user = getUser()

   
    //Set is share mode false 
    if(user){
      if(user.userType === "admin"){
        updateUserSession(false,null)
      }
    }
    
  },[])




  useEffect(() => {
    if(!applicationConfigObject){
      return
    }
    let isDataLoaded = false

    // createLoader("Authenticating..");
    showComponentLoader(mainLoaderId)
    // updateLoaderProgress(mainLoaderId,1,1,"Fetching data..")
  
    let token:string = getToken()!



    if(token && !isDataLoaded){
      tokenVerification(token).then(async (data)=>{
          await apiGatwayAuthentication()
        


        //Check if user is online
        // if(!isError){
          let clientId = data?.clientId
          setClientId(data?.clientId)  
          setClientName(data?.clientName)
          applicationConfigObject.clientId = clientId
          applicationConfigObject.clientName = data?.clientName
          API_INIT({clientId:clientId,shareItemId:data?.shareItemId,moduleName:data?.moduleName})
          // let authorization = new Authorization(userType,moduleName,shareItemId)
          await applicationConfigObject.crm.data.fetch()
          setIsLogin(true)
          hideComponentLoader(mainLoaderId)
      
      }).catch(err=>{
        console.log(err)
        hideComponentLoader(mainLoaderId)
          handleLogout()
      })
    }else{
        hideComponentLoader(mainLoaderId)
        handleLogout()
    }
    return () => {
      isDataLoaded = true
    }
  // },[isLogin,shareToken]);
},[applicationConfigObject]);




  function handleLogout() {
    if(getUserType() === "customer"){
      showTokenExpiryScreen()
    }else{
      logout()
      .then((response:any) => {
        removeUserSession()
      })
      .catch((error:any) => {
        console.log(error)
      });
    }
  }



 
  useEffect(()=>{
    let configObj:any = new applicationConfig(CLIENT_ID,"",0,0,'','',[],{})
    let store:any = new Store()
    configObj?.setBasePathAssets(BASE_PATH)
    configObj.setUser(getUser())
    setStoreConfig(store)
    setApplicationConfigObject(configObj)
  },[])

  
  useEffect(()=>{
    if(applicationConfigObject){
      setLogs()
      globalMethodsInit(applicationConfigObject,logs)
    }
  },[isLogin,applicationConfigObject])
 


    function setLogs() {
      if(!isLoadedLogs){
        let sessionId = generateSessionId(32)
        logs = new Logging("testuser",sessionId)
        getIpAddress().then(ipInfo=>{
          saveSessionToDatabse(sessionId,ipInfo.ipAddress)
        }).catch(err=>{
          console.log(err)
          saveSessionToDatabse(sessionId,"")
        })
        globalMethodsInit(applicationConfigObject,logs)
      }
      setIsLoadedLogs(true)
    }

    function saveSessionToDatabse(sessionId:string,ipAddress:string) {
        createSession(sessionId,ipAddress).then(data=>{
          const insertId = data.data.data.id
          applicationConfigObject.setSessionId(insertId)
          logs.setSessionDatabaseId(insertId)
          logs.info("login","session",`START SessionId: ${sessionId}`)
        }).catch(err=>{
          console.log(err)
          logs.error("login","session",`START unable to create session:`)
        })
    }


    function setLoginTrue(){
      // setIsLogin(true)
    }
  

  return (

    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>


          <PublicRoute path="/login" component={()=>(<Login
            setLoginTrue = {setLoginTrue}
            // setToken={setToken}
          />)} exact={true} />


          <PrivateRoute 
            path="/"
            component={()=>(
              <Leads
                configObj = {applicationConfigObject}
                setIsLoadedPageConfiguration={setIsLoadedPageConfiguration}
                isLoadedPageConfiguration={isLoadedPageConfiguration}
                isLogin={isLogin}
              />
            )}
            exact={true}
          />

             

          <PrivateRoute 
            path="/leads"
            component={()=>(
              <Leads
                configObj = {applicationConfigObject}
                setIsLoadedPageConfiguration={setIsLoadedPageConfiguration}
                isLoadedPageConfiguration={isLoadedPageConfiguration}
                isLogin={isLogin}
              />
            )}
            exact={true}
          />

            <PrivateRoute 
              path="/customers"
              component={()=>(
                <Customers
                  configObj = {applicationConfigObject}
                  setIsLoadedPageConfiguration={setIsLoadedPageConfiguration}
                  isLoadedPageConfiguration={isLoadedPageConfiguration}
                  isLogin={isLogin}
                />
              )}
              exact={true}
            />

            <PrivateRoute 
              path="/visitors"
              component={()=>(
                <Visitors
                  configObj = {applicationConfigObject}
                  setIsLoadedPageConfiguration={setIsLoadedPageConfiguration}
                  isLoadedPageConfiguration={isLoadedPageConfiguration}
                  isLogin={isLogin}
                />
              )}
              exact={true}
            />

            <PrivateRoute 
              path="/tickets"
              component={()=>(
                <Tickets
                  configObj = {applicationConfigObject}
                  setIsLoadedPageConfiguration={setIsLoadedPageConfiguration}
                  isLoadedPageConfiguration={isLoadedPageConfiguration}
                  isLogin={isLogin}
                />
              )}
              exact={true}
            />

            <PrivateRoute 
              path="/customer-tickets"
              component={()=>(
                <CustomerCreatedTickets
                  configObj = {applicationConfigObject}
                  setIsLoadedPageConfiguration={setIsLoadedPageConfiguration}
                  isLoadedPageConfiguration={isLoadedPageConfiguration}
                  isLogin={isLogin}
                />
              )}
              exact={true}
            />

            {/* <Route 
              path="/create-ticket"
              component={()=>(
                <CreateTicketServiceDesk
                  configObj = {applicationConfigObject}
                  setIsLoadedPageConfiguration={setIsLoadedPageConfiguration}
                  isLoadedPageConfiguration={isLoadedPageConfiguration}
                  isLogin={isLogin}
                />
              )}
              exact={true}
            /> */}

          <Route path="/access-denied" component={()=>(<AccessDenied
            // setToken={setToken}
          />)} exact={true} />
           

          <Redirect to="/login" />

        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default CrmController;
