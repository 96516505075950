import React, { useState } from "react";
import { appendModel } from "../../../../utils/customizein3d/modules/customizein3d";
import LayoutOptions from "../../modules/customizein3d/layouts/LayoutOptions";
import ProductModules from "./ProductModules";
import { getUser } from "../../../../utils/methods";


const ProductModulesAndLayouts: React.FC<{
  product:any
  moduleRef:any
  action:(module:any)=>void
  moduleName:string
  dragStart:(event:any)=>void
  parentContainerId:string
  productModulesList?:Array<any>
  isAddonsOnly?:boolean
}> = (props) => {

  const[tabItems]:any = useState(["Modules","Layouts"])
  const[currTab,setCurrTab] = useState("Modules")
   
 
  return (
    <div className="full-height-width">

    {getUser()?.username?.includes("Shreedhar")?
      <div className="center-aligned-column marginTop10">
        <div className="display-flex-row no-wrap">
          {tabItems.map((currTabName:any,i:number)=>{
            return(
              <div key={i} onClick={()=>setCurrTab(currTabName)} className={`marginleft5 pointer ${currTab === currTabName?"bg-color-warning color-white":"border bg-color-light  color-black"}`} style={{padding:"0.5rem 1rem",borderRadius:"5px",whiteSpace:"nowrap"}}>
                <div className='heading3 text-ellipsis font-md-small text-center pointer-none' >{currTabName}</div>
              </div>
            )
          })}
        </div>
      </div>
    :
        null
    }

      

      


      {currTab === "Layouts"?
      <LayoutOptions/>
      :
        <ProductModules
            productId={props.product?.product_id}
            moduleRef={{}}
            action={(module: any) => appendModel(module, false)}
            moduleName="customizein3d"
            dragStart={()=>{}}
            parentContainerId={"addModulesContainer"}
          />
      }

    </div>
  );
};

export default ProductModulesAndLayouts;
