import { hideComponentLoader, showComponentLoader, showGlobalToast } from "../../UI_methods/global";
import { applicationConfig, getFilteredArray, stringWithoutWhiteSpace, waitFor } from "../../methods";
import { SofaVariantsConfiguration } from "../../store/customization-options-data/sofa";
import { STORE, Variant } from "../../store/storeConfiguration";
import { ModuleClass } from "./ModuleClass";
import { hideConfigOptionsWindow } from "./UI_methods";
import { appendModel, configuration, group, resetModulesGroup, scene } from "./customizein3d";


export class Layouts{
    variant:Variant
    currLayout:SofaLayout


    constructor(){
        
    }

    init(){
        let product = STORE.currProduct 
        if(product.category_name === "Sofa"){
            this.currLayout = new SofaLayout()
        }
    }
}

export class SofaLayout extends Layouts{
    layoutsList:Array<any> = []

    constructor(){
        super()
        this.init()
    }
    //Set Layouts List
    init(){
        this.getLayouts()
    }

    getPossibleLayouts() {
        let product = STORE.currProduct 
        let subCategory = stringWithoutWhiteSpace(product.sub_category_name.toLowerCase())
        if(subCategory === "lshape"){
            return [
                ["Left","Centre","Right"],
                ["Left_Lounger","Centre","Right"],
                ["Left","Centre","Right_Lounger"],
                ["Left","Centre","Centre","Right"],
                ["Left","Centre","Centre","Corner","Centre","Right"],
                ["Left","Centre","Centre","Corner","Centre","RightLounger"]
            ]
        }

        if(subCategory === "setsofas"){
            return [
                ["ThreeSeater","SingleSeater"],
                ["ThreeSeater","SingleSeater","SingleSeater"],
                ["ThreeSeater","DoubleSeater","SingleSeater"],
                ["ThreeSeater","DoubleSeater"],
            ]
        }
        return []
    }

    getLayouts(){
        let product = STORE.currProduct 
        let productModules = getFilteredArray(applicationConfig?.data?.productModules,"product_id",product.product_id)
        let possibleLayouts = this.getPossibleLayouts()

        for (const layoutModules of possibleLayouts){
            let modulesList = []
            for (const moduleName of layoutModules) {
                let module = productModules.find(currModule => currModule.module_name.toLowerCase().includes(moduleName.toLowerCase()))                
                if(module){
                    modulesList.push(module)
                }
            }
            if(layoutModules.length === modulesList.length){
                this.layoutsList.push(modulesList)
            }
        }
    }

    getLayoutsList(){
        return this.layoutsList
    }

    async create(moduleNames:Array<string>){
        let product = configuration.product  
        let isValidConfig = true
        let modulesList = []
        for (const moduleName of moduleNames) {
            let module = applicationConfig?.data?.productModules.find(currModule => currModule.product_name === product.productName && currModule.module_name.toLowerCase().includes(moduleName.toLowerCase()))
            if(!module){
                isValidConfig = false
                showGlobalToast("Config does't exists",2000,"error")
                return
            }
            modulesList.push(module)
        }
        configuration.reset()
        resetModulesGroup()
        await waitFor(1000)
        // scene.remove(group)
        showComponentLoader("mainLoaderSpinner")
        for (let i = 0; i < modulesList.length; i++) {
            const module = modulesList[i];
            await appendModel(module,false)
            await waitFor(200)
            if(i === modulesList.length - 1){
                hideComponentLoader("mainLoaderSpinner")
            }
        }
        
    }

    async loadDefault(){
        let product = configuration.product 
        let allVariants = SofaVariantsConfiguration.filter(currVariant => currVariant.categories.includes(stringWithoutWhiteSpace(product?.subCategoryName)))
        // for (const currVariant of allVariants) {
        //     let moduleNames = currVariant.configuration
        //     let module = applicationConfig?.data?.productModules.find(currModule => currModule.product_name === product.productName && currModule.module_name.toLowerCase().includes(moduleName.toLowerCase()))
        //     if(module){
        //         modulesList.push(module)
        //     }
            
        // }
        this.create(allVariants[0].configuration)
        hideConfigOptionsWindow()
    }

}


 
 