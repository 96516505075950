import React, { useEffect, useState } from "react";
import { getProductModules } from "../../../../../services/api";
import { applicationConfig, getObjectByParameter, getUser } from "../../../../../utils/methods";
import { getFormattedPrice } from "../../../../../utils/products/productviewer_shopify.js";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import ModuleImage from "../../../../common/ui-components/elements/ModuleImage";
import ProductImage from "../../../../common/ui-components/elements/ProductImage";
import CustomVariantMeasurements from "../product-customizer/measurements/CustomVariantMeasurements";
import ModuleMeasurement from "../product-customizer/measurements/ModuleMeasurement";
import SofaPartImage from "../variant-creator-3d/SofaPartImage";
import AddonsDetails from "./AddonsDetails";
import CustomInstructions from "./CustomInstructionsList";
import LegDetails from "./LegDetails";
import ModulesFinishDetails from "./ModulesFinishDetails";
import OtherDimensions from "./OtherDimensions";
import RunningFeet from "./RunningFeet";
 
const ProductConfigSummary: React.FC<{
    configuration:any
    product:any
    isShowModulesList?:boolean
    configuration3D?:any
}> = (props) => {
 

    const[isCustomVariant] = useState(props.configuration?.isCustomVariant)
    const[isCustomOrder] = useState(props.configuration?.isCustomOrder)
    const[customVariants] = useState(props.configuration?.customModulesList)
    const[currCategory] = useState(props.product?.category_name)
    const[isDataLoaded,setIsDataLoaded] = useState(false)
    const[readOnly] = useState(getUser()?.isShareMode)

    const[screenShotUrl,setUrl] = useState("")
    const[screenShotUrl2D,setUrl2D] = useState("")

    useEffect(()=>{
        setTimeout(() => {
            let configId = props.configuration3D?.configId || props.configuration?.configId
            let url = `https://opusassets.s3.ap-south-1.amazonaws.com/public/store/savedConfigs/${applicationConfig.clientName}/${props.product.category_name}/${encodeURIComponent(props.product.product_name)}/${configId}.png`
            setUrl(url)  
            let topurl =  `https://opusassets.s3.ap-south-1.amazonaws.com/public/store/summaryTopView/${applicationConfig?.clientName}/${props.product.category_name}/${props.product.product_name}/${configId}.png`
            setUrl2D(topurl)  
        }, 1500);
    },[])


    useEffect(()=>{
        loadApiData()
    },[])


    async function loadApiData() {
        if(isDataLoaded){
            return
        }
        let promiseArray = [getProductModules()]
        Promise.all(promiseArray)
        .then(function (apidata) {
            applicationConfig.data.productModules = apidata[0]
            setIsDataLoaded(true)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

 

    function ModulesList() {
        let modulesList = getModulesList()
        return(
            <div className="marginTop10">
                <div className="marginTop10">
                <div className="Pricing"></div>
                <table className="table store-quotation-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Image</th>
                            <th>Module</th>
                            {currCategory === "Wardrobe"?
                            <>
                            <th>Shelf</th>
                            <th>Handle</th>
                            </>
                            :null}
                            {/* <th>Description</th> */}
                            <th className="display-flex-row no-wrap">Configuration <SofaPartImage product={props.product}/> </th>
                            <th>Price</th>
                        </tr>
                    </thead>
                <tbody>
                    {modulesList?.map((currModule:any,i:number)=>{
                        let handleName = currModule.additionalConfiguration?.handle?.name
                        let shelfName = currModule.additionalConfiguration?.shelf?.name
                        let module = getObjectByParameter(applicationConfig?.data?.productModules,"module_name",currModule.moduleName)
                        return(
                            <tr key={i}>
                                <td>{i+1}</td>
                                <td className="center-aligned-column" style={{maxWidth:"12rem"}}> 
                                    {currCategory === "Sofa" ? 
                                    <ModuleImage module={module}/>:
                                    currCategory === "Swing"?
                                    <ScreenShots/>
                                    :<ProductImage classNames="no-border" product={currModule}/>} 
                                </td>
                                <td className="heading2 text-no-wrap">{module?.display_name || currModule?.moduleName}</td>

                                {currCategory === "Wardrobe"?
                                    <>
                                    <td>
                                        <div className="heading3">
                                            <img width={150} src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/store/icons/Wardrobe/shelf/${shelfName}${module.module_type || module.moduleType}.webp`} className="border" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                            <img width={150} src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/store/icons/Wardrobe/Handles/${handleName}.png`} className="border" alt="" />
                                    </td>
                                    </>
                                :null}

                                {/* <td>{module?.module_description}</td> */}
                                <td> <ModulesFinishDetails configuration3D={props.configuration3D} product={props.product} configurations={currModule?.configuration} /> </td>
                                <td>{currModule?.price?getFormattedPrice(currModule?.price ):"NA"}</td>
                            </tr>
                        )
                    })}
                </tbody>
                </table>
            </div>
            </div>
        )
    }

    function StorageOption() {
        let storage = props.configuration.storage
        return(
            <div className="marginTop10">
                <div className="marginTop10">
                <div className="Pricing"></div>
                <table className="table store-quotation-table">
                <tbody>
                    <tr>
                        <td>{storage.name} - {storage.type} {storage.size}</td>
                    </tr>
                </tbody>
                </table>
            </div>
            </div>
        )
    }


    function getModulesList(){
        let modulesList = []
        if(props.configuration3D){
            modulesList = props.configuration3D.modules || []
            if(props.configuration3D.isLshapeWithSingleSeater){
                let addonsList = [...props.configuration3D.addons]
                if(addonsList.length){
                    let singleSeaters = addonsList.filter(currAddon => (currAddon.moduleName === "SingleSeater" || currAddon.moduleName === "DoubleSeater" || currAddon.moduleName === "ThreeSeater") && currAddon.productName === props.product.product_name)
                    if(singleSeaters.length){
                        modulesList = [...modulesList,...singleSeaters]
                    }
                }
            }
        }else{
            modulesList = props.configuration?.customModulesList
        }
        return modulesList
    }


    
  function HeaderDetails() {
    return(
        <div className="marginTop10">
            <div className="marginTop10">
            <div className="Pricing"></div>
            <table className="table store-quotation-table border-top">
                <tbody>
                    <tr>
                        <td className="">Product Name</td>
                        <td className="">{STORE.getProductName(props.product)}</td>
                        <td className="">Qualiy of product</td>
                        <td className="capitalize">{props.configuration.quality}</td>
                    </tr>
                    <tr>
                        <td className="">Product Category</td>
                        <td className="">{props.product?.category_name}</td>
                        <td className="">Price</td>
                        <td className="capitalize">{getFormattedPrice(props.configuration.price)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        </div> 
    )
    }




    function ScreenShots(){
        return(
        <div className="display-flex-row no-wrap full-width marginTop5">
            <div className="position-relative border" style={{width:"100%",padding:"1rem 0 0 0"}}>
                <img src={screenShotUrl} className="full-width" alt="" />
            </div>
            {/* <div className="marginleft10 border position-relative" style={{width:"15rem",padding:"1rem 0 0 0"}}>
                <img src={screenShotUrl2D} className="full-width" alt="" />
                <span className="top-left font-small padding5 heading3">Top View</span>
            </div> */}
        </div>
        )
    }
  
 
  return (
    <>
    {isDataLoaded?
        <>
     <div className="full-width">

        {!isCustomOrder?
        <div className="pricing-details  white-card padding10 shadow-medium full-width overflow-x-scroll">
            <div className="heading1">{currCategory === "Sofa"?"Modules List":"Finish Details"}</div>
            {currCategory === "Sofa"?
                <ScreenShots/>
            :null}
            <div className=""><ModulesList/></div>
        </div>
        :null}

        {isCustomOrder?
        <div className="pricing-details  white-card padding10 shadow-medium ">
            <div className=""><HeaderDetails/></div>
        </div>
        :null}

        {/* <div className="pricing-details  marginTop10 white-card padding10 shadow-medium overflow-hidden">
            <div className="heading1">Fabric Details</div>
            <FabricDetails 
            configuration={props.configuration} 
            product={props.product}
            configuration3D={props.configuration3D}
            />
        </div> */}

        {currCategory === "Sofa"?
            <div className="pricing-details  marginTop10 white-card padding10 shadow-medium">
                <div className="heading1">Top view of product / Space Configuration</div>
                {props.product.sub_category_name !== "SetSofas"?
                <CustomVariantMeasurements product={props.product} isReadOnly={readOnly} configuration3D={props.configuration3D}/>
                :
                <ModuleMeasurement product={props.product} productConfiguration={props.configuration} configuration3D={props.configuration3D}/>
                }
            </div>
        :null}

        {currCategory === "Sofa" && props.product.customizable_legs ?
            <div className="pricing-details  marginTop10 white-card padding10 shadow-medium">
                <div className="heading1">Leg Details</div>
                <div className=""><LegDetails configuration3D={props.configuration3D} configuration={props.configuration} isReadOnly={readOnly}/></div>
            </div>
        :null}

        {currCategory === "Sofa" && props.product?.sub_category_name !== "Chair" && applicationConfig.clientName === "SiddhiSofas"?
            <div className="pricing-details  marginTop10 white-card padding10 shadow-medium">
                <div className="heading1">Dimensions</div>
                <div className=""><OtherDimensions configuration3D={props.configuration3D} configuration={props.configuration} isReadOnly={readOnly}/></div>
            </div>
        :null}

        {currCategory === "UpholsteredBed"?
            <div className="pricing-details  marginTop10">
                <div className="heading1">Storage Option</div>
                <div className=""><StorageOption/></div>
            </div>
        :null}


        {/* {props.configuration3D?.isShowRunningFeet?
            <div className="pricing-details  marginTop10 white-card padding10 shadow-medium full-width overflow-x-scroll">
                <div className="heading1">Pricing</div>
                <div className=""><RunningFeet configuration3D={props.configuration3D}/></div>
            </div>
        :null} */}
        
  
        {props.configuration3D?.addons?
            <div className="pricing-details  marginTop10 white-card padding10 shadow-medium full-width overflow-x-scroll">
                <div className="heading1">Add-ons related to this product</div>
                <div className=""><AddonsDetails configuration={props.configuration3D} product={props.product}/></div>
            </div>
        :null}

        <div className="pricing-details  marginTop10 white-card padding10 shadow-medium">
            <div className="heading1">Special instruction / Additional cusomization if any</div>
            {props.configuration?
            <div className=""><CustomInstructions configuration={props.configuration}/></div>
            :null}
        </div>
      

     </div>   


</>
    :null}
     </>
  );
};

export default ProductConfigSummary;
