import { IonModal } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { hideComponentLoader } from "../../../../utils/UI_methods/global";
import { applicationConfig } from "../../../../utils/methods";
import { getRandomNumber } from "../../../../utils/products/productviewer_shopify.js";
import QRcode from "../../../common/modules/customizein3d/canvas/QRcode";
import { saromQrCodes } from "../../../../utils/data";
import CollectionCode from "../../../common/modules/customizein3d/canvas/CollectionCode";

const CollectionGallery: React.FC<{
  companyName: string;
  gotoCustomizeIn3D: () => void;
  collectionName: string;
  imageHeight: any;
  collectionsList?: any;
}> = (props) => {
  const [images, setImages] = useState(["mainImage"]);
  const baseUrl = "https://opusassets.s3.ap-south-1.amazonaws.com/";
  const [refresh, setRefresh] = useState(getRandomNumber(1, 150));
  const [modelUrl, setModelUrl] = useState("");
  const [isOpenViewProduct, setIsOpenViewProduct] = useState(false);

  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [isOpenQrCode, setIsOpenQrCode] = useState(false);

  useEffect(() => {
    if (isOpenViewProduct) {
      hideComponentLoader("canvasLoader");
    }
  }, [isOpenViewProduct]);

  const [currGalleryIndexDetails, setCurrGalleryIndexDetails] = useState({
    index: 0,
    url: null,
  });

  useEffect(() => {
    if (props.collectionsList) {
      let array = [];
      for (const collection of props.collectionsList) {
        let image = `https://opusassets.s3.ap-south-1.amazonaws.com/public/store/ThumnailsCover/${props.companyName}/${collection}.png`;
        array.push(image);
      }
      console.log(props.collectionsList);
      setImages(array);
    } else {
      let frontimage = `https://opusassets.s3.ap-south-1.amazonaws.com/public/store/ThumnailsCover/${props.companyName}/${props.collectionName}.png`;
      let backImage = `https://opusassets.s3.ap-south-1.amazonaws.com/public/store/ThumnailsCover/${props.companyName}/${props.collectionName}.png`;
      setImages([frontimage, backImage]);
    }
  }, [props.collectionName, refresh]);

  return (
    <>
      <IonModal
        id=""
        cssClass="project-steps-modal"
        onDidDismiss={() => setIsOpenQrCode(false)}
        isOpen={isOpenQrCode}>
        <>
          {qrCodeUrl ? (
            <QRcode configObj={applicationConfig} url={qrCodeUrl} />
          ) : null}
        </>
        {/* {url?
            <QRcode 
              configObj={props.configObj}
              url = {url} 
            />
          :null} */}
      </IonModal>

      {props.collectionsList ? (
        <Carousel
          dynamicHeight={false}
          className="full-height"
          infiniteLoop={true}
          showArrows={true}
          showThumbs={false}
          autoPlay={false}>
          {images.map((image: string, index: number) => {
            return (
              <div className="center-aligned-column full-height-width position-relative">
                <img
                  key={index}
                  style={{
                    height: `${props.imageHeight}px`,
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={image}
                />
              </div>
            );
          })}
        </Carousel>
      ) : (
        <Carousel
          dynamicHeight={false}
          className="full-height"
          infiniteLoop={true}
          showArrows={true}
          showThumbs={false}
          autoPlay={false}>
          <div className="center-aligned-column full-height-width position-relative">
            <img
              style={{
                height: `${props.imageHeight}px`,
                width: "100%",
                objectFit: "contain",
              }}
              src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/store/ThumnailsCover/${props.companyName}/${props.collectionName}.png`}
            />
          </div>
          <div className="center-aligned-column full-height-width position-relative">
            <CollectionCode
              url={
                saromQrCodes.find(
                  (value) => (value) =>
                    value.collectionName === props.collectionName
                )?.url || ""
              }
            />
          </div>
        </Carousel>
      )}
    </>
  );
};

export default CollectionGallery;
