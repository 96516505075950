import axios from "axios";
import { AWS_AUTH_TOKEN, BASE_PATH, getTextures } from "./api";
import { applicationConfig, getRandomString } from "../utils/methods";
import { Address } from "../utils/store/storeConfiguration";


export class StoreApi{
  
  cart = new Cart()
  orders = new Orders()
  product = new Product()
  pricing = new Pricing()
  quotation = new Quotation()
  
  constructor(){

  }

  createCustomer = (userDetails:any) => {
    let data = {
      customerDetails:userDetails,
      clientId:applicationConfig.clientId
    }
    return axios
      .post(`${BASE_PATH}api/store/createCustomer`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  startCustomerSession = (customer:any) => {
    let data = {
      customerId:customer.id,
      adminSessionId:applicationConfig.sessionId,
      sessionId:getRandomString(32)
    }
    return axios
      .post(`${BASE_PATH}api/store/createCustomerSession`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  getCustomersList = () => {
    return axios
      .get(`${BASE_PATH}api/store/getCustomers?clientId=${applicationConfig.clientId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }


  getCustomerAddress = (customerId:number) => {
    return axios
      .get(`${BASE_PATH}api/store/getCustomerAddress?customerId=${customerId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }


  createAddress = (customerId:number,address:Address) => {
    let data = {
      customerId:customerId,
      address:address
    }
    return axios
      .post(`${BASE_PATH}api/store/createAddress`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

}


class Cart{
  constructor(){

  }

  add = (customerId:number,item:any) => {
    let data = {
      itemDetails:item,
      customerId:customerId
    }
    return axios
      .post(`${BASE_PATH}api/store/cart/add`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  getCartItems = (customerId:number) => {
    return axios
      .get(`${BASE_PATH}api/store/cart/getItems?customerId=${customerId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  delete = (itemId:number) => {
    let data = {
      itemId:itemId
    }
    return axios
      .post(`${BASE_PATH}api/store/cart/delete`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

}


class Orders{
  constructor(){

  }

  add = (customerId:number,item:any) => {
    let data = {
      orderDetails:item,
      customerId:customerId
    }
    return axios
      .post(`${BASE_PATH}api/store/orders/add`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  getOrders = (customerId:number) => {
    return axios
      .get(`${BASE_PATH}api/store/orders/getItems?customerId=${customerId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

}

class Product{
  constructor(){

  }

  getProductVariants = (productId:number) => {
    return axios
      .get(`${BASE_PATH}api/store/productVariants?productId=${productId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  getProductMaterials = () => {
    return getTextures()
  }

}


class Pricing{
  constructor(){

  }

  add = (productId:number,configurations:any,price:number) => {
    let data = {
      productId:productId,
      configurations:configurations,
      price:price,
    }
    return axios
      .post(`${BASE_PATH}api/store/pricing/add`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  get = (productId:number) => {
    return axios
      .get(`${BASE_PATH}api/store/pricing?productId=${productId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  getModulePrice = (productName:number) => {
    return axios
      .get(`${BASE_PATH}api/store/pricing/modules?productName=${productName}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

}
 


class Quotation{
  constructor(){

  }

  add = (customerId:number,quoteDetails:any) => {
    let data = {
      customerId:customerId,
      quoteDetails:quoteDetails,
    }
    return axios
      .post(`${BASE_PATH}api/store/quotation/add`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  get = (customerId:number) => {
    return axios
      .get(`${BASE_PATH}api/store/quotation?customerId=${customerId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  getProduct = (token:number) => {
    return axios
      .get(`${BASE_PATH}api/store/quotation/product?token=${token}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  approve = (token:string) => {
    let data = {
      token:token,
    }
    return axios
      .post(`${BASE_PATH}api/store/quotation/approve`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

}
 