import { EstreInventory, InventoryConfiguration } from "../EstreInventory/InventoryConfiguration";
import AwsConfig from "../aws-sdk/AwsConfig";
import { addItemToLocalStorage, getItemFromLocalStorage } from "../cache/localstorage";
import { applicationConfig as applicationConfigObject } from "../methods";
import { CrmConfig } from "../store/crm/crmConfiguration";
import { CartItems } from "../store/product-cart";
import { STORE, Store } from "../store/storeConfiguration";
 

export class applicationConfig{
  configuration: any;
  history:any = null
  projectId: number;
  areas: any;
  projectName: any;
  areaName: string;
  areaId: any;
  configName: any;
  allProducts: Array<any> | undefined;
  BASE_URL_ASSETS: string;
  clientName: string;
  savedConfiguration: any;
  projectAreaCategory: any[] | undefined;
  BASE_URL_MODEL_VIEWER: string | undefined;
  moodboardId: number;
  clientId: number;
  sessionId: number;
  functions: any = {}
  productId: number;
  productName: string;
  userType:string
  token:string
  user:any
  isShareMode: boolean;
  shareItemId: any = null;
  defaultClientName = "DemoClient2"
  liveClients = ["DashSquare","Ovion","SiddhiSofas","WoodmartFurniture",
    "AttalsFurnitureWorks","MujjuInternationalFurniture","CHICASA","MahajanDecor","TheLevelFurniture",
    "SheeshamFurniture","VatsalyaFurnitech","SugamSofas","FurnitureHouse","Encaasa","Aadikko","ZulaHouse","PerfectFurniture","HomeZone","OpaaHomes","ManaGoodu"]
  masterLogins = ["Kartik","Shreedhar","testuser","DemoMaster"]
  awsConfig:AwsConfig = new AwsConfig()
  moduleFilters:any = {
    "moodboard":{}
  }
  states: any = {};
  shareItemConfiguration: any = null
  filters = new Filters()
  
  isLiveClient:boolean = false

  store = new Store()
  crm = new CrmConfig()
  estreInventory = new InventoryConfiguration()

  data:any = {}

  sharedItem = new SharedItem()

  share:any

  currStoreFront:any = null


  aws_public_url = `https://opusassets.s3.ap-south-1.amazonaws.com/public/`

  constructor(
    clientId: number,
    clientName: string,
    projectId: number,
    areaId: number,
    projectName: string,
    areaName: string,
    areas: any,
    configuration: object
  ) {
    this.configuration = configuration;
    this.projectId = projectId;
    this.areas = areas;
    this.projectName = projectName;
    this.areaName = areaName;
    this.areaId = areaId;
    this.configName = null;
    this.clientId = clientId;
    this.clientName = clientName;

    let filters = getItemFromLocalStorage("filters")
    if(filters){
      this.filters.createFiltersFromSavedData(filters)
    }

    let sharedItem = getItemFromLocalStorage("sharedItem")
    if(sharedItem){
      this.sharedItem.id = sharedItem.id
      this.sharedItem.configuration = sharedItem.configuration
    }

  }

  setUser(user:any){
    if(user){
      this.user = user
      this.userType = user.userType || user.user_type 
    }
    
  }

  getClientName(product:any = null){
    if(!product){
      product = STORE?.currProduct
    }
    let clientName = this?.clientName
    let storefront_name = product?.storefront_name
    if(storefront_name){
      clientName = storefront_name === "OVL" ? "OVL" : this?.clientName
    }
    return clientName
  }

 


  setSessionId(id:number){
    this.sessionId = id
  }

  setShareItemId(id:any){
    this.shareItemId = id
  }

  setShareItemConfiguration(config:any){
    this.shareItemConfiguration = config
  }

  setConfiguration(key: string, configuration: any) {
    this.configuration[key] = configuration;
  }

  setProductName(name:string){
    this.productName = name
  }

  setSavedConfiguration(config: any) {
    this.savedConfiguration = config;
  }

  setProjectAreaCategories(data: Array<any>) {
    this.projectAreaCategory = data;
  }

  setBasePathAssets(path: string) {
    this.BASE_URL_MODEL_VIEWER = path;
  }

  setMoodboardId(id: number) {
    this.moodboardId = id;
  }

  getMoodboardId() {
    return this.moodboardId;
  }

  setIsShareMode(val:boolean){
    this.isShareMode = val
  }

  setData(data: any) {
    this.data = data;
  }

  updateData(key:string,data:Array<any>){
    this.data[key] = data
  }

  getData() {
    return this.data;
  }
  setAreas(areaList: Array<any>) {
    this.areas = areaList;
  }

  setCurrArea(areaName: string) {
    this.areaName = areaName;
  }
  setCurrAreaId(areaId: string) {
    this.areaId = areaId;
  }

  setCurrProjectName(name: string) {
    this.projectName = name;
  }
  setCurrProjectId(id: number) {
    this.projectId = id;
  }

  getCurrAreaId(){
    return this.areaId
  }

  setConfigName(configName: any) {
    this.configName = configName;
  }

  getConfigName() {
    return this.configName;
  }

  getCurrArea() {
    return this.areaName;
  }

  setProjectId(projectId: number) {
    this.projectId = projectId;
  }

  setProjectName(projectName: string) {
    this.projectName = projectName;
  }

  setAllProducts(allProducts: Array<any>) {
    this.allProducts = allProducts;
  }

  isMasterLogin(){
    let result = false
    let userName = this.user?.username
    if(userName){
      for (const currName of this.masterLogins) {
        if(userName.toLowerCase().includes(currName.toLowerCase())){
          result = true
        }
      }
    }
    return result
  }

  isAdmin(){
    return this.user?.userType?.toLowerCase() === "admin"
  }

  // setFunctions(functions: any) {
  //   this.functions = functions;
  // }


  setFunctionRef(moduleName:string,funInfo:any){
    this.functions[moduleName] = {
      ...this.functions[moduleName],
      ...funInfo
    }
  }

  setState(moduleName:string,stateInfo:any){
    this.states[moduleName] = {
      ...this.states[moduleName],
      ...stateInfo
    }
  }

  getAllProducts() {
    return this.allProducts;
  }

  getAreas() {
    return this.areas;
  }

  setCurrentConfiguration(configuration: any) {
    this.configuration = configuration;
  }

  getCurrentConfiguration() {
    return this.configuration;
  }

  getCurrentState() {
    return {
      // 'areaList':this.areaList,
      // 'areaViewList':this.areaViewList,
      // 'areaViewProductList':this.areaViewProductList,
      configuration: this.configuration,
    };
  }
  setProductId(productId: number) {
    this.productId = productId;
  }

  getProductId() {
    return this.productId;
  }

  setModuleFilter(moduleName:string,filterName:string,filterValue:string){
    this.moduleFilters[moduleName][filterName] = filterValue
  }

  getModuleFilters(moduleName:string){
    return this.moduleFilters[moduleName]
  }

  resetFilters(){
    this.filters = null
  }
}

// export class ProductModuleConfig extends applicationConfig{
//     productId: number | undefined

//     setProductId(productId:number){
//         this.productId = productId
//     }

//     getProductId(){
//         return this.productId
//     }
// }

class Filters{
  products:ProductsFilters = new ProductsFilters()
  constructor(){

  }
  createFiltersFromSavedData(filters:any){
    this.products = new ProductsFilters(filters.products)
  }
  updateLocalStorage(){
    addItemToLocalStorage("filters",this)
  }
}

class ProductsFilters{
  categoryName:string = ""
  subCategoryName:string = "All"
  recentConfigured:any = null

  constructor(data:any = null){
    if(data){
      this.categoryName = data.categoryName
      this.subCategoryName = data.subCategoryName
      this.recentConfigured = data.recentConfigured
    }
  }

  setRecentConfigured(product:any){
    this.recentConfigured = product
    applicationConfigObject.filters.updateLocalStorage()
  }
  setCategoryName(categoryName:any){
    this.categoryName = categoryName
    applicationConfigObject.filters.updateLocalStorage()

  }
  setSubCategoryName(subCategoryName:any){
    this.subCategoryName = subCategoryName
    applicationConfigObject.filters.updateLocalStorage()
  }
  
}



export class SharedItem{
  id:number
  configuration:any

  constructor(sharedItem:any = null){
    if(sharedItem){
      this.id = sharedItem.id
      this.configuration = sharedItem.configuration
    }
  }
}
