import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { hideComponentLoader, showGlobalToast } from '../../../../utils/UI_methods/global';
import { applicationConfig, getUser, waitFor } from '../../../../utils/methods';
import { getRandomNumber } from '../../../../utils/products/productviewer_shopify.js';
import Button2 from '../../../common/ui-components/buttons/Button2';
import ProductImage from '../../../common/ui-components/elements/ProductImage';
import { productViewerInit } from '../../../../utils/customizein3d/productViewer/productViewer';
import ProductViewer from '../productviewer/ProductViewer';
import Modal from '../../../common/shared-components/Modals/Modal';
import { isTouchDevice } from '../../../../utils/customizer/helper';
import { IonModal } from '@ionic/react';
import QRcode from '../../../common/modules/customizein3d/canvas/QRcode';
import { getShareLink } from '../../../../services/api';

  
const ProductGallery: React.FC<{
    product:any
    gotoCustomizeIn3D: (product: any) => void;
    imageHeight:number
}> = (props) => {

    const[images,setImages] = useState(["mainImage"])
    const baseUrl = "https://opusassets.s3.ap-south-1.amazonaws.com/";
    const[refresh,setRefresh] = useState(getRandomNumber(1,150))
    const[modelUrl,setModelUrl] = useState("")
    const [isOpenViewProduct, setIsOpenViewProduct] = useState(false);

    const[qrCodeUrl,setQrCodeUrl] = useState("")
    const [isOpenQrCode, setIsOpenQrCode] = useState(false);

    useEffect(()=>{
        if(isOpenViewProduct){
            hideComponentLoader("canvasLoader")
        }
    },[isOpenViewProduct])


    const[currGalleryIndexDetails,setCurrGalleryIndexDetails] = useState({
        index:0,
        url:null
    })

    useEffect(()=>{
        if(props.product){
            let categoryName = props.product.category_name
            let subCategoryName = props.product.sub_category_name
            let productName = props.product.product_name
            let albumName = "public/ProductGallery";
            var albumPhotosKey = `${albumName}/${applicationConfig.getClientName(props.product)}/${categoryName}/${subCategoryName}/${productName}/` ;
            applicationConfig.awsConfig.viewAlbumContents(albumPhotosKey)
              .then((photos: any) => {
                setImages(prevImage=>{
                    return [...prevImage,...photos.reverse()]
                })
                // setImages(photos.reverse());
              })
              .catch((err) => {
                console.log(err);
            });
        }
    },[props.product,refresh])


    function removeFromGallery(key:string){
        applicationConfig.awsConfig.deleteObjectFromS3(key).then(data=>{
            showGlobalToast("Success",2000)
            setImages(["Main"])
            setRefresh(getRandomNumber(1,150))
        }).catch(err =>{
            showGlobalToast("Error",2000,"error")
        })
    }

    async function setCurrModelUrl(path:any){
        return new Promise((resolve,reject)=>{
            let configHash = getConfigHash(path)
            let categoryName = props.product.category_name
            let subCategoryName = props.product.sub_category_name
            let productName = props.product.product_name
            let baseUrl = "https://opusassets.s3.ap-south-1.amazonaws.com/"
            let albumUrl = `public/models/${applicationConfig.getClientName(props.product)}/${categoryName}/${subCategoryName}/${productName}/${configHash}/`
            applicationConfig.awsConfig.viewAlbumContents(albumUrl).then((photos: any) => {
              let url = baseUrl + photos[0]
              resolve(url)
            })
            .catch((err) => {
                reject(null)
          });
        })
    }

    function Footer(){
        return (
          <div className="full-width center-aligned-column white-card shadow-light border-top" style={{height:"13rem"}}>
            <div className="display-flex-row no-wrap">
                {!getUser()?.shareItemDetails?
                <div className='display-flex-row no-wrap'>
                    <div style={{width:"10rem",height:"10rem"}} onClick={()=>{viewProductin3DAction()}} className="white-card pointer ion-margin-end center-aligned-column border shadow-light">
                        <div className="padding5 ">
                        <div className="center-aligned-row"><img src={`${applicationConfig.aws_public_url}assets/icon/svg/viewin3d.svg`} alt="" style={{width:"4rem"}} /></div>
                        <div className="heading2 marginTop5 text-center font-md-small store-color-primary">{"Quick 3D"}</div>
                        </div>
                    </div>

                    <div style={{width:"10rem",height:"10rem"}} onClick={()=>{props.gotoCustomizeIn3D(props.product)}} className="white-card pointer  center-aligned-column border shadow-light">
                        <div className="padding5 ">
                        <div className="center-aligned-row"><img src={`${applicationConfig.aws_public_url}assets/icon/svg/customize_in_3d.svg`} alt="" style={{width:"4rem"}} /></div>
                        <div className="heading2 marginTop5 text-center font-md-small store-color-primary">{"Customize in 3D"}</div>
                        </div>
                    </div>

                </div>
                :null}
    
                  
              </div>
          </div>
        )
      }


    async function viewProductin3DAction() {
        setIsOpenViewProduct(true)
        await waitFor(500)
        if(currGalleryIndexDetails.index === 0){
          productViewerInit(props.product,"productViewerWrapper")
        }else{
          productViewerInit(props.product,"productViewerWrapper",currGalleryIndexDetails.url)
        }
      }


    function getConfigHash(filePath:string) {
        let fileName = filePath.split('/').pop();
        let number = fileName.match(/\d{13}/); 
        return number ? number[0] : null;  
    }

    async function handleSlideChange(index:number){
        let path = images[index]
        await setCurrModelUrl(path).then(data=>{
                 let details = {
                    index:index,
                    url:data
                }
                setCurrGalleryIndexDetails(details)
        })
        // console.log(url)
   

    }

    async function ArClickHandle() {
        let url = currGalleryIndexDetails.url
        if(isTouchDevice()){
            viewProductin3DAction()
        }else{
            if(currGalleryIndexDetails.index === 0){
                url = `https://opusassets.s3.ap-south-1.amazonaws.com/public/models/${applicationConfig.getClientName(props.product)}/${encodeURIComponent(props.product.category_name)}/${encodeURIComponent(props.product.sub_category_name)}/${encodeURIComponent(props.product.product_name)}.glb`
            }
            let  data={
                moduleName:"product-viewer",
                shareItemId:[0],
                collectionNames:JSON.stringify({url:url}),
                clientId:applicationConfig?.clientId,
                clientName:applicationConfig?.clientName,
                tokenValidity: 1/24 // default 1 hr
            }
            getShareLink(data).then(data=>{
                let shareLink = window.location.origin + "/product-viewer?token=" + data.data.data.token
                setQrCodeUrl(shareLink)
                setIsOpenQrCode(true)
                }).catch(err=>{
                console.log(err)
                showGlobalToast("Error",2000,"Error")
            })
                
        }
       
      }
 

    return (
        <>
        <div className="full-height-width">

        <IonModal
            id=""
            cssClass="project-steps-modal"
            onDidDismiss={() => setIsOpenQrCode(false)}
            isOpen={isOpenQrCode}>
            <>
            {qrCodeUrl?
                <QRcode 
                    configObj={applicationConfig}
                    url = {qrCodeUrl} 
                />
            :null}
            </>
            
        </IonModal>


        <Modal
            isOpen = {isOpenViewProduct}
            Content = {()=>(
              <ProductViewer
                id="productViewerWrapper"
              />
            )}
            onDismiss = {()=>setIsOpenViewProduct(false)}
            heading={props.product?.product_name}
            classNames="vertical-modal"
            id=""
            FooterContent={()=>(<></>)}
        />

        {props.imageHeight?
        <Carousel onChange={(currentIndex) => handleSlideChange(currentIndex)} dynamicHeight={false} className='full-height'
        infiniteLoop={true} showArrows={true} showThumbs={false} autoPlay={false}>
           {images.map((image:string,index:number)=>{
               return(
                   <div className="center-aligned-column full-height-width position-relative" key={index}>
                       {index === 0?
                           <ProductImage product={props.product} style={{height:`${props.imageHeight}px`,width:"100%",objectFit:"contain"}}/>
                       :
                       <>
                           <img style={{height:`${props.imageHeight}px`,width:"100%",objectFit:"contain"}} src={baseUrl+image} />
                           {applicationConfig.isMasterLogin()?
                           <>
                           <div className='top-left' style={{zIndex:1001,paddingBottom:"3rem"}}>
                               <div onClick={()=>{removeFromGallery(image)}} className='padding10'>
                                   <i className='pointer-none fas fa-trash-alt color-danger'></i>
                               </div>
                           </div>
                           
                           </>
                           :null}
                       </>
                       }

                    
                      
                   </div>
               )
           })}
       </Carousel>
        
        :null}

                    <div className='top-middle display-none ion-padding' style={{zIndex:1001}}>
                        <Button2
                            configObj={{id:""}}
                            action={()=>{ArClickHandle()}}
                            label={"AR"}
                            fontAwesomeIcon={"fas fa-cube"}
                            iconPos="left"
                            classNames={"white-card no-border shadow-medium"}
                            svgUrl='assets/icon/svg/ar.svg'
                        />
                    </div>

            

      </div>
      <Footer/>
      </>
    );
};

export default ProductGallery;