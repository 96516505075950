import React, { useState } from "react";
import Button2 from "../../../common/ui-components/buttons/Button2";
import { applicationConfig, getObjectByParameter } from "../../../../utils/methods";
import { createUserForm } from "../../../../utils/createuser/createUserForm";
import { createUser, getUsersList, updateUser } from "../../../../services/api";
import { showToast } from "../../../../utils/UI_methods/global";

  const CreateClientUser: React.FC<{
    callBack:()=>void
    savedUser:any
  }> = (props) => {


    const[userDetails,setUserDetails] = useState(new createUserForm(0,"",props.savedUser))
    const[clientsList,setClientsList] = useState(applicationConfig?.data?.clientsList)
    const[error,setError] = useState(null)

    // useEffect(()=>{

    // },[])
    
 

    function setUserName(event:any) {
        userDetails.setUserName(event.target.value)
    }

    function setPassword(event:any) {
        userDetails.setPassword(event.target.value)
    }

    function setEmail(event:any) {
        userDetails.setEmail(event.target.value)
    }

    function setLocation(event:any) {
        userDetails.setLocation(event.target.value)
    }

    function setUserType(event:any) {
        userDetails.setUserType(event.target.value)
    }

    function setStatus(event:any) {
        userDetails.setStatus(event.target.value)
    }

    function submitHandle() {
        if(props.savedUser){
            updateUserAction()
        }else{
            createNewUser()
        }
    }

    function createNewUser() {
        if(!userDetails.clientId){
            setError("Please select client id")
            return
        }
        createUser(userDetails).then((response:any)=>{
            if(response.data.error){
                setError(response.data.message)
            }else{
                onSuccessAction()
            }
            showToast("User created successfully",2000) 
        }).catch(err=>{
            console.log(err)
        })
    }

    function updateUserAction() {
        if(!userDetails.clientId){
            setError("Please select client id")
            return
        }
        updateUser(userDetails).then((response:any)=>{
            if(response.data.error){
                setError(response.data.message)
            }else{
                onSuccessAction()
            }
            showToast("User updated successfully",2000) 
        }).catch(err=>{
            console.log(err)
        })
    }

    function onSuccessAction() {
        setError(null)
        getUsersList().then(data=>{
            console.log(data)
            applicationConfig.updateData("usersList",data)
            props.callBack()
        })
    }

    function updateClientId(id:number) {
        if(id === 0){
            setUserDetails(new createUserForm(0,"",null))
            return
        }
        setUserDetails(null)
        let client = getObjectByParameter(clientsList,"id",id)
        setUserDetails(new createUserForm(id,client.client_name,null))
    }
     
    return (
        <div className="full-width padding5">

            {error?
            <div className="color-danger heading2 font-md-small padding5">{error}</div>
            :null
            }


            <div className="full-width display-flex-row no-wrap">
                <div className="form-label-input-container" style={{width:"50%"}}>
                    <div className="color-black font-md-small marginBottom5 label" >Client Name</div>
                    <div className={props.savedUser?"input-container disable":"input-container"}>
                    <select className={"input input-mandatory"}  defaultValue={userDetails.clientId}    onChange={(e)=>updateClientId(Number(e.target.value))}>
                        <option value={0}>Select client</option>
                        {clientsList.map((client:any,index:number)=>{
                            return(
                                <option value={client.id} key={index}>{client.client_name}</option>
                            )
                        })}
                    </select>
                    </div>
                </div>

                <div className="form-label-input-container" style={{width:"50%"}}>
                    <div className="color-black font-md-small marginBottom5 label">User Type</div>
                    <div className="input-container">
                        <select className="input input-mandatory" defaultValue={userDetails.userType}  onChange={(e)=>setUserType(e)}>
                            <option value="">Select user type</option>
                            <option value="admin">Admin</option>
                            <option value="user">User</option>
                            <option value="InsideSales">InsideSales</option>
                            <option value="FieldSales">FieldSales</option>
                            <option value="CustomerCare">CustomerCare</option>
                            <option value="SalesSpecialist">SalesSpecialist</option>
                            <option value="Security">Security</option>
                            <option value="Manager">Manager</option>
                            <option value="Dispatcher">Dispatcher</option>
                        </select>
                    </div>
                </div>
            </div>
            

            

            <div className="form-label-input-container ion-margin-top">
                <div className="color-black font-md-small marginBottom5 label">Email Address</div>
                <div className="input-container">
                <input className="input" type="text" defaultValue={userDetails.email} onChange={(event)=>setEmail(event)}/>
                </div>
            </div>

            <div className="form-label-input-container ion-margin-top">
                <div className="color-black font-md-small marginBottom5 label logs-table">Username <span className={userDetails.clientId?"font-small":"display-none"}>(Auto generated. Edit if you want to change)</span> </div>
                <div className="input-container">
                <input className="input" type="text" defaultValue={userDetails.username} onChange={(event)=>setUserName(event)}/>
                </div>
            </div>

           

            <div className="form-label-input-container ion-margin-top">
                <div className="color-black font-md-small marginBottom5 label logs-table">Password {props.savedUser?" (Optional) Providing value will overwrite existing password":<span className={userDetails.clientId?"font-small":"display-none"}>(Auto generated. Edit if you want to change)</span>}  </div>
                <div className="input-container">
                <input className="input" type="text" defaultValue={userDetails.password} onChange={(event)=>setPassword(event)}/>
                </div>
            </div>

            <div className="form-label-input-container ion-margin-top">
                <div className="color-black font-md-small marginBottom5 label">Location</div>
                <div className="input-container">
                <input className="input" type="text" defaultValue={userDetails.location} onChange={(event)=>setLocation(event)}/>
                </div>
            </div>


            <div className="form-label-input-container ion-margin-top">
                <div className="color-black font-md-small marginBottom5 label">Status</div>
                <div className="input-container">
                    <select className="input input-mandatory" defaultValue={userDetails.status}  onChange={(e)=>setStatus(e)}>
                        <option value="">Select status</option>
                        <option value="1">Enable</option>
                        <option value="0">Disable</option>
                    </select>
                </div>
            </div>
           

            <div className="ion-margin-top">
                <Button2
                    configObj={applicationConfig}
                    action={submitHandle}
                    label={props.savedUser?"Update":"Submit"}
                    fontAwesomeIcon={"fas fa-user color-primary"}
                    iconPos=""         
                    classNames={"button-light  bg-color-primary no-shadow"}   
                />
            </div>

        </div>
    );  
  };
  
  export default CreateClientUser;
  