import $ from "jquery";
import { checkFromCache, untarAndSaveToCache } from "../cache/cache";
import { getCartKey, getItemFromLocalStorage } from "../cache/localstorage";
import { dropProductCustomizer } from "../customizein3d/area3dmodel/dragging";
import { applicationConfig, disposeVariables, getObjectByParameter, waitFor } from "../methods";
import { getProductImageUrl } from "../moodboard/helper";
import { dropImageMoodboard } from "../moodboard/moodboard";
import { closeSearch } from "../products/productSearch";
import { displayImages } from "../store/UI_methods";

// let awsConfig = new AwsConfig()


let updateLoaderValue = null

window.addEventListener("click",function(event) {
  if(! $(event.target)?.attr("class")?.includes("user-options-link")){
    closeUserOptions()
  } 
  if(! $(event.target)?.attr("class")?.includes("dropdown")){
    closeDropDowns()
  } 
  if(! $(event.target)?.attr("class")?.includes("nested-filter")){
    closeNestedDropDown()
  } 
  if(! $(event.target)?.attr("class")?.includes("more-options-button")){
    $(".more-options-window").removeClass("--is-active")
  } 
  closeSearch()
})

// history.pushState({ path: '/product-3dcustomization' }, '', '/product-3dcustomization');

// window.addEventListener('popstate', function(event) {
//   console.log(window.location.pathname)
//   if (window.location.pathname === '/product-3dcustomization') {
//     applicationConfig.functions.customizein3d.setShowGoBackAlert(true)
//     console.log('Back button pressed on /my-route');
//   }
// });

// window.addEventListener('beforeunload', function(event) {
//   event.preventDefault();
//   event.returnValue = '';
//   if (window.location.pathname === '/product-3dcustomization') {
//     return 'Are you sure you want to leave?';
//   }
// });



function closeNestedDropDown() {
  $("#nestedFilterParentMenu").fadeOut()
  $("#nestedFilterChildMenu").fadeOut()
}

export function showToast(message:string,time:number,type:string="success"){
    $("#toast").removeClass("show")
    $("#toast").addClass("show")
    $("#toast").find(".text").text(message)
    if(type==="success"){
      $("#toast").find("#icon").attr("class","fas fa-check-circle font-medium color-primary")
    }
    if(type==="error"){
      $("#toast").find("#icon").attr("class","fas fa-exclamation-circle font-medium color-danger")
    }
    setTimeout(() => {
        $("#toast").removeClass("show")
    }, time);
}


export function showGlobalToast(message:string,time:number=3000,type:string="success"){
  $("#globalToast").addClass("show")
  $("#globalToast").find(".text").text(message)
  if(type==="success"){
    $("#globalToast").find("#icon").attr("class","fas fa-check-circle font-medium color-success")
  }
  if(type==="error"){
    $("#globalToast").find("#icon").attr("class","fas fa-exclamation-circle font-medium color-danger")
  }
  setTimeout(() => {
      $("#globalToast").removeClass("show")
  }, time);
}


export function showAlert(className:string,message:string,type:string,time:number = 3000){
  const element = $(`.${className}`)
  if(element){
    element.removeClass("display-none")
    element.removeClass("success")
    element.removeClass("error")
    element.text(message)
    if(type==="success"){
      element.addClass("success")
    }
    if(type==="error"){
      element.addClass("error")
    }
    const closeButton = $("<div></div>",{
      class:"right-middle"
    }).html(`<i class="fas fa-times icon padding10"></i>`).on("click",()=>{
      element.addClass("display-none")
    })
    element.append(closeButton)


    setTimeout(() => {
      element.addClass("display-none")
    }, time);
  }
  
 
}

export function showNotificationToast(message:string,time:number,callBack:()=>void){
  $("#notificationToast").removeClass("show")
  $("#notificationToast").addClass("show")
  $("#notificationToast").find(".message").text(message)
  $("#notificationToast").find(".view-action").on("click",()=>{
    hideNotificationToast()
    callBack()
  })
  setTimeout(() => {
    hideNotificationToast()
  }, time);
}

export function hideNotificationToast(){
    $("#notificationToast").removeClass("show")
}

export function showCartToast(message:string,type="success"){
  $("#cartToast").fadeIn()
  if(type === "success"){
    $("#cartIcon").addClass("--is-active")
  }
  $("#cartToast").find("#cartToastMessage").text(message)

  $("#cartToast").find("#cartToastIcon").attr("class","fas fa-check-circle font-medium color-warning center-aligned-column marginRight5")
  
  if(type==="error"){
    $("#cartToast").find("#cartToastIcon").attr("class","fas fa-exclamation-circle font-medium color-danger center-aligned-column marginRight5")
  }

  setTimeout(() => {
    $("#cartIcon").removeClass("--is-active")
  }, 500);
  setTimeout(() => {
      $("#cartToast").fadeOut()
  }, 2000);
}

export function toggleDropdownMenu(event:any) {
  // if($(event.target).siblings(".dropdown-menu").hasClass("--is-active")){
  //   $(event.target).siblings(".dropdown-menu").removeClass("--is-active")
  //   return
  // }
  $(event.target).siblings(".dropdown-menu").toggleClass("--is-active")
}

export function setCanvasContainerAspectRatio(width:any,height:any,controlsContainerWidthPercent:any,leftContainerWidthPercent:any,moduleName:string){

    moduleName = moduleName.toLowerCase()
    let MAX_WIDTH_LEFT_WRAPPER = 165
    let MAX_WIDTH_CONTROLS_WRAPPER = 200

    let classNamesLeftWrapper:Array<string> = []
    let classNamesControlsWrapper:Array<string> = []
    let toggleButtonClassNames:Array<string> = []
    let controlstoggleButtonClassNames:Array<string> = []

    switch(moduleName.toLocaleLowerCase()){
      case "moodboard":
        MAX_WIDTH_LEFT_WRAPPER = 130
        classNamesLeftWrapper = ["--is-overlay --is-overlay-right"]
        toggleButtonClassNames = ["--is-visible-right"]

        classNamesControlsWrapper = ["--is-overlay --is-overlay-left"]
        controlstoggleButtonClassNames = ["--is-visible-left"]
      break;

      case "customizein3d":
        MAX_WIDTH_LEFT_WRAPPER = 165
        MAX_WIDTH_CONTROLS_WRAPPER = 180
        classNamesLeftWrapper = ["--is-overlay --is-overlay-left"]
        toggleButtonClassNames = ["--is-visible-left"]
        // classNamesControlsWrapper = ["--is-overlay --is-overlay-left"]
        controlstoggleButtonClassNames = ["--is-visible-bottom-middle"]
        classNamesControlsWrapper = [""]
      break;

      case "customizer":
        MAX_WIDTH_LEFT_WRAPPER = 130
        MAX_WIDTH_CONTROLS_WRAPPER = 0
        classNamesLeftWrapper = ["--is-overlay --is-overlay-left"]
        toggleButtonClassNames = ["--is-visible-left"]
        classNamesControlsWrapper = ["--is-overlay --is-overlay-right"]
        controlstoggleButtonClassNames = ["--is-visible-left"]
      break;
    }

    let data:any = getCanvasContainerWidthheight(moduleName)
    let remainingWidth = data.windowWidth-data.width
    let remainingHeight = data.windowHeight-(data.height+data.topOffset)
    let controlsContainerWidth = remainingWidth * controlsContainerWidthPercent
    let leftContainerWidth = remainingWidth * leftContainerWidthPercent

    if(moduleName === "customizer" || moduleName === "customizein3d"){
      $(".customize-canvas-wrapper").width(data.width)
      $(".customize-controls-wrapper").width(controlsContainerWidth+leftContainerWidth)
      $(".customize-controls-wrapper").removeClass("display-none")
      return
    }

    $(".customize-canvas-wrapper").width(data.width)
    $(".customize-canvas-wrapper").height(data.height)





    //If left controls width is less than the defined width hide it 
    if(leftContainerWidth < MAX_WIDTH_LEFT_WRAPPER){
      // $(".customize-left-wrapper").addClass("--is-hidden")
      $(".customize-left-wrapper").addClass(classNamesLeftWrapper.join(" "))
      //customizein3D module left side bar width is 0
      // if(leftContainerWidthPercent!==0){
      //   $(".toggle-left-wrapper").addClass(toggleButtonClassNames.join(" "))
      // }
      $(".toggle-left-wrapper").addClass(toggleButtonClassNames.join(" "))
      $("#leftSidebarClose").addClass("--is-active")

      $(".customize-controls-wrapper").width(controlsContainerWidth+leftContainerWidth)
      // return
    }else{
      //When window resized back to initial state
      $(".customize-left-wrapper").removeClass(classNamesLeftWrapper.join(" "))
      $(".toggle-left-wrapper").removeClass(toggleButtonClassNames.join(" "))
      $("#leftSidebarClose").removeClass("--is-active")

      //Set left wrapper width to its initial calculated width
      $(".customize-left-wrapper").width(leftContainerWidth)
      $(".customize-controls-wrapper").width(controlsContainerWidth)
    }

    //For the controls container // Ipad view // When both controls containers are hidden
    //If there is no remaining height
    if(controlsContainerWidth < MAX_WIDTH_CONTROLS_WRAPPER && data.windowWidth > 481 && remainingHeight>0){
      $(".customize-controls-wrapper").addClass(classNamesControlsWrapper.join(" "))
      $(".toggle-controls-wrapper").addClass(controlstoggleButtonClassNames.join(" "))
      
      updateControlsWrapperPosition(100,40)
      //For Ipad view drag controls window
      let element = document.getElementById("controlsDragIcon")!
      if(element){
        element?.addEventListener("touchmove",startMoving,false)
      }
    }else{
      $(".customize-controls-wrapper").removeClass(classNamesControlsWrapper.join(" "))
      //When coming back from Ipad view
      updateControlsWrapperPosition(0,0)
      $(".toggle-controls-wrapper").removeClass(controlstoggleButtonClassNames.join(" "))
    }
  }

  function startMoving(event:any) {
    let touchLocation = event.targetTouches[0];
    updateControlsWrapperPosition(touchLocation.pageX,touchLocation.pageY)
  }

  function updateControlsWrapperPosition(x:any,y:any) {
    $(".customize-controls-wrapper").css("left",x + 'px');
    $(".customize-controls-wrapper").css("top",y + 'px');
  }

  export function getWindowWidth(){
    return $(window).width()
  }

  export function getWindowHeight(){
    return $(window).height()
  }

  export function getCanvasContainerHeight(){
    return $(".customize-canvas-wrapper").height()
  }

  export function  getCanvasContainerWidthheight(moduleName:string) {

    const pathName = window.location.pathname
    let domain = window.location.hostname
    domain = domain.toLowerCase()

    moduleName = moduleName.toLowerCase()
    let finalWidth = 0
    let finalHeight = 0
    // let canvasContainer = $(".customize-canvas-wrapper")


    //For I pad pro
    let windowHeight:any = $(window).height(); 
    let windowWidth:any = $(window).width(); 
    let topOffsetContainer:any = $(".main-header-wrapper").height()
    // topOffsetContainer = Number(topOffsetContainer.replace("px","")) 


    if(pathName.includes("/store/") && moduleName.toLowerCase() === "moodboard"){
      const paddingBottom = 30
      return{
        width:$(".customize-canvas-wrapper").width(),
        height:windowHeight - $(".main-header-wrapper").height() - $(".main-sub-header-wrapper").height() - paddingBottom,
        windowWidth:windowWidth,
        windowHeight:windowHeight,
        topOffset:topOffsetContainer
      }
    }

    if((pathName.includes("/store/") && moduleName.toLowerCase() === "customizein3d") || domain.includes("elevate") && moduleName.toLowerCase() === "customizein3d"){
      topOffsetContainer = $(".main-header-wrapper").height() - $(".main-sub-header-wrapper").height()
      return{
        width:windowWidth,
        height:windowHeight - topOffsetContainer,
        windowWidth:windowWidth,
        windowHeight:windowHeight,
        topOffset:topOffsetContainer
      }
    }

    
    if(moduleName === "moodboard"){    
      topOffsetContainer = $("#customizerHeader").height() || 0
    }

    if(moduleName==="customizer"){
      return{
        width:windowWidth,
        height:windowHeight - topOffsetContainer,
        windowWidth:windowWidth,
        windowHeight:windowHeight,
        topOffset:topOffsetContainer
      }
    }

    if(moduleName.toLowerCase() === "customizein3d" || moduleName.toLowerCase() === "moodboard"){
      return{
        width:windowWidth,
        height:windowHeight - topOffsetContainer,
        windowWidth:windowWidth,
        windowHeight:windowHeight,
        topOffset:topOffsetContainer
      }
    }

    if((moduleName.toLowerCase() === "moodboard" && windowWidth <= 480) || (moduleName.toLowerCase() === "customizein3d" && windowWidth <= 480)){
      finalWidth = windowWidth
      finalHeight = windowHeight * 0.55
      return{
        width:finalWidth,
        height:finalHeight,
        windowWidth:windowWidth,
        windowHeight:windowHeight,
        topOffset:topOffsetContainer
      }
    }

    //For Ipad and Ipad pro
    if((windowWidth === 1366 && windowHeight === 1024) || (windowWidth === 1024 && windowHeight === 768)){
      return{
        width:windowWidth,
        height:windowHeight - topOffsetContainer,
        windowWidth:windowWidth,
        windowHeight:windowHeight,
        topOffset:topOffsetContainer
      }
    }

    // let topOffsetContainer:any = canvasContainer?.offset()?.top

    //Mobile device potrait mode
    //calculate height frmo width
    //Else calculate width from height
    //Height for Ipad and Ipad pro

    // let remainingHeight = windowHeight-((windowWidth*3)/4)
    // if(windowWidth <= 480 || remainingHeight > 420 ){
    //   finalWidth = windowWidth
    //   finalHeight = windowWidth
    // }else{
    //   finalHeight = windowHeight - topOffsetContainer
    //   finalWidth = (finalHeight*width)/height
    // }
    // return{
    //   width:finalWidth,
    //   height:finalHeight,
    //   windowWidth:windowWidth,
    //   windowHeight:windowHeight,
    //   topOffset:topOffsetContainer
    // }
  
  }


  export function handleLeftWrapperToggle(position:string) {
    
    if(position==='left'){
      $(".toggle-left-wrapper").toggleClass("--is-active-left")
      $(".customize-left-wrapper").toggleClass("--is-visible-left")
    }else{
      $(".toggle-left-wrapper").toggleClass("--is-active-right")
      $(".customize-left-wrapper").toggleClass("--is-visible-right")
    }
  }

  

  export function handleRightWrapperToggle(position:string) {
    // if(position==='left'){
    //   $(".toggle-right-wrapper").toggleClass("--is-active-left")
    //   $(".customize-controls-wrapper").toggleClass("--is-visible-left")
    // }else{
    //   $(".toggle-right-wrapper").toggleClass("--is-active-right")
    //   $(".customize-controls-wrapper").toggleClass("--is-visible-right")
    // }

    $(".customize-controls-wrapper").fadeToggle()
  }


  export function setElementInnerContent(data:string,id:string) {
    // $(`#${id}`).html(data)
    // document.getElementById(id)
    $(`#${id}`).text(data)
  }

  export async function updateCartCounter(){
    await waitFor(500)
    let key = getCartKey()
    let items = getItemFromLocalStorage(key)
    let count = items?.length || 0

    $(`.cart-item-counter`)?.text(String(count))
    $(`.cart-item-counter`)?.text(String(count))
  }

  export async function updateNotificationsCounter(){
    await waitFor(500)
    let items = getItemFromLocalStorage("notifications")
    let count = items?.length || 0

    $(`.notification-counter`)?.text(String(count))
    $(`.notification-counter`)?.text(String(count))
  }

  export function getHeaderHeight(){
    return $(".main-header-wrapper").height() || 0
  }

  export function showContainer(container:string) {
    $(`${container}`).addClass('--is-active')
  }
  export function hideContainer(container:string) {
    $(`${container}`).removeClass('--is-active')
  }

  export function fullScreenBrowserToggle() {
    if(document.fullscreenElement){
      disableBrowserFullScreen()
      return
    }
    enableBrowserFullScreen()
  }

  export function enableBrowserFullScreen() {
    setTimeout(() => {
      document.body.requestFullscreen()
      $("#landscapeWarningWrapper").addClass("display-none")
    }, 500);
  }
  export function disableBrowserFullScreen() {
    if(document.fullscreenElement){
      document.exitFullscreen()
      if((window.orientation == 90 || window.orientation == -90) && getWindowHeight() < 480){
        $("#landscapeWarningWrapper").removeClass("display-none")
      }else{
        $("#landscapeWarningWrapper").addClass("display-none")
      }
      return
    }
  }

  export function handleDropDownToggle(event:any) {
    if($(event.target).siblings(".dropdown-menu").hasClass("--is-active")){
      closeDropDowns(event)
      return
    }
    closeDropDowns(event)
    $(event.target).addClass("--is-active")
    toggleDropdownMenu(event)
  }

  export function closeDropDowns(event:any = null) {
    //In case of products search 
    if(!event){
      $(".dropdown-menu").removeClass("--is-active")
      $(".dropdown-toggle").removeClass("--is-active")
      return
    }

    $(".dropdown-menu").removeClass("--is-active")
    $(".dropdown-toggle").removeClass("--is-active")
  return
  }

  export function showComponentLoader(id:string,className:string=null) {
    if(id){
      $("#"+id).addClass("--is-active")
    }
    if(className){
      $("."+className).addClass("--is-active")
    }
  }

  function getScrollDelta(scrollTo:string) {
    if(scrollTo==="left"){
      return 0
    }
    return getWindowWidth()<480?0:90
  }
 
  export function centerElement(containerId:string,itemText:any,scrollTo:string="center") {
    //WrapperId

    let scrollContainer = $('#'+containerId)

    let allItems = scrollContainer.find(".item")

    let length = 0
    let scrollLength = 0
    let itemWidth = 0

    let delta = getScrollDelta(scrollTo)
     

    for (let i = 0; i < allItems.length; i++) {
      const element = allItems[i];
      itemWidth = $(element).parent().outerWidth(true)

      if(element.innerText === itemText){
        length = i
        break
      }
      scrollLength = scrollLength + itemWidth || 0
    }


    scrollContainer.animate({
        scrollLeft: scrollLength - delta
      }, 600
    );
  }


  export function removeActiveClassFromAllTextures() {
    $('.checkmark').remove()
    $('.texture-label-container').removeClass("--is-active")
  }

  export   function addActiveClassOnTexture(element:any){
    element = $(element)
    removeActiveClassFromAllTextures()
    let checkmarkElement = $('<span></span>')
    let thumbnailURL = './assets/images/checkmark.png'
    checkmarkElement.css({ 'background-image': 'url(' + thumbnailURL + ')'});
    checkmarkElement.addClass('checkmark')
    $(element).children().eq(0).append(checkmarkElement)
    // $('.texture-label-container').removeClass('--is-active')
    // $(element).parent().parent().addClass('--is-active')
  }

  export function addActiveClass(element:string) {
    $(element).addClass("--is-active")
  }
  export function toggleActiveClass(element:string) {
    $(element).toggleClass("--is-active")
  }

  export function removeActiveClass(element:string) {
    $(element).removeClass("--is-active")
  }

  
  export function toggleClassInElement(id:string,className:string) {
    $("#"+id).toggleClass(className)
  }


  export function addClassInElement(id:string,className:string) {
    $("#"+id).addClass(className)
  }

  export function removeClassFromElement(id:string,className:string,isGetElementFromClass:boolean = false) {
    if(isGetElementFromClass){
      $("."+id).removeClass(className)
    }else{
      $("#"+id).removeClass(className)
    }
 
  }

  export function focusOnInput(id:string) {
    $("#"+id).focus()
  }

  export function openItemsWrapper(event:any) {
    let element = $(event.target).siblings(".items-list-wrapper")
    element.addClass("--is-active")
  }

  export function hideComponentLoader(id:string,className:string=null) {
    setTimeout(() => {
      if(id){
        $("#"+id)?.removeClass("--is-active")
        $("#"+id+"progressBar").css("width",0)
        $("#"+id+"progressBarValue").text('0%')
        // $("#"+id+"progressMessage").text('Please wait')
      }
      if(className){
        $("."+className)?.removeClass("--is-active")
      }
    }, 300);
  }

  export function addNewBadgeClassToElement(elementId:string) {
    $("#"+elementId).addClass("new-badge-container")
  }
  
  export function removeNewBadgeClassFromElement(elementId:string) {
    $("#"+elementId).removeClass("new-badge-container")
  }


  export function updateLoaderProgress(id:string,loaded:Number,total:number,message:string) {
    if(total){
      let width:any = (Number(loaded)/Number(total)) * 100
      $("#"+id+"progressBar").css("width",width+'%')
      width = Math.floor(width)
      $("#"+id+"progressBarValue").text(width+'%')
      $("#"+id+"progressMessage").text(message)
    }
  }

  export function updateLoaderPercentage(id:string,loaded:Number,total:number) {
    if(total){
      let percentage:any = (Number(loaded)/Number(total)) * 100
      $("#"+id+"percentages").text(String(Math.floor(percentage)))
    }
  }





  export function loadAndDisplayImages(awsConfig:any,elements:Array<any>) {
    let companyName = elements[0].company_name
    let collectionName = elements[0].collection_name
    let key = awsConfig.getTarFileKey("thumbnails",{companyName:companyName,collectionName:collectionName})
    untarAndSaveToCache(awsConfig,`thumbnails/${companyName}/${collectionName}`,key)?.then(data=>{
      // console.log(data)
      setTimeout(() => {
        // displayThumbnails()
      }, 200);
    }).catch(err=>{
      // displayThumbnails()
    })
  } 


  export function ifEmptyFields() {
    let err = false
    let inputs = document.querySelectorAll(".input-mandatory")
    for (let i = 0; i < inputs.length; i++) {
      const inputElement:any = inputs[i];
      let value = inputElement.value
      if(value===""){
        $(inputElement).addClass("mandatory")
        err = true
      }else{
        $(inputElement).removeClass("mandatory")
      }
    }
    return err
  }



  export function showElement(id:string) {
    $("#"+id).fadeIn()
  }

  export function hideElement(id:string) {
    $("#"+id).fadeOut()
  }


  export function filterUIElements(elementWrapperClass:string,dataAttribute:string,value:string,parentContainerId:string=null) {
    let parentWrapper:any = document.getElementById(parentContainerId)!
    let element:any = []
    if(parentWrapper){
      element = parentWrapper.querySelectorAll(elementWrapperClass)!;
    }else{
      element = document.querySelectorAll(elementWrapperClass)!;
    }
    for (let i = 0; i < element.length; i++) {
        let searchString = element[i].getAttribute(dataAttribute)!
        if (searchString === value ) {
          element[i].style.display = "";
        } else {
          element[i].style.display = "none";
        }
    }
  }

  export function searchProject() {
    // let productsWrapper = document.getElementById("productsContainer")!;
    let element:any = document.querySelectorAll(".project-container")!;
    let input:any = document.getElementById("projectSearchInput")!;
    
    let searchString = String(input.value.toLowerCase());

    for (let i = 0; i < element.length; i++) {
        let clientName = element[i]?.getAttribute("data-client-name")!
        let location = element[i]?.getAttribute("data-project-location")!
        if (clientName?.toLowerCase().indexOf(searchString) > -1 || location?.toLowerCase().indexOf(searchString) > -1) {
          element[i].style.display = "";
        } else {
          element[i].style.display = "none";
        }
    }
  }

  export function displayAllMoodboardImages(){
    let parentContainer = getParentContainer()
    let productContainers:any = parentContainer.querySelectorAll(".product-image-container")!;
    for (let i = 0; i < productContainers.length; i++) {
        productContainers[i].style.display = "";
    }
  }

  export function searchCategory(parentContainerId:string,idSuffix:string) {
    let parentContainer = document.getElementById(parentContainerId)
    let productContainers:any = parentContainer.querySelectorAll(".cat-sub-wrapper")!;

  
    let input:any = parentContainer.querySelector("#categorySearch"+idSuffix)!;


    let searchString = String(input?.value?.toLowerCase()) || ""
    // if(!searchString.length){
    //   return
    // }

    for (let i = 0; i < productContainers.length; i++) {
        let categoryName = productContainers[i].getAttribute("data-category-name")!
        if (categoryName?.toLowerCase().indexOf(searchString) > -1) {
          productContainers[i].style.display = "";
        } else {
          productContainers[i].style.display = "none";
        }
    }
  }


  export function searchTexture(){
    let input:any, searchString, texturesWrapper,textureContainer:any;
    // texturesWrapper = document.getElementById("textureContainer")!;
    texturesWrapper = getParentContainer();
    textureContainer = texturesWrapper.querySelectorAll(".texture-label-container")!;
    input = texturesWrapper.querySelector("#textureSearch")!;
    searchString = input.value.toUpperCase();

    for (let i = 0; i < textureContainer.length; i++) {
      // td = tr[i].getElementsByTagName("td")[0];
      // if (td) {
        // txtValue = td.textContent || td.innerText;
        let collectionName = textureContainer[i].getAttribute("data-collection-name")!
        let materialCode = textureContainer[i].getAttribute("data-material-code")!
        let companyName = textureContainer[i].getAttribute("data-company-name")! 
        if (materialCode.toUpperCase().indexOf(searchString) > -1 || collectionName.toUpperCase().indexOf(searchString) > -1 || companyName.toUpperCase().indexOf(searchString) > -1) {
          textureContainer[i].style.display = "";
        } else {
          textureContainer[i].style.display = "none";
        }
      // }    
    }
    disposeVariables([texturesWrapper,textureContainer])
  }


  export function toggleThemeSetting(){
    $(".theme-setting-wrapper").toggleClass("--is-active")
  }

  export function closeThemeSetting(){
    $(".theme-setting-wrapper").removeClass("--is-active")
  }

  export function updateLoaderProgressWithInterval(loaderId:any,message:string,timeInterval:number = 60,currLoaded:number = 1) {
    updateLoaderValue = setInterval(async () => {
      currLoaded = currLoaded +1
      if(currLoaded >= 100){
        currLoaded = 100
      }
      updateLoaderProgress(loaderId,currLoaded * 0.9,100,message)
    }, timeInterval)
  }

  export function clearProgressBarInterval() {
    clearInterval(updateLoaderValue)
  }



  export function getParentContainer(){
    let element = null
    if(window.location.pathname.includes("/product-categories")){
      element =  document.getElementById("mainProductsCategoryContentWrapper") 
    }
    if(window.location.pathname.includes("/projects")){
      element =  document.getElementById("mainProjectsContentWrapper") 
    }
    if(window.location.pathname.includes("/dashboard") || window.location.pathname.includes("/home")){
      element =  document.getElementById("mainDashboardContentWrapper") 
    }
    if(window.location.pathname.includes('/edit-moodboard')){
      element =  document.getElementById("createMoodboardWrapper") 
    }
    if(window.location.pathname.includes("/finish")){
      element =  document.getElementById("mainFinishContentWrapper") 
    }
    if(window.location.pathname.includes("/customize")){
      element =  document.getElementById("mainCustomizerWrapper") 
    }
    if(window.location.pathname.includes("/product-3dcustomization")){
      element =  document.getElementById("mainCustomizein3DWrapper") 
    }
    if(window.location.pathname.includes("/customizein3d")){
      element =  document.getElementById("mainCustomizein3DWrapper") 
    }
    if(window.location.pathname.includes("/3dwalkthrough")){
      element = document.getElementById("main3dwalkthroughWrapper") 
    }
    
    return element || document
  }

   export  function scrollLeft (id:string,scroll:any){
      let ele = $('#'+id)
      var leftPos = ele.scrollLeft();
      ele.animate({
          scrollLeft: leftPos - 200
        }, 600
      );
  }

  export function scrollRight(id:string,scroll:any){
      // ele.scrollTo(scroll,0)
      let ele = $('#'+id)
      var leftPos = ele.scrollLeft();
      ele.animate({
          scrollLeft: leftPos + 200
        }, 600
      );
  }


  export async function displayThumbnails(textureLoaderId:any, className:any) {
    showComponentLoader(textureLoaderId);
    let thumbnails = document.querySelectorAll(".finish-image-container");
    // let promises = [];
  
    for (let i = 0; i < thumbnails.length; i++) {
      let companyName = thumbnails[i].getAttribute("data-company-name");
      let collectionName = thumbnails[i].getAttribute("data-collection-name");
      let materialCode = thumbnails[i].getAttribute("data-material-code");
      let key = `thumbnails/OVL/${companyName}/${collectionName}/${materialCode}.png`;
      let image = $(thumbnails[i]).find("img");

      await displayImages(image[0],key)

      if(i === thumbnails.length-1){
        hideComponentLoader(null,"materials-loader");
      }
  
      // let promise = checkFromCache(key)
      //   .then((url: any) => {
      //     image.attr("src", url);
      //     return Promise.resolve();  
      //   })
      //   .catch((err) => {
      //     image.attr("src", "")
      //     console.log(err);
      //     return Promise.resolve();  
      //   });
  
      // promises.push(promise);
    }
    // await Promise.all(promises);
    // All textures are loaded, hide the loader
  }
  


  export function setUploadedImage(imageUrlKey:string) {
    checkFromCache(imageUrlKey)?.then((url:any)=>{
      $("#uploadedImage").css("display","block")
      $("#uploadedImage").attr("src",url)
    }).catch(err=>{
      console.log(err)
    })
  }


  export function hideImage(event:any) {
    $(event.target).css({"visibility":"hidden"})
  }



  export function toggleUserOption() {
    $(".header-user-options").toggleClass("--is-active")
  }

  export function closeUserOptions(){
    $(".header-user-options").removeClass("--is-active")
  }

  export function stopPropagation(event:any) {
    event.stopPropagation()
  }

  export function toggleFiltersWindowMobile() {
    $(".main-sidebar-filters-wrapper").toggleClass("--is-active")
  }

  export function openFiltersWindowMobile() {
    $(".main-sidebar-filters-wrapper").addClass("--is-active")
  }

  export function closeFiltersPanelMobile() {
    $(".main-sidebar-filters-wrapper").removeClass("--is-active")
  }

  export function updateFillbarPosition() {
    return
    //Get the current active element index
    let index = 0
    let parentContainer = getParentContainer()
    let stepContainers = parentContainer.querySelectorAll(".step-container")
    for (let i = 0; i < stepContainers.length; i++) {
      const element = stepContainers[i];
      if($(element).hasClass("--is-active")){
        index = i
      }
    }

    //Set width of fill bar and update the position
    let fillBarElement = $("#multistepsFillBar")
    let fillBarWidth = $(".step-container").innerWidth()
    fillBarElement.width(fillBarWidth)
    fillBarElement?.animate({
      left: index * fillBarWidth
    },0)
  }



  export function dropProductImage(event:any,moduleName:string) {
    $("#draggableImg").fadeIn(0)
    
    switch (moduleName.toLocaleLowerCase()) {
      case "customizer":
        dropProductCustomizer(event)
      break;

      case "moodboard":
        dropImageMoodboard(event)
      break;
    
      default:
        break;
    }
  }


  export function updateDragImagePosition(event:any) {
    // return
    // $("#draggableImg").fadeIn(0)
    let image = $(event.target).find("img")
    $("#draggableImg").css("left",event.pageX - image.width() / 2 + 'px')
    $("#draggableImg").css("top",event.pageY - image.width() / 2 + 'px')

    $("#draggableImgLoader").css({"top":event.pageY - 25 + 'px',"left":event.pageX - 15 + 'px'})
    
    $("#draggableImg").css({"width":image.width(),"height":image.height()})
    let src = image.attr("src")
    $("#draggableImg").attr("src",src)
  }


  export async function displaySharedItemImage(shareItemId:any) {
    let product = getObjectByParameter(applicationConfig?.data?.productsList,"product_id",shareItemId)
    let image = $("#sharedItemImage");
    let text = $("#shareItemText");
    let button = $("#getStartedButton").find(".text")
    button.text("Get started")
    if(product){
      text.html(`<div class="heading2"><strong>${product.product_name}</strong> has been shared with you</div>`)
      let key = getProductImageUrl(
        applicationConfig?.BASE_URL_ASSETS,
        applicationConfig?.clientName,
        product.product_name,
        product.category_name,
        product.sub_category_name
      );
      await checkFromCache(key)
        ?.then((url: any) => {
          image?.attr("src", url);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  export function closeSummary() {
    $(".close-summary-button").click()
  }

  export function showAndHideLoader(loaderId:string,time:number) {
    showComponentLoader(loaderId)
    setTimeout(() => {
      hideComponentLoader(loaderId)
    }, time);
  }

  export function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
        return "android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "ios";
    }
    return "unknown";
}

export function addActiveClassOnMaterial(event:any,element:any=null){
  removeActiveClassFromAllTextures()
  if(!element && event){
    element = $(event?.target)
  }
  if(element){
    let checkmarkElement = $('<span></span>')
    let thumbnailURL = './assets/images/checkmark.png'
    checkmarkElement.css({ 'background-image': 'url(' + thumbnailURL + ')'});
    checkmarkElement.addClass('checkmark')
    $(element).children().eq(0).append(checkmarkElement)
    $(element).addClass("--is-active")
  }
}



function showAddToHomeScreen(deferredPrompt) {
  // Show the add to home screen prompt
  // You can customize the text and buttons of the pop-up message here
  var a2hsPrompt = document.createElement('div');
  // add your HTML code for the pop-up message here
  document.body.appendChild(a2hsPrompt);
  // Add event listener to the buttons in the pop-up message
  var a2hsBtn = document.getElementById('a2hs-btn');
  a2hsBtn.addEventListener('click', function() {
    // Hide the pop-up message
    a2hsPrompt.style.display = 'none';
    // Show the native add to home screen prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then(function(choiceResult) {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      // Reset the deferred prompt variable
      deferredPrompt = null;
    });
  });
}


export function updateScrollPosition(scrollBarId:string) {
  const scrollContainer = $("#"+scrollBarId)

  const selectedElement = $("#"+scrollBarId).find(".area.--is-active")
  const selectedElementWidth = selectedElement.width()
  const selectedElementPos = selectedElement.offset()?.left

  const nextArrowPos =  $("#"+scrollBarId).siblings(".next-arrow").offset()?.left
  const prevArrowPos =  $("#"+scrollBarId).siblings(".prev-arrow").offset()?.left
  const deltaForNext =  (selectedElementPos + selectedElementWidth) - nextArrowPos
  const deltaForPrev =  selectedElementPos - prevArrowPos

  if(deltaForNext > 0){
    var maxScrollLeft = scrollContainer[0].scrollWidth - scrollContainer.outerWidth();
    scrollContainer.animate({ scrollLeft: maxScrollLeft }, 500); 
    return
  }
  if(deltaForPrev < 0){
    var maxScrollLeft = scrollContainer[0].scrollWidth - scrollContainer.outerWidth();
    scrollContainer.animate({ scrollLeft: -maxScrollLeft }, 500); 
  }
}

export function confirmAccordionHandle(index:number) {
  $(".accordion-wrapper").find(".content").css("max-height", 0+"px")
  let container = $(`#accordion_${index}`)
  container.find(".content").css("height", 0+"px")
  container.find(".content").css("max-height", 0+"px")
  confirmProccessAccordion(index)
}


export async function confirmProccessAccordion(index:number) {
  closeProccessAccordion(index)
  await waitFor(600)
  $(`#accordion_${index}`).addClass("--is-confirmed")
  $(`#accordion_${index+1}`).addClass("--is-active")
}

export function closeProccessAccordion(index:number) {
  $(`#accordion_${index}`).removeClass("--is-active")
}


export function updateAccordionContentWrapperHeight(){
  
}