import { IonModal } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { hideComponentLoader, showComponentLoader, showGlobalToast } from "../../../../../../utils/UI_methods/global";
import { configuration } from "../../../../../../utils/customizein3d/modules/customizein3d";
import { applicationConfig, logger, openNativeShareOptions, waitFor } from "../../../../../../utils/methods";
import { getRandomNumber } from "../../../../../../utils/products/productviewer_shopify";
import { getTodayTimestampOnlyDate } from "../../../../../../utils/store/crm/calendar";
import { STORE } from "../../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
import StartCustomerSession from "../../customer/StartCustomerSession";
import Quotation from "../Quotation";
import ShareLinkAction from "./ShareLinkAction";

 
const ShareLink: React.FC<{
    cartItems:any
    Content?:any
}> = (props) => {
 

const[isOpenReview,setisOpenReview]:any =  useState(false)
const[isOpenShareLink,setisOpenShareLink]:any =  useState(false)
const[QuotationNumber] = useState("Quo"+getRandomNumber(1,1000))
const[link,setLink] = useState(null)
const[isOpenStartSession,setIsOpenStartSession] = useState(false)


async function createQuotationHandle() {
    showComponentLoader("mainLoaderSpinner")
    await waitFor(500)
    let customerId = -1 // ""Default Configuration
    if(STORE.activeCustomer){
        customerId = STORE.activeCustomer.id
    }
    // if(STORE.activeCustomer && true){
        let quoteDetails = {
            quoteNo:QuotationNumber,
            quotationDate:getTodayTimestampOnlyDate(),
            cartItems:props.cartItems || getCurrItem()
        }
        STORE.storeApi.quotation.add(customerId,quoteDetails).then((data:any)=>{
            if(!data.data.error){
                setLink(data.data.data.token)
                setisOpenShareLink(true)
                setisOpenReview(false)
                let shareItemName = STORE.currProduct?.product_name || ""
                openNativeShareOptions(`${window.location.origin}/view/${data.data.data.token}`,shareItemName,"mainSpinnerLoader")
                // props.confirmHandle()
                showGlobalToast("Link Generated Successfully")
                logger?.info("customizein3d","S-Quo")
            }else{
                showGlobalToast(data.data.message,2000,"error")
            }
            hideComponentLoader("mainLoaderSpinner")
        })
    // }else{
    //     hideComponentLoader("mainLoaderSpinner")
    //     setIsOpenStartSession(true)
    //     showGlobalToast("Please start customer session",2000,"error")
    // }
    
}

function getCurrItem() {
    if(STORE.productConfiguration){
      return [{
        product_id:STORE.currProduct.product_id,
        configuration:JSON.stringify(STORE.productConfiguration),
        configuration3D:JSON.stringify(configuration),
        price:STORE.productConfiguration.price,
        quantity:1
      }]
    }
  }


function ShareDetails() {
    return(
        <div className="full-height-width">
            <div className="header-content position-relative center-aligned-column heading1 store-color-primary uppercase">
                Share Details
                <span className="right-middle padding10" onClick={()=>{setisOpenReview(false)}}>
                    <i className="fas fa-times color-dark font-medium"></i>
                </span>
            </div>
            <div className="body-content">
                {isOpenReview && (props.cartItems || getCurrItem())? 
                    <Quotation isShareMode={true} cartItems={props.cartItems || getCurrItem()} confirmHandle={null} />
                :null}
            </div>
            <div className="footer-content center-aligned-column">
                <Button2
                    configObj={{id:""}}
                    action={()=>{createQuotationHandle()}}
                    label={"Generate Sharable Link"}
                    fontAwesomeIcon={"fas fa-share color-white"}
                    iconPos=""
                    classNames={"margin0 button-medium store-bg-color-primary"}
                />
            </div>
        </div>
    )
}

function shareOptionClickHandle() {
    let shareItemName = STORE.currProduct?.product_name || ""
    openNativeShareOptions(`${window.location.origin}/view/${link}`,shareItemName,"mainSpinnerLoader")
    logger?.info("customizein3d","S-Link")

}
 
 
return (
    <div className="" >

        <StartCustomerSession isOpen={isOpenStartSession} setIsOpen={setIsOpenStartSession} createCustomerCallback={()=>{}}/>


        <IonModal
            isOpen={isOpenReview}
            onDidDismiss={()=>setisOpenReview(false)}
            cssClass={"medium-y-modal"}
        >
            <ShareDetails/>
        </IonModal>

        <IonModal
            isOpen={isOpenShareLink}
            onDidDismiss={()=>setisOpenShareLink(false)}
            cssClass={"copy-link-modal"}
        >
            <ShareLinkAction closeHandle={()=>setisOpenShareLink(false)} link={link} shareOptionClickHandle={shareOptionClickHandle}/>
        </IonModal>

        <div className="" onClick={()=>createQuotationHandle()}>
            {props.Content?
            <props.Content/>
            :
            <Button2
                configObj={{id:""}}
                action={()=>{createQuotationHandle()}}
                label={"Share"}
                fontAwesomeIcon={"fas fa-share color-white"}
                iconPos=""
                classNames={"no-shadow margin0 bg-color-warning"}
            />
            }
        </div>

        

    </div>   
);
};

export default ShareLink;
