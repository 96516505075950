import React, { useState } from "react";
import { useHistory } from "react-router";
import { getRandomNumber } from "../../../../utils/products/productviewer_shopify";
 

const TopCategories: React.FC<{
}> = (props) => {

 

  let history = useHistory()

  const[categories] = useState([
    {
      name:"Sofa",
      displayName:"Sofas"
    },
    {
      name:"Cot",
      displayName:"Bed"
    },
    {
      name:"Chairs",
      displayName:"Chairs"
    },
    {
      name:"Wardrobe",
      displayName:"Wardrobes"
    },
    {
      name:"Mattress",
      displayName:"Mattress"
    },
  ])


  function clickHandle(product:any) {
    history.push(`/furnitures/Sofa`)
  }

 
  return (
    <div className="display-flex-row product-images-container marginTop10 full-height" >
       

      {categories?.map((category:any,index:number)=>{
        return(
          <div onClick={()=>clickHandle(category)} className={`category-details-wrapper padding5 image-small`} key={index}>
            <div className="">
              <div className="image product-image-container position-relative">
                <img src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/elevate/images/category/${category.name}.jpg`} alt="" />
              </div>
                <div className="padding10 center-aligned-column">
                  <div className="heading2 text-center color-black font-medium">{category.displayName}</div>
                  <div className="heading4 text-center rupee-sign marginTop5 font-normal color-black">From ₹ {getRandomNumber(10000,25487)}.00</div>
                </div>
              <div className="no-shadow marginleft5  display-flex-row no-wrap">
              </div>
            </div>
          </div>
        )
      })}
    </div> 
  );
};

export default TopCategories;
