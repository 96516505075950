import React from "react";
import { getFormattedPrice } from "../../../../../utils/products/productviewer_shopify";
import { getFormatedDate } from "../../../../../utils/store/crm/crm";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import { showGlobalToast } from "../../../../../utils/UI_methods/global";
 

 
const QuotationsListContainer: React.FC<{
  quotationsList:any
  setCartItems:(id:string)=>void
  placeOrderHandle:(id:string)=>void
}> = (props) => {

  async function copyToClipboard(link:string) {
    try {
      await navigator.clipboard.writeText(`${window.location.origin}/view/${link}`);
        showGlobalToast('Coppied!!');
    } catch (err) {
        showGlobalToast('Unable to copy text to clipboard',2000,"error");
    }
  }

  return (
    <div className="full-height-width display-flex-column no-wrap">

        
      
        <div className="display-flex-column no-wrap full-height-width position-relative">
          <div className="container overflow-y-scroll">
            {props.quotationsList?.map((quotation: any, index: number) => {
              return (
                <div key={index} className="full-width marginTop5 lead-card-container">
                  <div className={`white-card pointer position-relative no-shadow border`}>
                  <div className="display-flex-row">
                    <div className="padding5 name-badge-container" >
                      <div className="capitalize name-badge center-aligned-column white-card no-shadow">
                        {quotation.quote_no.substring(0,2)}
                      </div>
                    </div>
                    <div  className="customer-details center-aligned-column padding5">
                      <div className="contact-details ">
                        <div className="display-flex-row item" >
                          <span className="hide-in-desktop"><i className="far fa-id-card icon color-dark"></i></span>
                          <span className="center-aligned-column heading2 capitalize name color-black text  marginleft5">{quotation.quote_no}</span>
                        </div>

                        <div className="display-flex-row item ion-margin-start">
                          <span className="center-aligned-column"><i className="far fa-calendar-alt icon color-warning"></i></span>
                          <span className="text marginleft5 color-black">{getFormatedDate(quotation.quotation_date)} </span>
                        </div>

                        <div className="display-flex-row item ion-margin-start">
                          <span className="center-aligned-column"><i className="fas fa-tag icon color-warning"></i></span>
                          <span className="text marginleft5 color-black heading2">{getFormattedPrice(quotation.price)}</span>
                        </div>

                        {quotation.is_approved?
                          <div className="display-flex-row item ion-margin-start">
                            <span className="center-aligned-column"><i className="fas fa-check-circle icon color-success"></i></span>
                            <span className="text marginleft5 color-black heading2">Approved</span>
                          </div>
                        :
                          <div className="display-flex-row item ion-margin-start">
                            <span className="center-aligned-column"><i className="fas fa-exclamation-triangle icon" style={{color:"#f0ad4e"}}></i></span>
                            <span className="text marginleft5 color-black heading2">Waiting for confirmation</span>
                          </div>
                        }

                        
          
                      </div>
                    </div>
                  </div>
                  <div className={`top-right options-container center-aligned-column full-height`}>
                    <div className="display-flex-row no-wrap">
                      
                      {quotation.is_order_placed?
                        <Button2
                          configObj={{id:""}}
                          action={()=>{}}
                          label={"Order Placed"}
                          fontAwesomeIcon={"fas fa-check-circle color-success"}
                          iconPos="left"
                          classNames={"no-shadow button-fixed-width center-aligned-row button-small color-white bg-color-light"}
                        />
                      :
                      !quotation.is_approved?
                        null
                      :
                        <Button2
                          configObj={{id:""}}
                          action={()=>{props.placeOrderHandle(quotation.quote_no)}}
                          label={"Checkout"}
                          fontAwesomeIcon={"fas fa-shopping-cart color-warning"}
                          iconPos="left"
                          classNames={"no-shadow center-aligned-row button-small color-white bg-color-light"}
                        />
                      }

                      <Button2
                        configObj={{id:""}}
                        action={()=>{props.setCartItems(quotation.quote_no)}}
                        label={""}
                        fontAwesomeIcon={"fas fa-eye color-warning"}
                        iconPos="left"
                        classNames={"no-shadow button-small color-white bg-color-light"}
                      />

                      <Button2
                        configObj={{id:""}}
                        action={()=>{copyToClipboard(quotation.token)}}
                        label={"Copy Link"}
                        fontAwesomeIcon={"fas fa-eye color-warning"}
                        iconPos=""
                        classNames={"no-shadow button-small color-white bg-color-light"}
                      />
                      
                    </div>
                  </div>
                </div>
              </div>
              );
            })}
          </div>
        </div>
      
    </div>
  );
};

export default QuotationsListContainer;
