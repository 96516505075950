import $ from "jquery"
import { MathUtils } from "three"
import { getWindowHeight, getWindowWidth } from "../../UI_methods/global"
import { applicationConfig, waitFor } from "../../methods"
import { expandControlsPanel, hideControlsPanel, isPotraitMode, updateFraming } from "../common"
import { cancelFloorDimensionsAnimation, cancelmoduleAnnotationAnimation } from "./annotations"
import { IS_SPACE_PLANNER_MODE, camera, composer, configObj, configuration, controls, dimensions, group, groupBoundingBox, labelRenderer, render, renderer } from "./customizein3d"
import { CURR_SELECTED_MODULE } from "./raycasting"
import { STORE } from "../../store/storeConfiguration"

export async function showFloorDimensions() {
    $(".floor-dimensions").addClass("--is-active")
    // await waitFor(300)
    // updateFloorDimensionsPosition()
    // $("#floorTopDim").text($(".room-breadth-feet").find(".select-value").text() + " ' " + $(".room-breadth-inch").find(".select-value").text() + " '' ")
    // $("#floorBottomDim").text($(".room-breadth-feet").find(".select-value").text() + " ' " + $(".room-breadth-inch").find(".select-value").text() + " '' ")
    // $("#floorLeftDim").text($(".room-length-feet").find(".select-value").text() + " ' " + $(".room-length-inch").find(".select-value").text() + " '' ")
    // $("#floorRightDim").text($(".room-length-feet").find(".select-value").text() + " ' " + $(".room-length-inch").find(".select-value").text() + " '' ")
    // $("").text( $(".room-breadth-feet").find(".select-value").text)
    // $("").text( $(".room-breadth-inch").find(".select-value").text)
}


export function updateRoomLayoutValues() {
    $("#summaryRoomBreadth").text($(".room-breadth-feet").find(".select-value").text() + " ' " + $(".room-breadth-inch").find(".select-value").text() + " '' ")
    $("#summaryRoomLength").text($(".room-length-feet").find(".select-value").text() + " ' " + $(".room-length-inch").find(".select-value").text() + " '' ")

}

export function hideFloorDimensions() {
    $(".floor-dimensions").removeClass("--is-active")
    cancelFloorDimensionsAnimation()
}

export async function showModuleAnnotation(){
    $(".icon-container").removeClass("--is-active")
    if(!$("#productAnnotationWrapper").hasClass("--is-hide")){
        $("#productAnnotationWrapper").addClass("--is-hide")
        cancelmoduleAnnotationAnimation()
        await waitFor(200)
    }
    $("#productAnnotationWrapper").removeClass("--is-hide")
    if(CURR_SELECTED_MODULE){
      let module = CURR_SELECTED_MODULE.userData.configuration
      let mainCategory = STORE.getCurrCategory()
      let selectedProductCategory = module?.categoryName || module?.category_name

      let isAddedAsAddon = CURR_SELECTED_MODULE.userData.configuration?.isAddedAsAddon 

      // if(CURR_SELECTED_MODULE.userData.configuration?.productName && configuration.isViewProductMode){
      //   $("#productDuplicateAnnotation").addClass("display-none")
      //   $("#productDeleteAnnotation").addClass("display-none")
      //   $("#productRotateAnnotation").addClass("display-none")
      //   return
      // }

      if(mainCategory === "Wardrobe" && selectedProductCategory === "Dresser"){
        $("#moduleAttachAnnotation").removeClass("display-none")
      }else{
        $("#moduleAttachAnnotation").addClass("display-none")
      }


      if(!module){
        $("#productDuplicateAnnotation").addClass("display-none")
        $("#productRotateAnnotation").removeClass("display-none")
        $("#productDeleteAnnotation").removeClass("display-none")
        return
      }
      $("#productResizeAnnotation").removeClass("display-none")
      if(module?.allowDuplication){
        $("#productDuplicateAnnotation").removeClass("display-none")
      }else{
        $("#productDuplicateAnnotation").addClass("display-none")
      }
      if(module?.allowDelete || CURR_SELECTED_MODULE.isSprite || configuration.isLshapeWithSingleSeater){
        $("#productDeleteAnnotation").removeClass("display-none")
      }else{
        $("#productDeleteAnnotation").addClass("display-none")
      }
      if((module?.allowRotation && IS_SPACE_PLANNER_MODE) || isAddedAsAddon){
        $("#productRotationRangeSlider").addClass("--is-active")
        let rotationInDeg = Math.round(MathUtils.radToDeg(CURR_SELECTED_MODULE.rotation.y))
        if(CURR_SELECTED_MODULE.rotation.y === 1.2246467991473532e-16){
          rotationInDeg = 180
        }
        if(CURR_SELECTED_MODULE.rotation.y === -1.2246467991473532e-16){
          rotationInDeg = -180
        }
        $("#rangeSlider").val(rotationInDeg)
        $("#rangeSliderValue").text(String(rotationInDeg))
      }else{
        $("#productRotationRangeSlider").removeClass("--is-active")
      }
      let categoryName = module?.category_name || module?.categoryName
      if(categoryName !== "Sofa"){
        $("#wardrobeModuleAnnotation").removeClass("display-none")
      }else{
        $("#wardrobeModuleAnnotation").addClass("display-none")
      }
    }
}


export function hideModuleAnnotation(){
    $("#productAnnotationWrapper").addClass("--is-hide")

}


export function enableConfigureMode(isUpdateFraming:boolean = true) {
    expandControlsPanel(function() {
        composer?.onWindowResize(renderer,camera,labelRenderer,IS_SPACE_PLANNER_MODE)
        if(isUpdateFraming){
            updateFraming(group,groupBoundingBox,camera,controls,false)
        }
    })
}

export function openGallery() {
  $("#rendersGalleryContainer").removeClass("display-none")
  return 
  closeMenuHandleLandscape()
  expandControlsPanel(function() {
      $(".tab-content").removeClass("--is-active");
      $("#rendersGalleryContainer").addClass("--is-active");
      composer?.onWindowResize(renderer,camera,labelRenderer,IS_SPACE_PLANNER_MODE)
      updateFraming(group,groupBoundingBox,camera,controls,false)
  })
}

export function closeGallery() {
  $("#rendersGalleryContainer").removeClass("--is-active");
}

export function resetControlTabs() {
  try {
    setTimeout(() => {
      // $(".main-tab-container").find(".controls-tab").removeClass("--is-active")
      // $(".tab-content").removeClass("--is-active")
      // $($(".main-tab-container").find(".controls-tab")[0]).addClass("--is-active")
      // $($(".tab-content")[0]).addClass("--is-active")
      // $("#addModule").click()
    }, 500);
   
  } catch (error) {
    
  }

}

export function disableConfigureMode(isUpdateFraming:boolean = true) {
    $(".summary-link-container").fadeOut(0)
    closeGallery()
    hideControlsPanel(function() {
        composer?.onWindowResize(renderer,camera,labelRenderer,IS_SPACE_PLANNER_MODE)
        if(isUpdateFraming){
            updateFraming(group,groupBoundingBox,camera,controls,false)
        }
        if(getWindowWidth() < 480 || isPotraitMode()){
            showCustomizeButton()
            $(".summary-link-container").fadeIn(0)
        }else{
            // showActionTabs()
        }
    })
}

export function fullScreenToggleConfigurator() {
  if($(".customize-controls-wrapper").hasClass("--is-active")){
    disableConfigureMode()
  }else{
    enableConfigureMode()
  }
}

export function isPortraitMode() {
  let width = getWindowWidth()
  let height = getWindowHeight()

  if(width < height){
    return true
  }
  return false
}

export function isDeviceIpadPotrait() {
    let width = getWindowWidth()
    let height = getWindowHeight()

    if((width === 768 && height === 1024) || (width === 1024 && height === 1366)
    || (width === 820 && height === 1180 || width < height)
    ){
      return true
    }
    return false
}

export async function showActionPanel(event:any=null) {
    hideActionBar(function() {
        expandControlsPanel(function() {
            composer?.onWindowResize(renderer,camera,labelRenderer,IS_SPACE_PLANNER_MODE)
            
        })
    })

    $("#objectActionsContainer").addClass("--is-active")
    $("#finishContainer").removeClass("--is-active")
    $("#addModulesContainer").addClass("--is-active")
    applicationConfig.functions.customizein3d.updateTabValueMobile("addModulesContainer","addModule")

    $(".icon-container").removeClass("--is-active")
    $(event?.target).addClass("--is-active")
    $("#productInfoAnnotation").addClass("--is-active")
  }

  export async function showFinishPanel(event:any=null) {
    hideActionPanel()
      if(!isPotraitMode()){
        configObj.functions.customizein3d?.updateTabValueDesktop("finishContainer","finish")
        hideActionBar(function() {
            expandControlsPanel(function() {
                composer?.onWindowResize(renderer,camera,labelRenderer,IS_SPACE_PLANNER_MODE)
                
            })
        })
      }else{
        configObj.functions.customizein3d?.updateTabValueMobile("finishContainer","finish")
        enableConfigureMode()
      }

 
    $(".icon-container").removeClass("--is-active")
    $(event?.target).addClass("--is-active")
  }
  
  export function hideActionPanel() {
    $("#objectActionsContainer").removeClass("--is-active")
  }

  
export async function showActionTabs() {
    expandActionBar(function() {
      composer?.onWindowResize(renderer,camera,labelRenderer,IS_SPACE_PLANNER_MODE)
    })
 
  }
  
  export function hideActionTabs() {
    hideActionBar(function() {
      composer?.onWindowResize(renderer,camera,labelRenderer,IS_SPACE_PLANNER_MODE)
    })
  }


  export async function showCustomizeButton() {
    $(".customization-customize-button").addClass("--is-active")
  }
  
  export function hideCustomizeButton() {
    $(".customization-customize-button").removeClass("--is-active")
  }



  let controlsPanelWidth = getWindowWidth() >= 1920 ? 150 : 120
  let animationTime = 100
  
export function expandActionBar(callback:()=>void) {
    if(!$("#customizein3dActionTabs").hasClass("--is-active")){
        let dimensionName = "width"
       
        $(".customize-canvas-wrapper").animate({[dimensionName]:"-="+ controlsPanelWidth +"px"},{
            duration:animationTime, 
            easing:"linear",
            complete :function(){
                $(".customizein3d-tabs-wrapper").addClass("--is-active")
                $(".summary-link-container").fadeIn(0)
            },
            step:function() {
              callback()
            }
        })
        $("#customizein3dActionTabs").animate({[dimensionName]:"+="+ controlsPanelWidth +"px"},{
            duration:0,
            complete:function(){
                callback()
            }
        })
        $("#customizein3dActionTabs").addClass("--is-active")
        $(".customization-customize-button").removeClass("--is-active")
    }  
  }
  
  export function hideActionBar(callback:()=>void) {
      if(getWindowWidth() <= 480){
          callback()
          return
      }
    // $(".customization-cancel-button").removeClass("--is-active")
    $(".customizein3d-tabs-wrapper").removeClass("--is-active")
  
    let dimensionName = "width"
   
    $(".customize-canvas-wrapper").animate({[dimensionName]:"+="+ controlsPanelWidth +"px"},{
        easing:"linear",
        duration:0,
       
    })
    $("#customizein3dActionTabs").animate({[dimensionName]:"-="+ controlsPanelWidth +"px"},{
        duration:animationTime,
        complete :function(){
            callback()
            //For modules configuration
            // $(".customization-customize-button").addClass("--is-active")
        }
    })
    $("#customizein3dActionTabs").removeClass("--is-active")
  }
    


  export function hideMenusDoubleClick(event:any) {
    if($(event.target).hasClass("stop-propagation")){
        return
    }
    hideAllMenues()
  }

  export function hideAllMenues() {
    hideControlsPanel(function() {
      composer?.onWindowResize(renderer,camera,labelRenderer,IS_SPACE_PLANNER_MODE)

        
        if(getWindowWidth() > 480){
            hideActionBar(function() {
              composer?.onWindowResize(renderer,camera,labelRenderer,IS_SPACE_PLANNER_MODE)

                
                showCustomizeButton()
            })
        }
    })
  }

  
  export function openSummary() {
    document.getElementById("summaryButton").click()
  }

  export function clickArButton() {
    setTimeout(() => {
        document.getElementById("invokeArButton").click()
    }, 500);
  }


  export function showConfigOptionsWindow() {
      $("#configOptionsWindow").addClass("--is-active")
  }

  export function hideConfigOptionsWindow() {
    $("#configOptionsWindow").removeClass("--is-active")
}

export function closeMenuHandleLandscape() {
  hideActionTabs();
  showCustomizeButton();
}

export function updateActiveClassCotSelection() {
  if(configuration.modules?.length){
    let module = configuration?.modules[0]
    $(".module-container").removeClass("--is-active")
    $(`.module-container[data-product-name="${module?.moduleName}"]`).addClass("--is-active")
  }
}

export function showHeightWindow() {
  $("#productHeightAnnotation").fadeIn()
}

export function hideHeightWindow() {
  $("#productHeightAnnotation").fadeOut()
}
export function showResizerWindow() {
  dimensions?.hideDimensions()
  $(".object-resizer-container").fadeIn()
}

export function hideResizerWindow() {
  $(".object-resizer-container").fadeOut()
}


export function updateCurrSelectedMaterialValue(val:string){
  $(".curr-selected-material").text(val)
}
