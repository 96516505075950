export const kanvasScenes = [
    {
        scene_name:"scene1",
        room_name:"Room2_View1",
        productsList: [
            {
                productName:"8159_SingleSeater",
                categoryName:"Sofa",
                parts:["Base","Wood"],
                collections:[{
                    companyName:"OVL",
                    collectionName:"Fabiano"
                }]
            },
            {
                productName:"Floor_Room2",
                categoryName:"Floor",
                parts:["Floor"],
                collections:[{
                    companyName:"Demo",
                    collectionName:"FloorOptions"
                }]
            },
            {
                productName:"Carpet_Room2",
                categoryName:"Carpet",
                parts:["Carpet"],
                collections:[{
                    companyName:"Demo",
                    collectionName:"CarpetOptions"
                }]
            },
            {
                productName:"Wallpaper_Room2",
                categoryName:"Wallpaper",
                parts:["Wallpaper"],
                collections:[{
                    companyName:"Demo",
                    collectionName:"Geometric"
                },
                {
                    companyName:"Demo",
                    collectionName:"Scenery"
                }
            ]
            },
            {
                productName:"Curtain_Room2",
                categoryName:"Curtain",
                parts:["CurtainBase"],
                collections:[{
                    companyName:"DDecor",
                    collectionName:"Arlington"
                },
                {
                    companyName:"DDecor",
                    collectionName:"Atelier"
                },
                {
                    companyName:"DDecor",
                    collectionName:"Malabar"
                }
                ]
            }
            
        ]
    },

    {
        scene_name:"scene2",
        room_name:"Room3_View1",
        productsList: [
            {
                productName:"Italia",
                categoryName:"Sofa",
                parts:["Base","Wood","BackCushion","SeatCushion","Pillow"],
                collections:[{
                    companyName:"OVL",
                    collectionName:"Fabiano"
                }]
            },
            {
                productName:"Floor3",
                categoryName:"Floor",
                parts:["Floor"],
                collections:[{
                    companyName:"Demo",
                    collectionName:"FloorOptions"
                }]
            },
            {
                productName:"Carpet3",
                categoryName:"Carpet",
                parts:["Carpet"],
                collections:[{
                    companyName:"Demo",
                    collectionName:"CarpetOptions"
                }]
            },
            {
                productName:"Wallpaper3",
                categoryName:"Wallpaper",
                parts:["Wallpaper","Skirting"],
                collections:[{
                    companyName:"Demo",
                    collectionName:"Geometric"
                },
                {
                    companyName:"Demo",
                    collectionName:"Scenery"
                }
            ]
            },
            {
                productName:"Curtain3",
                categoryName:"Curtain",
                parts:["CurtainBase"],
                collections:[{
                    companyName:"DDecor",
                    collectionName:"Arlington"
                },
                {
                    companyName:"DDecor",
                    collectionName:"Atelier"
                },
                {
                    companyName:"DDecor",
                    collectionName:"Malabar"
                }
                ]
            }
            
        ]
    }

]


export const SpritesScenes = [
    {
        scene_name:"scene1",
        room_name:"Room3_View1",
        productsList: [
            {
                productName:"8159_SingleSeater",
                categoryName:"Sofa",
                parts:["Base","Wood"],
                collections:[{
                    companyName:"OVL",
                    collectionName:"Fabiano"
                }]
            },
            {
                productName:"Floor_Room2",
                categoryName:"Floor",
                parts:["Floor"],
                collections:[{
                    companyName:"Demo",
                    collectionName:"FloorOptions"
                }]
            },
            {
                productName:"Carpet_Room2",
                categoryName:"Carpet",
                parts:["Carpet"],
                collections:[{
                    companyName:"Demo",
                    collectionName:"CarpetOptions"
                }]
            },
            {
                productName:"Wallpaper_Room2",
                categoryName:"Wallpaper",
                parts:["Wallpaper","Other"],
                collections:[{
                    companyName:"Demo",
                    collectionName:"Geometric"
                },
                {
                    companyName:"Demo",
                    collectionName:"Scenery"
                }
            ]
            },
            {
                productName:"Curtain_Room2",
                categoryName:"Curtain",
                parts:["CurtainBase"],
                collections:[{
                    companyName:"DDecor",
                    collectionName:"Arlington"
                },
                {
                    companyName:"DDecor",
                    collectionName:"Atelier"
                },
                {
                    companyName:"DDecor",
                    collectionName:"Malabar"
                }
                ]
            }
            
        ]
    }

]