import React, { useEffect } from "react";
import { FabricateConfig } from "../../../../../utils/fabricate/fabricate";
import Controls from "./Controls";
import TopRightActions from "./TopRightActions";
 

const CustomizeIn3D: React.FC<{
  fabricateConfig:FabricateConfig
}> = (props) => {

  useEffect(()=>{
    if(props.fabricateConfig){
      props.fabricateConfig.init("fabricateContainer")
    }
  },[props.fabricateConfig])
 
 
    return (
        <div className="full-height-width customize-canvas-controls-wrapper" id="mainCustomizein3DWrapper" style={{flexDirection:"row"}}>
          <div id="mainCustomizein3DWrapper"  className="customize-canvas-wrapper position-relative center-aligned-row white-card" style={{zIndex:1,background:"rgba(0,0,0,0.2)"}}>
          <TopRightActions
              fabricateConfig={props.fabricateConfig}
          />
          <div id="fabricateContainer"  className="full-height-width pointer center-aligned-column" style={{zIndex:1}}>
          
          </div>
          </div>
          <div className="customize-controls-wrapper store-controls-wrapper  elevate-main-wrapper display-none full-height position-relative white-card" id="controlsWrapper" style={{zIndex:0}}>
            <Controls
              fabricateConfig={props.fabricateConfig}
            />
          </div>
        </div>
    );
};

export default CustomizeIn3D;
