import {
  IonCheckbox,
  IonPage
} from "@ionic/react";
import axios from "axios";
import { DeviceUUID } from "device-uuid";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../theme/variables.css";
import { hideComponentLoader, updateLoaderProgress } from "../utils/UI_methods/global";
import { deleteCookie, getCookie, setCookie } from "../utils/cache/cookies";
import { applicationConfig, getRouteFromSubDomain, getSubDomainName, setUserSession } from '../utils/methods';
import Loader from "./common/shared-components/main-ui/Loader";
import Button2 from "./common/ui-components/buttons/Button2";
import "./style/login.css";
import Modal from "./common/shared-components/Modals/Modal";
import PasswordResetForm from "./PasswordResetForm";
import { BASE_PATH } from "../services/api";
import { addItemToLocalStorage } from "../utils/cache/localstorage";

const Login: React.FC<{
  setLoginTrue:()=>void;
}> = (props) => {

  const history = useHistory();
  const [username, setUsername] = useState(getCookie("username"));
  const [password, setPassword] = useState("");
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSetCookie, setIsSetCookie] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const[instaLink,setInstaLink] = useState("https://www.instagram.com/omniview_labs/")
  const[fbLink,setfbLink] = useState("https://www.facebook.com/omniview.labs/")

  const[logoSrc,setLogoSrc] = useState("assets/images/opus.png")
  const[subDomain] = useState(window.location.hostname.split('.')[0])
  const[isWhiteLabeledClient,setIsWhiteLabeledClient] = useState(false)

  useEffect(()=>{
    if(subDomain === "ssh"){
      setLogoSrc(`https://opusassets.s3.ap-south-1.amazonaws.com/public/assets/clientLogos/SiddhiSofas.png`)
      setIsWhiteLabeledClient(true)
      setInstaLink("")
      setfbLink("")
    }
    if(subDomain === "thelevelfurniture"){
      setLogoSrc(`https://opusassets.s3.ap-south-1.amazonaws.com/public/assets/clientLogos/TheLevelFurniture.png`)
      setIsWhiteLabeledClient(true)
      setInstaLink("")
      setfbLink("")
    }
  },[subDomain])

  function enableLoading() {
    setLoading(true);
    updateLoaderProgress("loginLoader",0,0,"Authenticating...")
    $("#username").addClass("disable")
    $("#password").addClass("disable")
    // showComponentLoader("loginLoader")
    // setElementInnerContent("Please Wait...","loginButton")
  }

  function disableLoading() {
    hideComponentLoader("loginLoader")
    setLoading(false);
  }

  function getClientNameFromSubdomain(){
    if(subDomain === "ssh"){
      return "SiddhiSofas"
    }
    if(subDomain === "thelevelfurniture"){
      return "The Level Furniture"
    }
    else{
      return "OmniviewLabs"
    }
  }

  function initialState() {
    $("#username").removeClass("disable")
    $("#password").removeClass("disable")
    // setElementInnerContent("Login","loginButton")
  }
  
  const handleLogin = () => {
    enableLoading()
    setError('');
    axios
    .post(
      `${BASE_PATH}api/signin`,
      {
        username: username,
        password: password,
        deviceId:new DeviceUUID().get()
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      console.log(response)
      if(response.data.error){
        disableLoading()
        setError(response.data.message)
        initialState()
        return
      }
      addItemToLocalStorage("isLogin",true)
      addItemToLocalStorage("recentActivities",null)
      
      const user = response.data.user 
      // disableLoading()
      user.isShareMode = false
      setUserSession(response.data.token,user);
      props.setLoginTrue()
      handleCookie()
      
      // props.setToken(response.data?.token)
      if(user.userType.toLowerCase() === "insidesales" || user.userType.toLowerCase() === "fieldsales" || user.userType.toLowerCase() === "manager"){
        window.location.href = "/leads"
      }else if(user.userType.toLowerCase() === "security"){
        window.location.href = "/visitors"
      }
      else{
        window.location.href = getRouteFromSubDomain()
      }
      // history.push('/collections');
    })
    .catch((error) => {
        disableLoading()
        initialState()
        setError(error?.response?.data?.message)
    });
  };

  function handleCookie(){
    if(isSetCookie){
      setCookie("username",username,30)
    }else{
      deleteCookie("username")
    }
  }


  function submitHandle(event:any) {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleLogin()
    } 
  }

  return (
    <IonPage>
      {/* <Header
            configObj={{}}
            componentMiddle={()=>(<div>Opus Login</div>)}
            componentLeft={null}
        /> */}
      <div className="ion-padding background-white ion-text-center full-height-width center-aligned-column">
          <Loader
            id="loginLoader"
            data={{message:"Authenticating"}}
            loaderType="dotsText"
            className="fade-background-loader"
          />

        <Modal
          id=""
          classNames="small-y-modal"
          onDismiss={()=>setIsOpen(false)}
          isOpen={isOpen}
          Content={()=>(
            <PasswordResetForm onSuccess={()=>setIsOpen(false)}/>
          )}
          heading="Reset password"
          FooterContent={()=>(<div></div>)}
          >
        </Modal>

        <div className="white-card login-container  position-relative hide-scrollbar  overflow-y-scroll">

        {!getClientNameFromSubdomain().toLowerCase().includes("omniview")?
        <div className="heading1 text-center ion-margin-bottom  ion-margin-top font-medium">Welcome to <br/> {getClientNameFromSubdomain()}</div>
        :null}



          <div className="login-page-logo marginTop10 full-width center-aligned-row">
            <img src={logoSrc} style={{width:"15rem"}} alt="" />
          </div>

        {error?<p className="error heading3 font-md-small padding10">{error}</p>:null}

        <div className="heading3 text-center marginBottom5  ion-margin-top font-normal color-primary">Sign in to your account</div>

          
        <div className="form-label-input-container">
            <div className="label-container">
                <div className="label">
                  Username  
                </div>
            </div>
            <div className="input-container">
              <input
                className="input"
                type="text"
                name=""
                id="username"
                onChange={(e) => setUsername(e.target.value!)}
                required
                value={username}
              />
            </div>
          </div>

          <div className="form-label-input-container">
            <div className="label-container">
                <div className="label">
                  Password  
                </div>
            </div>
            <div className="input-container">
              <input
                className="input"
                type="password"
                name=""
                id="password"
                required
                value={password}
                onKeyPress={submitHandle}
                onChange={(e) => setPassword(e.target.value!)}
              />
            </div>
          </div>

          <div className="display-flex-row ion-margin-top space-between">
            <div className="display-flex-row no-wrap">
              <div className="check">
                <IonCheckbox onIonChange={(e:any)=>setIsSetCookie(!isSetCookie)} slot="end" checked={isSetCookie} color="primary" mode='md'/>
              </div>
              <div className="heading3 font-md-small marginleft10">Keep me signed in</div>
            </div>
            <div className="heading3 color-primary pointer font-small center-aligned-column" onClick={()=>setIsOpen(true)}>Reset password</div>
          </div>


          

          <div className="full-width ion-margin-top">
            <Button2
              configObj={{id:""}}
              action={handleLogin}
              label={loading?"Authenticating...":"Sign In"}
              fontAwesomeIcon={"color-danger"}
              iconPos="left"
              classNames={"shadow-light text-center margin0 full-width  button-medium bg-color-primary"}
            />
          </div>

          <div className="social-media-icons full-width center-aligned-row padding10">
            {instaLink?
              <div className="display-flex-row marginTop10">
                <a href={instaLink}>
                  <img src="https://opusassets.s3.ap-south-1.amazonaws.com/public/assets/icon/svg/socialMedia/instagram.svg" width={25} height={25}/>
                </a>
                <a href={fbLink}>
                <img className="ion-margin-start" src="https://opusassets.s3.ap-south-1.amazonaws.com/public/assets/icon/svg/socialMedia/facebook.svg" width={25} height={25}/>
                </a>
              </div>
            :null}
          </div>

          {!isWhiteLabeledClient?
          <div className="bottom-middle padding5">
          <a href="https://omniviewlabs.com/" className="font-md-small" style={{textDecoration:"none"}}>www.omniviewlabs.com</a>
          </div>
          :
          null
          // <div className="bottom-right full-width center-aligned-row no-wrap padding5 no-wrap">
          //   <span className="heading4 center-aligned-column ">Powered by </span>
          //   <a className="font-md-small center-aligned-column color-secondary" style={{textDecoration:"none",marginLeft:"2px"}}> Omniview Labs</a>
          // </div>
          }

          {/* <div className="bottom-right" style={{bottom:"-10px"}}>
            <a href="https://omniviewlabs.com/">
            <img src="https://omniviewlabs.com/wp-content/uploads/2020/07/NewLogo_TopAligned-Custom-1.png" width={50} alt="" />
            </a>
          </div> */}

         


          {/* <button className="op-btn op-btn-primary login-button"
           disabled={loading}
           style={{width:"100%"}}
           id="loginButton"
           onClick={handleLogin}>
          Login</button> */}
          {/* <p className="ion-padding heading4">
                Forgot your password?
          </p> */}
        
        </div>
      </div>
    </IonPage>
  );
};

export default Login;
