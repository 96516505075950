import $ from "jquery"
import { Box3, Group, Object3D, Quaternion, Vector3 } from "three"
import { hideComponentLoader, showComponentLoader, showGlobalToast, showToast } from "../../UI_methods/global"
import { checkFromCache, untarAndSaveToCache } from "../../cache/cache"
import { applicationConfig, getFilteredArray, getObjectByParameter, waitFor } from "../../methods"
import { Door, Frame, Handle, Shelf, STORE } from "../../store/storeConfiguration"
import { updateActionPanelInformation } from "../UImethods"
import { addModuleToConfiguration, attachModules, detachModule, detachModules, loadAndApplyTextureToMesh, updateGroupCenter } from "../common"
import { GRID, assignTextureMap } from "../enviornment"
import { ModuleClass } from "./ModuleClass"
import { MODULE_NAME, addAddonToConfiguration, boundingBox, composer, configuration, dimensions, disableLoading, fitToModuelsScene, group, loadModuleToTheScene, modulesLoader, postAddToSceneActionSofa, removeModuleFromConfiguration, saveInitialConfig, scene, setModulePosition } from "./customizein3d"
import { getModelDimensions, getObjectByObjectId, getObjectDimensionPosition, getParent, getPartNameFromMeshName, updateOtherModelPositions, updatePositionOfAddon } from "./helper"
import { addProductToScene } from "./imageDragging"
import { CURR_SELECTED_MODULE, deselectModule, removeBoxHelper, resetCurrSelectedMeshes, selectModule, setCurrSelectedMeshes, setCurrSelectedModule } from "./raycasting"



// let configuration = {
//     hasLoft:false,
//     hasMorror:false,
//     doorsList:[
//         {
//             name:"Door1",
//             module:{
//                 moduleName:"",
//                 productName:"",
//                 moduleType:""
//             },
//             transform :new Transform(),
//             configuration:{}
//         }
//     ],
//     framesList:[
//         {
//             name:"Frame1",
//             type:"1Door",
//             transform :new Transform(),
//             configuration:{}
//         }
//     ],
//     handlesList:[
//         {
//             name:"Handle1",
//             transform :new Transform(),
//             configuration:{}
//         }
//     ],
//     shelvesList:[
//         {
//             name:"Shelf1",
//             type:"1Door",
//             transform :new Transform(),
//             configuration:{}
//         }
//     ]
// }

let loftsList = []
let doorsList = []
let baseList = []
let handlesList = []
let shelvesList = []
let framesList = []

var doorObject = null

export var currHandle = "Handle1"

 

const axis = new Vector3(0,1,0)

 
//when loading default model pick module type from default model
export function loadWardrobePartsToScene(loader:any,shelfName:string,frameName:string,module:any = null) {
    return new Promise(async (resolve, reject) => {
        let group = new Group()
    
        try {
            // Fetch Common Parts
            let productModules = getFilteredArray(applicationConfig?.data?.productModules,"product_id",configuration.product.productId)
            let product = getObjectByParameter(applicationConfig.data.productsList,"product_name",module?.product_name) || STORE.currProduct
            let productName = product.product_name
            let clientName = product?.storefront_name === "OVL" ? "OVL" : applicationConfig?.clientName

            // let module = null 
            if(!module){
                // let result = productModules.find(currModule => currModule.module_type.includes(moduleType)) || productModules[0]
                // module = new ModuleClass(result)
                let result = productModules.find(currModule => currModule.module_name.includes("2Door")) || productModules[0]
                module = new ModuleClass(result)

            }else{
                module = new ModuleClass(module)
            }

            let moduleType = module.module_type

            let key = `models/${clientName}/common/Wardrobe/Common.tar.gz`
            await untarAndSaveToCache(applicationConfig.awsConfig, `models/${clientName}/common/Wardrobe`, key)
            let productModelsKey = applicationConfig.awsConfig.getTarFileKey("models",{clientName:clientName,productName:module.product_name})
            await untarAndSaveToCache(applicationConfig.awsConfig,`models/${module.product_name}`,productModelsKey)
        
    
            // Define model keys
            const frameKey = `models/OVL/common/Wardrobe/${frameName}_${moduleType}.glb`;
            // const doorKey = `models/${productName}/${productName}_${module.module_name}.glb`;
            const doorKey = `models/OVL/common/Wardrobe/${productName}_${module.module_name}.glb`;


            var shelf:any = null
            var frameObject:any = null
            var handleObject:any = null
    
            // Load Frame
            try {
                const frameUrl:any = await checkFromCache(frameKey);
                frameObject = await loadModuleToTheScene(loader, frameUrl);
                group.add(frameObject);
                framesList.push({
                    object:frameObject,
                    type:moduleType
                })
                // configuration.wardrobeConfiguration.framesList.push(new Frame(frameName,moduleType,frameObject.position,frameObject.rotation,frameObject.scale))
            } catch (error) {
                console.warn('Error loading frame, skipping...', error);
            }
    
            // Load Shelf
            try {
                shelf = await updateWardrobeShelf(moduleType,shelfName,group)
                // configuration.wardrobeConfiguration.shelvesList.push(new Shelf(shelfName,moduleType,shelf.position,shelf.rotation,shelf.scale))
            } catch (error) {
                const shelfKey = `models/OVL/common/Wardrobe/${shelfName}_${moduleType}.glb`;
                console.warn('Error loading shelf, skipping...', error);
            }
    
            // Load Door
            try {
                const doorUrl:any = await checkFromCache(doorKey);
                doorObject = await loadModuleToTheScene(loader, doorUrl);
                group.add(doorObject);
                // configuration.wardrobeConfiguration.doorsList.push(new Door(productName,module.module_name,moduleType,doorObject.position,doorObject.rotation,doorObject.scale))
            } catch (error) {
                console.warn('Error loading door, skipping...', error);
            }

            try {
                handleObject = await updateWardrobeHandles(currHandle)
            } catch (error) {
                console.warn('Error loading handle, skipping...', error);
            }

        //     setTimeout(() => {
        // console.log(getConfiguration())
        //     }, 3000);

            let doorConfig = new Door(productName,module.module_name,moduleType,getConfig(frameObject).pos,getConfig(frameObject).rot,doorObject.scale)
            let frameConfig = new Frame(frameName,moduleType,getConfig(frameObject).pos,getConfig(frameObject).rot,frameObject.scale)
            // updateWorldConfig(doorObject,doorConfig.transform)
            // updateWorldConfig(frameObject,frameConfig.transform)
            group.userData.door = doorConfig
            group.userData.frame = frameConfig 
                
            // configuration.wardrobeConfiguration.doorsList.push(doorConfig)
            // configuration.wardrobeConfiguration.framesList.push(frameConfig)

            resolve({
                object:group,
                module:module
            });
        } catch (error) {
            reject(error); // Only reject if there's a major issue (e.g., untar failure)
        }
    });
}

function getConfig(object:any){

    var target = new Vector3()
    let rotation = new Quaternion()

    object.getWorldPosition(target)
    object.getWorldQuaternion(rotation)

    return{
        pos:target,
        rot:rotation
    }
}

async function updateWorldConfig(object:any,transform:any){

    setTimeout(() => {
        var target = new Vector3()
        let rotation = new Quaternion()
    
        object.getWorldPosition(target)
        object.getWorldQuaternion(rotation)
    
        transform.position = target
        transform.rotation = rotation  

    }, 1500);
}



export function updateWardrobeShelves(shelfName:string){
    let allChildren = group.children
    for (const object of allChildren) {
        let moduleType = object.userData.configuration.moduleType
        updateWardrobeShelf(moduleType,shelfName,object) 
    }
}


export function updateWardrobeShelf(moduleType:string,shelfName:string,object:any = CURR_SELECTED_MODULE){
    return new Promise(async (resolve, reject) => {
        if(!object){
            showGlobalToast("Please select a module",2000,"error")
            resolve("Done")
            return
        }
        showComponentLoader("changeFinishLoader");
        let shelf = object.children.find(currChild=>currChild.name.includes("Shelf"))

        let finishConfig = null
        let moduleName = null
        let productName = null
        if(shelf){
            let allConfig = object.userData?.configuration?.configuration
            moduleName = object.userData?.configuration?.moduleName
            productName = object.userData?.configuration?.productName
            finishConfig = allConfig.find(currConfig => currConfig.partName?.includes("Drawer"))
            detachModules([shelf]);
        }
        // let moduleType = object.userData.configuration.moduleType
        await waitFor(200);
        shelvesList = [];
        // attachAllLoftForUpdateDimensions();
        // const shelfKey = `models/OVL/common/Wardrobe/${shelfName}_${moduleType}.glb`;
        const shelfKey = `models/OVL/common/Wardrobe/${shelfName}_${moduleType}.glb`;

        // Array of promises for loading handles
        try {
            $(".shelf-card-option").removeClass("border-danger")
            const shelfUrl:any = await checkFromCache(shelfKey);
            const shelfObject:any = await loadModuleToTheScene(modulesLoader, shelfUrl);
            shelvesList.push({
                object:shelfObject,
                type:moduleType
            })
            assignTextureMap(scene, shelfObject);
            await waitFor(100);
            hideComponentLoader("changeFinishLoader");
            $(`#${shelfName}_option`).addClass("border-danger")
            let shelfConfig = new Shelf(shelfName,moduleType,getConfig(shelfObject).pos,getConfig(shelfObject).rot,shelfObject.scale)
            // updateWorldConfig(shelfObject,shelfConfig.transform)
            object.userData.shelf = shelfConfig

            object.add(shelfObject)

            let moduleConfig = object?.userData?.configuration
            if(moduleConfig && moduleConfig.additionalConfiguration){  
                moduleConfig.additionalConfiguration.shelf = shelfConfig 
                // configuration.wardrobeConfiguration.shelfList.push(shelfConfig)
            }

            //Apply Prev shelf finish
            if(finishConfig){
                shelfObject.traverse(mesh=>{
                    if(mesh.isMesh){
                        loadAndApplyTextureToMesh(object,finishConfig,mesh,productName,moduleName,"Wardrobe",configuration)
                    }
                })
            }

          
            // copyModelTexture(object,true)
            resolve(shelfObject);
        } catch (error) {
            console.error('Error loading Shelf:', error);
            hideComponentLoader("changeFinishLoader");
            reject(error);
        }
    });
}


export function updateWardrobeDoors(module:any,object:any = CURR_SELECTED_MODULE){
    return new Promise(async (resolve, reject) => {

        if(!object){
            showGlobalToast("Please select a module",2000,"error")
            resolve("Done")
            return
        }
        attachLoftForUpdateDimensions(object)
        let door = CURR_SELECTED_MODULE.children.find(currChild=>currChild.name.toLowerCase().includes("_1door_") || currChild.name.toLowerCase().includes("_2door_"))
        // let door = result.object
        if(door){
            removeDoor(door)
        }

        // let moduleType = object.userData.configuration.moduleType
        await waitFor(200);
        // shelvesList = [];
        // attachAllLoftForUpdateDimensions();
        let productName = module.product_name
        let moduleName = module.module_name
        const doorKey = `models/${productName}/${productName}_${moduleName}.glb`;
        // Array of promises for loading handles
        try {
            $(".shelf-card-option").removeClass("border-danger")
            const doorUrl:any = await checkFromCache(doorKey);
            const doorObj:any = await loadModuleToTheScene(modulesLoader, doorUrl);
            object.add(doorObj);


            assignTextureMap(scene, doorObj);
            await waitFor(100);
            hideComponentLoader("changeFinishLoader");
            updateDoorsList(doorObj)
            hideLoft(doorObj)
            // $(`#${doorObj}_option`).addClass("border-danger")
            resolve(object);
        } catch (error) {
            console.warn('Error loading handle, skipping...', error);
            console.error('Error loading handles:', error);
            hideComponentLoader("changeFinishLoader");
            reject(error);
        }
    });
}
 
export async function updateWardrobeHandles(handleName:string) {
    return new Promise(async (resolve, reject) => {
        if(CURR_SELECTED_MODULE){
            deselectModule()
        }
        showComponentLoader("changeFinishLoader");
        detachModules(handlesList);
        await waitFor(200);
        handlesList = [];
        // attachAllLoftForUpdateDimensions();

        const anchorPoints = getAnchorPoints(group);
        const handleKey = `models/OVL/common/Wardrobe/${handleName}.glb`;

        // Array of promises for loading handles
        const handlePromises = anchorPoints.map(async (anchor) => {
            try {
                $(".handle-card-option").removeClass("border-danger")
                const handleUrl:any = await checkFromCache(handleKey);
                const handleObject:any = await loadModuleToTheScene(modulesLoader, handleUrl);
                handleObject.position.copy(anchor.position);
                anchor.parent.add(handleObject);
                handlesList.push(handleObject);
                assignTextureMap(scene, handleObject);
                currHandle = handleName

                let group = getParent(anchor.parent)
                if(group){
                    let handleConfig = new Handle(currHandle,getConfig(handleObject).pos,getConfig(handleObject).rot,handleObject.scale)
                    // updateWorldConfig(handleObject,handleConfig.transform)
                    group.userData.handle = handleConfig
                    let modulesConfig = group?.userData?.configuration
                    if(configuration){  
                        modulesConfig.additionalConfiguration.handle = handleConfig 
                        // configuration.wardrobeConfiguration.handlesList.push(handleConfig)
                    }
                }


                // configuration.wardrobeConfiguration.handlesList.push(new Handle(currHandle,handleObject.position,handleObject.rotation,handleObject.scale))
                $(`#${handleName}_option`).addClass("border-danger")
            } catch (error) {
                console.warn('Error loading handle, skipping...', error);
            }
        });

        // Wait for all handles to load
        Promise.all(handlePromises)
            .then(async () => {
                // detachAllLoftForUpdateDimensions();
                await waitFor(100);
                hideComponentLoader("changeFinishLoader");
                resolve('');
            })
            .catch((error) => {
                console.error('Error loading handles:', error);
                hideComponentLoader("changeFinishLoader");
                reject(error);
            });
    });
}


function getAnchorPoints(object:any){
    let result = [];
    object.traverse((child) => {
        if (child.name.includes("HandleAnchor")) {
            result.push(child);
        }
    });
    return result
}


export async function loadWardrobeModuleToScene(defaultProduct:any = null,isAddedAsAddon = undefined) {
    try {
      showComponentLoader("canvasLoader")
      deselectModule()
      let wardrobes = getFilteredArray(applicationConfig.data.allProductsList,"category_name","Wardrobe")
      if(wardrobes.length){
        if(!defaultProduct){
            defaultProduct = STORE?.currProduct || getObjectByParameter(wardrobes,"product_name","Module7")
        }
        if(defaultProduct){
            let mainProductCategory = STORE.getCurrCategory()
            let category = defaultProduct.category_name
            let module = getModuleInfo(defaultProduct)

            if(isAddedAsAddon === undefined){
                isAddedAsAddon = mainProductCategory === "Wardrobe" && (category === "Wardrobe" || category === "Dresser" )?false:true 
            }

            // updateLoaderProgress("canvasLoader",0,1,"Downloading...")
            // await untarAndSaveToCache(applicationConfig.awsConfig,`models/${module.product_name}`,key)
            // updateLoaderProgressWithInterval("canvasLoader","Loading model",70,50)
            addProductToScene(module,defaultProduct).then(async (object:Object3D)=>{
                let isAdded = await postAddToSceneActionSofa(object,module,isAddedAsAddon,true)
                if(category === "Dresser"){
                    object.userData.isAttched = true
                    // setPositionOfDresser(object)
                } 
                if(!isAdded){
                    object.clear()
                    showToast("Invalid Config",2000,"error")
                    // reject("Invalid Config")
                }
                disableLoading()
                // addPointLightsToScene(scene,group,15)
                // addPointLightsToScene(scene,floorplanner.group,10)
                GRID.position.setY(group.position.y - 0.01)
                updateGroupCenter(group)
                saveInitialConfig()
                // applyPrevModelTexture(group,configuration,object)
                copyModelTexture(object,mainProductCategory === category)
                updateLoftsList(object)
                updateAddonPosition(object,defaultProduct.category_name)
                object.userData.isLoftVisible = true
                if(mainProductCategory === "Wardrobe"){
                    hideLoft(object)
                }
              }).catch(err=>{
            })
        }else{
            showGlobalToast("No default wardrobe",2000,"error")
        }
      }else{
        showGlobalToast("No wardrobes",2000,"error")
      }
    
    //   startPageTour() 
    } catch (error) {
      disableLoading()
    }
}

export function postWardrobeAddActions(modelObject:any){
    let mainProductCategory = STORE.getCurrCategory()
    if(mainProductCategory === "Wardrobe"){
        let defaultProduct = STORE?.currProduct
        let category = defaultProduct.category_name
        updateLoftsList(modelObject)
        hideLoft(modelObject)
        setTimeout(() => {
            attachLoftForUpdateDimensions(modelObject)
            copyModelTexture(modelObject,mainProductCategory === category)
            detachLoftForUpdateDimensions(modelObject)
        }, 500);
    }
}


export async function toggleAttachWardrobeModule() {
    showComponentLoader("changeFinishLoader")
    if(CURR_SELECTED_MODULE){
        let object = CURR_SELECTED_MODULE
        deselectModule()
        let isAttach = object.userData.isAttched
        let module = object.userData.configuration
        removeModuleFromConfiguration(configuration,object)
        detachModule(object)
        module.isAddedAsAddon = !isAttach
        object.userData.isAttched = !isAttach
        if(isAttach){
            updatePositionOfAddon(object,configuration)
            group.attach(object)
            addAddonToConfiguration(module,object,configuration)
            showGlobalToast("Detached Module")
            updateOtherModelPositions(group,configuration,boundingBox,axis,module.subCategoryName,false)
        }else{
            setModulePosition(group,object,module,configuration,"lshape")
            group.attach(object)
            addModuleToConfiguration(module,object,configuration)
            showGlobalToast("Attached Module")
        }
        fitToModuelsScene()
    }
    hideComponentLoader("changeFinishLoader")

}

 

function copyModelTexture(targetObject:any,isCatagorySame:boolean){
    if(group.children.length < 2){
        return
    }


    let object = null

    const isWardrobeActions = STORE.getCurrCategory() === "Wardrobe" && !isLoftAttach(targetObject)

    if(isCatagorySame || true){
        // object = group.children.find(currObject => getCatgoryName(currObject) === getCatgoryName(targetObject))
        object = group.children[0]
        if(object){
            let productName = targetObject.userData?.configuration?.productName || STORE.currProduct?.product_name
            if(productName){
                targetObject.traverse((mesh:any) => { 
                    if ( mesh.isMesh  && !mesh?.name?.toLowerCase().includes("leg")) {
                        let configurations = object.userData.configuration.configuration 
                        let currMesh = mesh.name
                        if(currMesh.toLowerCase().includes("door")){
                            currMesh = "Door"
                        }
                        currMesh = currMesh.replace("_","")
                        let categoryName = getCatgoryName(targetObject)
                        if( (currMesh.toLowerCase().includes("drawer") || currMesh.toLowerCase().includes("shelf")) && !currMesh.toLowerCase().includes("drawerdoor")){
                            currMesh = "ExternalDrawer"
                        }
                        let partName = currMesh === "Door"?"Door":getPartNameFromMeshName(currMesh,categoryName)
                        let textureInfo = configurations.find(currConfig=> currConfig.partName === partName)
                        if(textureInfo && textureInfo?.companyName !== "Default"){
                            loadAndApplyTextureToMesh(targetObject,textureInfo,mesh,productName,MODULE_NAME,categoryName,configuration)
                        }
                  } 
                });
                // loadAndApplyTexture(targetObject,textureInfo,configuration.product.productName,MODULE_NAME,getCatgoryName(targetObject),configuration)
            } 

            // if(isWardrobeActions){
            //     let loft = getLoftFromObject(targetObject)
            //     loft?.traverse((mesh:any) => { 
            //       if ( mesh.isMesh) {
            //         loadAndApplyTextureToMesh(targetObject,textureInfo,mesh,productName,MODULE_NAME, STORE.getCurrCategory(),configuration).then(data=>{
            //         })
            //       }
            //     });
            // }
           
        }
    }
}

function getCatgoryName(object:any) {
    return object?.userData?.configuration?.categoryName
}


function getModuleInfo(product:any){
    return {
        module_name:product.product_name,
        category_name:product.category_name,
        sub_category_name:product.sub_category_name,
        sub_module_type:"Default",
        module_id:product.productId,
        storefront_name:product.storefront_name,
        module_type:"Product",
        price:0,
        allow_rotation:1,
        allow_movement:1,
        allow_duplication:1,
        allow_delete:1,
      }
}



// function setPositionOfDresser(modelObject:any) {
//       //Prev model used when rearranging the models 
//       let module = configuration.getLastModelObject(modelObject) 
//       let previousModelObj = getObjectByObjectId(scene,module.moduleObjectId)
  
//       modelObject.rotation.set(0,0,0,"XYZ")
      
//       let rotationOffset = 0
  
//       let previousModelObjDimensions:any = getModelDimensions(previousModelObj,new Box3())
  
//       let previousModelWidth_X = previousModelObjDimensions.dimX
//       let previousModelWidth_Z = previousModelObjDimensions.dimZ
  
//       let worldPosition = new Vector3()
//       previousModelObj.getWorldPosition(worldPosition)
  
//       let positl
//         posX : worldPosition.x, 
//         posY : worldPosition.y,
//         posZ : worldPosition.z
//       }
     
//       modelObject.position.copy(new Vector3(positions.posX + (previousModelWidth_X / 2),positions.posY,positions.posZ -  (previousModelWidth_Z / 2)))
//       let worldRotation = new Quaternion()
//       previousModelObj.getWorldQuaternion(worldRotation)
//       modelObject.applyQuaternion(worldRotation)
//       modelObject.rotateOnAxis(axis,rotationOffset)
   
// }



export function openWardrobeModulePanel(){
    $("#wardrobeModuePanel").fadeIn()
    updateLoftToggleText(CURR_SELECTED_MODULE)
    updateBaseToggleText(CURR_SELECTED_MODULE)
}

export function closeWardrobeModulePanel(){
    $("#wardrobeModuePanel").fadeOut()
}


export function updateLoftToggleText(object:any) {
    if(object){
        let isLoftVisible = object.userData.isLoftVisible
        if(isLoftVisible){
            $("#loftToggleButton").find((".text")).text("Hide Loft")
        }else{
            $("#loftToggleButton").find((".text")).text("Show Loft")
        }
    }
}


export function updateBaseToggleText(object:any) {
    if(object){
        let isBaseVisible = object.userData.isBaseVisible
        if(isBaseVisible){
            $("#baseToggleButton").find((".text")).text("Hide Base")
        }else{
            $("#baseToggleButton").find((".text")).text("Show Base")
        }
    }
}

export async function toggleLoft() {
    let object = CURR_SELECTED_MODULE
    let filteredLofts = loftsList.filter(currData => currData.object.uuid === object?.uuid)
    let selectedObject = CURR_SELECTED_MODULE
    let configuration = CURR_SELECTED_MODULE.userData.configuration
    showComponentLoader("changeFinishLoader")
    let isLoftVisible = CURR_SELECTED_MODULE.userData.isLoftVisible
    for (const currData of filteredLofts) {
        if(currData?.loft){
            if(isLoftVisible){
                detachModule(currData.loft)
                CURR_SELECTED_MODULE.userData.isLoftVisible = false
                showGlobalToast("Loft Removed",2000)
            }else{
                currData.object.attach(currData.loft)
                CURR_SELECTED_MODULE.userData.isLoftVisible = true
                showGlobalToast("Loft Added",2000)
            }
            
        } 
    }
    if(configuration){
        configuration.additionalConfiguration.hasLoft = CURR_SELECTED_MODULE.userData.isLoftVisible
    }
    removeBoxHelper()
    updateLoftToggleText(CURR_SELECTED_MODULE)
    deselectModule()
    fitToModuelsScene()
    if(dimensions.isDimensionsVisible){
        dimensions.hideDimensions()
        await waitFor(200)
        dimensions.showDimensions()
    }
    await waitFor(200)
    await updateWardrobeHandles(currHandle)
    setCurrSelectedModule(selectedObject)
    selectModule()
    openWardrobeModulePanel()
   
    hideComponentLoader("changeFinishLoader")
}


export async function toggleBase() {
    let object = CURR_SELECTED_MODULE
    let data = baseList.find(currData => currData.object.uuid === object?.uuid)

    let isLoftVisible = CURR_SELECTED_MODULE.userData.isLoftVisible
    if(!isLoftVisible){
        showGlobalToast("Shoe loft to hide",2000,"error")
        return
    }
    if(data?.base){
        showComponentLoader("changeFinishLoader")
        let selectedObject = CURR_SELECTED_MODULE
        let isBaseVisible = CURR_SELECTED_MODULE.userData.isBaseVisible
        if(isBaseVisible){
            detachModule(data.base)
            CURR_SELECTED_MODULE.userData.isBaseVisible = false
            showGlobalToast("Base Removed",2000)
        }else{
            CURR_SELECTED_MODULE.attach(data.base)
            CURR_SELECTED_MODULE.userData.isBaseVisible = true
            showGlobalToast("Base Added",2000)
        }
        removeBoxHelper()
        updateBaseToggleText(CURR_SELECTED_MODULE)
        deselectModule()
        fitToModuelsScene()
        if(dimensions.isDimensionsVisible){
            dimensions.hideDimensions()
            await waitFor(200)
            dimensions.showDimensions()
        }
        await waitFor(200)
        setCurrSelectedModule(selectedObject)
        selectModule()
        openWardrobeModulePanel()
        hideComponentLoader("changeFinishLoader")
    }
}

export async function toggleDoors(event:any) {

    deselectModule()
    await waitFor(200)


    for (const data of doorsList) {
        if(data?.door){
            let door = data.door
            let isDoorVisible = door.userData.isDoorVisible
            if(isDoorVisible){
                resetWardrobeParents()
                await waitFor(100)
                door.visible = false
                door.userData.isDoorVisible = false
                $(event?.target).addClass("border-danger")
                hideLoftDoors()
            }else{
                door.visible = true
                door.userData.isDoorVisible = true
                $(event?.target).removeClass("border-danger")
                showLoftDoors()
            }
        }
    }
}

export async function showDoors() {
  
    for (const data of doorsList) {
        if(data?.door){
            let door = data.door
            door.visible = true
            door.userData.isDoorVisible = true
        }
    }
    $(".wardrobe-doors").removeClass("border-danger")
    showLoftDoors()
}

export async function hideDoors() {
    deselectModule()
    await waitFor(500)
    for (const data of doorsList) {
        if(data?.door){
            let door = data.door
            door.visible = false
            door.userData.isDoorVisible = false
        }
    }
    hideLoftDoors()
}

function hideLoftDoors(){
    for (const loft of loftsList) {
        let object = loft.loft 
        if(object){
            object.traverse(currMesh => {
                if(currMesh.name.toLowerCase().includes("door")){
                    currMesh.visible = false
                } 
            })
           
        }
    }
    group.traverse(currMesh => {
        if(currMesh.name.toLowerCase().includes("handle")){
            currMesh.visible = false
        } 
    })
}

function showLoftDoors(){
    for (const loft of loftsList) {
        let object = loft.loft 
        if(object){
            object.traverse(currMesh => {
                if(currMesh.name.toLowerCase().includes("door")){
                    currMesh.visible = true
                } 
            })
           
        }
    }
    group.traverse(currMesh => {
        if(currMesh.name.toLowerCase().includes("handle")){
            currMesh.visible = true
        } 
    })
}


export function hideLoft(object:any) {
    let filteredLofts = loftsList.filter(currData => currData.object.uuid === object?.uuid)
    for (const currData of filteredLofts) {
        if(currData){
            detachModule(currData.loft)
            object.userData.isLoftVisible = false
        } 
    }
    setTimeout(() => {
        updateWardrobeHandles(currHandle)
    }, 500);
    fitToModuelsScene()
}

export function showLoft(object:any) {
    let filteredLofts = loftsList.filter(currData => currData.object.uuid === object?.uuid)
    for (const currData of filteredLofts) {
        if(currData){
            currData.object.attach(currData.loft)  
            object.userData.isLoftVisible = true
        } 
    }
    setTimeout(() => {
        updateWardrobeHandles(currHandle)
    }, 500);
}


export function isLoftAttach(object:any) {
    return object?.userData?.isLoftVisible
}

function removeDoor(door:any){
    detachModules([door])
    let doorloft = door.children.find(mesh => mesh?.name?.toLowerCase().includes("loftdoor"))
    loftsList = loftsList.filter(result => result?.mesh?.name !== door.name)

    doorsList = doorsList.filter(result => result.object.name !== door.name)
}

function updateDoorsList(door:any){
    doorsList.push({
        object:door,
        door:door
    })
    door.traverse(mesh=>{
        if(mesh.name.toLowerCase().includes("loft")){
            loftsList.push({
                object:door,
                loft:mesh
            })
        }
    }) 
}


function getConfiguration(){
    // for (const door of doorsList) {
    //     let mesh = door.door 
    //     console.log(door,mesh)
    //     let object = mesh.parent
    //     configuration.wardrobeConfiguration.doorsList.push(new Door(object.name,mesh.name,mesh.position,mesh.rotation,mesh.scale))
    // }
    // for (const shelf of shelvesList) {
    //     let object = shelf.object
    //     let type = shelf.type
    //     configuration.wardrobeConfiguration.shelvesList.push(new Shelf(object.name,type,object.position,object.rotation,object.scale))
    // }
    // for (const frame of framesList) {
    //     let object = frame.object
    //     let type = frame.type
    //     configuration.wardrobeConfiguration.framesList.push(new Frame(object.name,type,object.position,object.rotation,object.scale))
    // }
    // for (const handle of handlesList) {
    //     let object = handle
    //     configuration.wardrobeConfiguration.handlesList.push(new Handle(object.name,object.position,object.rotation,object.scale))
    // }

    return configuration
}

export function updateLoftsList(object:any) {
    let productName = STORE?.currProduct?.product_name || ""
    // loftsList = []
    // let loftGroup = new Group()
    // loftGroup.name = "WardrobeLoftCustomGroup"
    // let lofts = []

    object?.traverse(mesh=>{
        if(mesh?.name.toLowerCase().includes("wardrobeloft") || mesh?.name.toLowerCase().includes("loftdoor") ){
            loftsList.push({
                object:object,
                loft:mesh
            })
        }
    }) 
    // object.add(loftGroup)
    // detachModules(lofts)
    // attachModules(lofts,loftGroup)
    // loftsList.push({
    //     object:object,
    //     loft:loftGroup
    // })
    // object?.traverse(mesh=>{
    //     if(mesh?.name.toLowerCase().includes("loft") && mesh?.isMesh){
            
    //     }
    // }) 

    object.traverse(mesh=>{
        let meshName = mesh.name.toLowerCase() 
        if((meshName.includes("door_") || meshName.includes("loftdoor")) && !meshName.includes("frame") && !meshName.includes("shelf") && !meshName.includes("handle")){
            mesh.userData.isDoorVisible = true
            doorsList.push({
                object:mesh,
                door:mesh
            })
        }
    }) 


    object.traverse(mesh=>{
        if(mesh.name.toLowerCase().includes("wardrobebase")){
            object.userData.isBaseVisible = true
            baseList.push({
                object:object,
                base:mesh
            })
        }
    }) 

}


export function attachLoftForUpdateDimensions(object:any){
    let data = loftsList.find(currData => currData.object.uuid === object.uuid)
    if(data){
        // data.loft.visible = true
        data.object.attach(data.loft)  
    }
}

export function detachLoftForUpdateDimensions(object:any){
    let data = loftsList.find(currData => currData.object.uuid === object?.uuid)
    if(data){
        // data.loft.visible = false
        detachModule(data.loft)
        data.object.userData.isLoftVisible = false
    }
}


export function getLoftsFromObject(object:any){
    let lofts = []
    loftsList.filter(currData => {
        if(currData.object.uuid === object?.uuid){
            lofts.push(currData?.loft)
        }
    })
    return lofts
}


export function attachAllLoftForUpdateDimensions(){
    for (const data of loftsList) {
        data.object.attach(data.loft)  
        data.object.userData.isLoftVisible = true
    }
}

export function detachAllLoftForUpdateDimensions(){
    for (const data of loftsList) {
        detachModule(data.loft) 
        data.object.userData.isLoftVisible = false
    }
}
 

export function updateAddonPosition(modelObject:any,categoryName:string) {
    let data = getObjectDimensionPosition(null,group)
    modelObject.rotation.set(0,0,0,"XYZ")

    if(categoryName === "Cot"){
        modelObject.rotateOnWorldAxis(axis,-Math.PI)
        // modelObject.position.copy(new Vector3(posX,posY,posZ))
        modelObject.translateZ(-2);
    }
  }
 

export function resizeLoft(action:string){
    if(CURR_SELECTED_MODULE){
        if(!CURR_SELECTED_MODULE.userData.isLoftVisible){
            showGlobalToast("Enable loft",2000,"error")
            return
        }
        let moduleLofts = loftsList.filter(currData => currData.object.uuid === CURR_SELECTED_MODULE?.uuid)

        for (const data of moduleLofts) {
            let currLoft = data?.loft
        if(currLoft){
            let dimData = getObjectDimensionPosition(null,currLoft).dimensions
            let dimY = dimData.dimY
            const increaseWidthByMeters = (1 / 100 )
            let currBreadth = currLoft.scale.x 
            let currDepth = currLoft.scale.z
            if(action==="plus"){
                let result = dimY + (2.54 / 100);
                currLoft.scale.set(currBreadth,(result / dimY) * currLoft.scale.y ,currDepth)
              }else{
                let result = dimY - (2.54 / 100);
                currLoft.scale.set(currBreadth,(result / dimY) * currLoft.scale.y ,currDepth)
                // let result = currLoft.scale.z - increaseWidthByMeters;
                // currLoft.scale.set(currBreadth,result,result)
            }
            removeBoxHelper()
            updateActionPanelInformation(CURR_SELECTED_MODULE,boundingBox)
            if(dimensions.isDimensionsVisible){
                dimensions.hideDimensions()
                setTimeout(() => {
                  dimensions.showDimensions()
                }, 200);
            }
        }
        }


        
    }
    
}

export function applyFinishInLoft(object:any,textureInfo:any) {
    let categoryName = object?.userData?.configuration?.categoryName || STORE.currProduct.category_name
    let lofts = getLoftsFromObject(object)
    for (const loft of lofts) {
        loft?.traverse(async (mesh:any) => { 
            if ( mesh.isMesh) {
              let productName = object.userData?.configuration?.productName || STORE.currProduct?.product_name
              let objectConfig = object.userData.configuration.configuration
  
              let textureInfo = objectConfig.find(currConfig => currConfig.partName.includes(mesh.name) || mesh.name.includes(currConfig.partName))    
              console.log(objectConfig,textureInfo,mesh.name)
              if(textureInfo){
                  await loadAndApplyTextureToMesh(object,textureInfo,mesh,productName,MODULE_NAME,categoryName,configuration).then(data=>{
                  })
              }
                
                }
        });
    }
        
}

export function wardrobeAccordionClickActions(text:string) {
    if(text.toLowerCase().includes("internal") || text.toLowerCase().includes("shelf")){
        toggleDoors(null)
    }else{
        showDoors()
    }
}

export function resetWardrobeParents() {
    group.traverse(currMesh =>{
        if(currMesh.userData.originalParent){
            let originalParent = currMesh.userData.originalParent
            detachModule(currMesh)
            attachModules([currMesh],originalParent)
        }
    })
}

export function resetParent(object:any) {
    try {
        object.traverse(currMesh =>{
            if(currMesh.userData.originalParent){
                let originalParent = currMesh.userData.originalParent
                detachModule(currMesh)
                attachModules([currMesh],originalParent)
            }
        })
    } catch (error) {
        console.log(error)
    }
    
}

export function selectWardrobeLines() {
    try {
        resetCurrSelectedMeshes()
        let meshes = []
        group.traverse(currMesh =>{
            if(currMesh.isMesh && currMesh.name.includes("MiddleBand")){
                // detachModule(currMesh)
                if(currMesh?.parent?.isMesh && STORE.getCurrCategory() === "Wardrobe"){
                    let group = getParent(currMesh)
                    if(group){
                        currMesh.userData.originalParent = currMesh.parent
                        detachModule(currMesh)
                        attachModules([currMesh],group)
                    }
                }
                
                meshes.push(currMesh)
            }
        })
        showGlobalToast("Lines Selected",2000)
        setCurrSelectedMeshes(meshes)
        $(".hide-on-select").addClass("visibility-hidden")
        $(".deselect-button").removeClass("visibility-hidden")  
        composer.outlinePass.selectedObjects = meshes
    } catch (error) {   
        showGlobalToast("Error in selecting try again",2000,"error")
    }
}

export async function downloadCutlist(){


    try {
        showComponentLoader("changeFinishLoader")
        const data = [];
        let modules = group.children;

        
        for (const module of modules) {
          const moduleData = [];
          let moduleName = module.userData?.configuration?.moduleName || ""
          let productName = module.userData?.configuration?.productName || ""

          let title = `${productName} - ${moduleName}`
        
          module.traverse((currMesh) => {
            if (
              currMesh.isMesh &&
              currMesh.name &&
              !currMesh?.name.includes("Glass") &&
              !currMesh?.name.includes("Handle_Metal")
            ) {
              let dim = getObjectDimensionPosition(null, currMesh);
              moduleData.push({
                PartName: currMesh.name,
                DimX: Math.round(dim.dimensions.dimX * 100),
                DimY: Math.round(dim.dimensions.dimY * 100),
              });
            }
          });
        
          if (moduleData.length > 0) {
            // Add module name as a heading row
            data.push([`${title || ""}`]);
            // Add column headers for each module section
            data.push({ PartName: "Part Name",DimX: "DimX (CM)",DimY: "DimY (CM)" });
            // Add the module's parts data
            data.push(...moduleData);
            // Add an empty row for spacing between modules
            data.push([]);
          }
        }
        
        await waitFor(1000);
        
        let windowObj:any = window;
        
        // Create workbook and worksheet
        const workbook = windowObj.XLSX.utils.book_new();
        const worksheet = windowObj.XLSX.utils.json_to_sheet(data, {
          skipHeader: true, // Skip default headers
        });
        
        // Append worksheet to workbook
        windowObj.XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        
        // Generate Excel file and trigger download
        windowObj.XLSX.writeFile(workbook, "Cutlist.xlsx");
        hideComponentLoader("changeFinishLoader")
        
    } catch (error) {
        console.log(error)
        hideComponentLoader("changeFinishLoader")
        showGlobalToast("Error in generating",2000,"error")
    }
    
}

 
  