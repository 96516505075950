import { CameraHelper, Group, Mesh, MeshBasicMaterial, MeshDepthMaterial, OrthographicCamera, PlaneGeometry, ShaderMaterial, WebGLRenderTarget } from "three";
import { HorizontalBlurShader } from "three/examples/jsm/shaders/HorizontalBlurShader";
import { VerticalBlurShader } from "three/examples/jsm/shaders/VerticalBlurShader";
import { renderer, scene } from "./customizein3d";
import { detachModule } from "../common";

export const GROUND_WIDTH = 25, GROUND_HEIGHT = 25
const state = {
  shadow: {
    blur: 1.2,
    darkness: 0.7,
    opacity: 1,
  },
  plane: {
    color: '#ffffff',
    opacity: 0,
  },
  showWireframe: false,
};
const CAMERA_HEIGHT = 2;
let shadowGroup, renderTarget, renderTargetBlur, shadowCamera, cameraHelper, depthMaterial, horizontalBlurMaterial, verticalBlurMaterial;
let plane, blurPlane, fillPlane;

export function createShadow() {
  detachModule(shadowGroup)
  shadowGroup = new Group();
  shadowGroup.name = "shadowGroup"
  shadowGroup.position.y = 0;
  scene.add( shadowGroup );

  // the render target that will show the shadows in the plane texture
  renderTarget = new WebGLRenderTarget( 512, 512 );
  renderTarget.texture.generateMipmaps = false;

  // the render target that we will use to blur the first render target
  renderTargetBlur = new WebGLRenderTarget( 512, 512 );
  renderTargetBlur.texture.generateMipmaps = false;

  // make a plane and make it face up
  const planeGeometry = new PlaneGeometry( GROUND_WIDTH, GROUND_HEIGHT ).rotateX( Math.PI / 2 );
  const planeMaterial = new MeshBasicMaterial( {
    map: renderTarget.texture,
    opacity: state.shadow.opacity,
    transparent: true,
    depthWrite: false,
  } );
  plane = new Mesh( planeGeometry, planeMaterial );
  // make sure it's rendered after the fillPlane
  plane.renderOrder = 0;
  shadowGroup.add( plane );

  // the y from the texture is flipped!
  plane.scale.y = - 1;




  // the plane onto which to blur the texture
  blurPlane = new Mesh( planeGeometry );
  blurPlane.visible = false;

  blurPlane.bVisible = true

  // plane.position.set(GROUND_WIDTH/2,0,GROUND_HEIGHT/2);
  // blurPlane.position.set(GROUND_WIDTH/2,0,GROUND_HEIGHT/2);

  blurPlane = new Mesh( planeGeometry );

  shadowGroup.add( blurPlane );

  // the camera to render the depth material from
  shadowCamera = new OrthographicCamera( - GROUND_WIDTH / 2, GROUND_WIDTH / 2, GROUND_HEIGHT / 2, - GROUND_HEIGHT / 2, 0, CAMERA_HEIGHT );
  shadowCamera.rotation.x = Math.PI / 2; // get the camera to look up
  shadowGroup.add( shadowCamera );

  cameraHelper = new CameraHelper( shadowCamera );

  // like MeshDepthMaterial, but goes from black to transparent
  depthMaterial = new MeshDepthMaterial();
  depthMaterial.userData.darkness = { value: state.shadow.darkness };
  depthMaterial.onBeforeCompile = function ( shader ) {

    shader.uniforms.darkness = depthMaterial.userData.darkness;
    shader.fragmentShader = /* glsl */`
      uniform float darkness;
      ${shader.fragmentShader.replace(
    'gl_FragColor = vec4( vec3( 1.0 - fragCoordZ ), opacity );',
    'gl_FragColor = vec4( vec3( 0.0 ), ( 1.0 - fragCoordZ ) * darkness );'
  )}
    `;

  };

  depthMaterial.depthTest = false;
  depthMaterial.depthWrite = false;


	horizontalBlurMaterial = new ShaderMaterial( HorizontalBlurShader );
  horizontalBlurMaterial.depthTest = false;

  verticalBlurMaterial = new ShaderMaterial( VerticalBlurShader );
  verticalBlurMaterial.depthTest = false;

}


export function blurShadow( amount:any ) {

  blurPlane.visible = true;

  // blur horizontally and draw in the renderTargetBlur
  blurPlane.material = horizontalBlurMaterial;
  blurPlane.material.uniforms.tDiffuse.value = renderTarget.texture;
  horizontalBlurMaterial.uniforms.h.value = amount * 1 / 256;

  renderer.setRenderTarget( renderTargetBlur );
  renderer.render( blurPlane, shadowCamera );

  // blur vertically and draw in the main renderTarget
  blurPlane.material = verticalBlurMaterial;
  blurPlane.material.uniforms.tDiffuse.value = renderTargetBlur.texture;
  verticalBlurMaterial.uniforms.v.value = amount * 1 / 256;

  renderer.setRenderTarget( renderTarget );
  renderer.render( blurPlane, shadowCamera );

  blurPlane.visible = false;

}

export function renderShadow( ) {

  // remove the background
  const initialBackground = scene.background;
  scene.background = null;

  // force the depthMaterial to everything
  cameraHelper.visible = false;
  scene.overrideMaterial = depthMaterial;

  // set renderer clear alpha
  const initialClearAlpha = renderer.getClearAlpha();
  renderer.setClearAlpha( 0 );

  // render to the render target to get the depths
  renderer.setRenderTarget( renderTarget );
  renderer.render( scene, shadowCamera );

  // and reset the override material
  scene.overrideMaterial = null;
  cameraHelper.visible = true;

  blurShadow( state.shadow.blur );

  // a second pass to reduce the artifacts
  // (0.4 is the minimum blur amout so that the artifacts are gone)
  blurShadow( state.shadow.blur * 0.4 );

  // reset and render the normal scene
  renderer.setRenderTarget( null );
  renderer.setClearAlpha( initialClearAlpha );
  scene.background = initialBackground;

  // renderer.render( scene, camera );
}
