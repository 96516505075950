import { Group, MathUtils, Matrix4, Object3D, Scene, Sprite, Vector2, Vector3 } from "three";
import { IS_SPACE_PLANNER_MODE, addonsGroup, camera, configuration, controls, dimensions, element, group, renderer } from "./customizein3d";
import { updateGroupCenter } from "../common";
import { updateBoxHelper } from "./raycasting";
import { getClicked3DPoint, updateDragIconPosition } from "./annotations";
const vector = new Vector3()

export class Dragging {
  mouseDown = false;
  object:any
  client = new Vector2(Infinity,Infinity)
  isDragging:boolean = false
  draggingGroup:Group = new Group()
  scene:Scene = null

  //To check in pointer move if pointer is on selected object 
  allowDragging:boolean = false

  constructor(scene:any) {
    this.scene = scene
    scene.add(this.draggingGroup)
  }

  setObject(object:any){
    if(object){
      let module = object.userData.configuration
      if(module?.allowMovement || object?.isSprite){
        this.object = object
        this.allowDragging = true
      }
    }
  }

  resetObject(){
    this.object = null
  }

  pointerDown(event:any) {
    this.mouseDown = true;
    const x = event.pageX || event.targetTouches[0].pageX 
    const y = event.pageY || event.targetTouches[0].pageY
    let worldPos:any = getClicked3DPoint(x,y,vector,element,camera)
    if(worldPos){
      this.client.x = worldPos.x
      this.client.y = worldPos.z
    }
  }

  pointerMove(event:any) {
    let numTouches = 1
    if(event.touches){
      numTouches = event.touches.length
    }

    let isAddedAsAddon = this.object?.userData?.configuration?.isAddedAsAddon


    if (this.mouseDown && this.object && (IS_SPACE_PLANNER_MODE || isAddedAsAddon) && numTouches && this.allowDragging) {

      const x = event.pageX || event.targetTouches[0].pageX 
      const y = event.pageY || event.targetTouches[0].pageY

      dimensions.hideDimensions()
      let delta = 1
      let worldPos:any = getClicked3DPoint(x,y,vector,element,camera)
      if(worldPos){
        if(this.object.isSprite){
          this.object.position.x += ((worldPos.x - this.client.x)* delta)
          this.object.position.z += ((worldPos.z - this.client.y) * delta)
        }else{
          // let rotationMatrix = new Matrix4().makeRotationY(MathUtils.degToRad(configuration.groupRotation));
          // let movementVector = new Vector3(deltaMove.x,0,deltaMove.y);
          // movementVector.applyMatrix4(rotationMatrix);
          // this.object.position.add(movementVector);
          this.object.position.x += ((worldPos.x - this.client.x)* delta)
          this.object.position.z += ((worldPos.z - this.client.y) * delta); 
        }
  
        this.client.x = worldPos.x
        this.client.y = worldPos.z
  
        controls.enabled = false
        updateBoxHelper()
        updateDragIconPosition(group,renderer,camera,[group,addonsGroup])
      }
     
    }
  }

  pointerUp(event:any) {
    this.mouseDown = false;

    controls.enabled = true
    if(this.isDragging){
      this.isDragging = false
    }
    updateGroupCenter(group)
  }


  updatePositionInSpacePlannerMode(x:number,y:number) {
    let position:any = getClicked3DPoint(x,y,vector,element,camera)
    let posY = 0
    if(position && this.object){
      this.object?.position.set(position.x,posY,position.z)
    }
  }
  
  updatePositionIn3dMode(x:number,y:number){
    let currentPosition = getClicked3DPoint(x,y,vector,element,camera)
    if(currentPosition && this.object){
      let posY = this.object.position.y
      let position = new Vector3(currentPosition.x,posY,currentPosition.z)
      this.object?.position.copy(position)
    }
  }
}
