import React from "react";
import {
  closeDropDowns,
  handleDropDownToggle
} from "../../../../utils/UI_methods/global";
import $ from "jquery"


const DropDown: React.FC<{
  values: any;
  currSelectedValue: any;
  action: (value: any) => void;
  classNames: string;
  columns:number;
  label:string
}> = (props) => {

  function dropDownAction(value:string) {
    props.action(value);
    closeDropDowns();
    $("#updateLayoutButton").addClass("pulse-anchor")

  }

  function toggle(event:any) {
    handleDropDownToggle(event)
    $(".scroll-down-element").scrollTop(500)
  }

  return (
    <div
      className={`dropdown-container center-aligned-column position-relative ${props.classNames}`}>
      <div
        className="dropdown-select input display-flex-row text-start dropdown-toggle full-width"
        onClick={(event)=>{toggle(event)}}>
        <span className="ion-margin-end pointer-none label select-value">
          {props.currSelectedValue}
        </span>
      </div>
      <div role="menu" aria-hidden="true" className="hide-scrollbar dropdown-menu-rounded dropdown-menu">
        <div className="display-flex-row">
          {/* <div className="dropdown-item-container custom-col-100">
                <button type="button" className={`dropdown-item label`} onClick={() => {dropDownAction(null)}}>
                  {`Select ${props.label}`}
                </button>
          </div> */}
          {props.values?.map((value: any, index: number) => {
            let classNames = "dropdown-item"
            let classNamesContainer = "dropdown-item-container "
            if(value===props.currSelectedValue){
              classNames = classNames + " --is-active"
            }
            if(props.columns===1){
              classNamesContainer = classNamesContainer + " custom-col-100"
            }
            if(props.columns===2){
              classNamesContainer = classNamesContainer + " custom-col-50"
            }
            if(props.columns===3){
              classNamesContainer = classNamesContainer + " custom-col-30"
            }
            return (
              <div className={classNamesContainer} key={index}>
                <button
                  type="button"
                  className={classNames}
                  onClick={() => {
                    dropDownAction(value)
                  }}>
                  {value}
                </button>
              </div>
            );
          })}
        </div>
      </div>


        {props.label.length?
      <div className="drop-down-label">
          <span className="label heading">{props.label}</span>
      </div>
      :null}

    </div>
  );
};

export default DropDown;
