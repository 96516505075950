import $ from "jquery"
import { Box3, Color, Group, Material, Mesh, Object3D, OrthographicCamera, PerspectiveCamera, Scene, TextureLoader, Vector2, Vector3 } from "three"
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { getWindowHeight, getWindowWidth, updateLoaderProgress } from "../UI_methods/global"
import { untarAndSaveToCache } from "../cache/cache"
import { getUrlPrefix } from "../customizer/customizer"
import { isTouchDevice } from "../customizer/helper"
import { applicationConfig, getArrayOfDistinctValuesFromArray, getFilteredArray, getMaterialTypesFromPartName, getObjectByParameter, stringWithoutNumbers } from "../methods"
import { Configuration } from "./Configuration"
import { updateSceneCameraAspectRatio } from "./controls"
import { generatedEnvironmentMap } from "./enviornment"
import { ALL_MATERIALS, ALL_MESHES, IS_SPACE_PLANNER_MODE, configuration, group, configuration as modulesConfiguration, sceneBackgrounds, sprites } from "./modules/customizein3d"
import { createMeshStandardMaterial, getAllMapsArray, getAllUrls, getDefaultConfig, getMaterialUpdated, getModelPositions, getObjectDimensionPosition, getPartNameFromMeshName, getValuesFromMaterialType, setModuleProperties, updateTotalPrice } from "./modules/helper"
import { tweenCamera, tweenControls } from "./tween"
import { STORE } from "../store/storeConfiguration"
import { getOriginalPartName } from "./area3dmodel/helper"
import { materialHexRelationship } from "../data"

export var rendererDimensions = new Vector2()



var textureLoader = new TextureLoader();

var controlsPanelWidth = 380

var target = new Vector3()

export var draggableGroup = new Group()
export const DRAG_ICON_NAME = "dragicon" 
var dragIconControls:any = null
//Drag controls for individual 3d models
export const SELECTED_OBJECT_OUTLINE_NAME = "selectedProduct"

var fontLoader = new FontLoader()
var boundingBox = new Box3()

export var DRAG_ICON = null

export var isOpenControlsPanel = false

 
function getAllArchiveUrls(modelObject:any,categoryName:string){
    let urls:any = []
    let textureInfo = []
    modelObject.traverse((mesh:any) => { if ( mesh.isMesh ) {
      let partName = getPartNameFromMeshName(mesh.name)
      if(partName){
        let config = getConfigToBeApplied(partName,null,categoryName)
        textureInfo.push(config)
          let url = getAllUrls(config)
          if(!url.textureUrl.includes("null")){
            urls.push(url.textureUrl)
          }
        } 
      }
    })
    return [getArrayOfDistinctValuesFromArray(urls),textureInfo]
  }

  
export function prepareModule(object:any,productName:string,moduleName:string,categoryName:string,isApplyFinish=true) {
    return new Promise((resolve,reject)=>{
      setModuleProperties(object)
      let data:any = getAllArchiveUrls(object,categoryName)
      let urls = data[0]
      let textureInfo = data[1]
      if(!isApplyFinish){
        resolve(object)
      }
      if(!urls.length){
        resolve("Completed")
      }
      downloadTexturesTarFiles(urls,textureInfo).then(data=>{
        loadAndApplyTexture(object,null,productName,moduleName,categoryName,configuration).then(data=>{
          resolve(data)
        }).catch(err=>{
          reject(err)
        })
      }).catch(err=>{
        reject(err)
      })
    })
  }

  export async function downloadTexturesTarFiles(urls: Array<any>, textureInfoList: any) {
    if (!textureInfoList.length) {
      return "completed";
    }
  
    const downloads = [];
  
    for (let i = 0; i < textureInfoList.length; i++) {
      const textureInfo = textureInfoList[i];
      const tarFileKey = applicationConfig.awsConfig.getTarFileKey("thumbnails", {
        companyName: textureInfo.companyName,
        collectionName: textureInfo.collectionName,
      });
      const urlPrefix = getUrlPrefix(tarFileKey);
      try {
        downloads.push(await untarAndSaveToCache(applicationConfig.awsConfig, urlPrefix, tarFileKey));
      } catch (error) {
        
      }
    }
  
    // for (let i = 0; i < textureInfoList.length; i++) {
    //   const textureInfo = textureInfoList[i];
    //   const urlPrefix = `textures/${textureInfo?.companyName}/${textureInfo?.collectionName}/${textureInfo?.collectionName}Common/`;
    //   const tarFileKey = applicationConfig.awsConfig.getTarFileKey("commonTexture", textureInfo);
    //   if (!tarFileKey.includes("null")) {
    //     try {
    //       downloads.push(await untarAndSaveToCache(applicationConfig.awsConfig, urlPrefix, tarFileKey));
    //     } catch (error) {
          
    //     }
    //   }
    // }
  
    await Promise.all(downloads);
  
    return "completed";
  }
  
  
  export function getConfigToBeApplied(partName:string,textureInfo:any,categoryName:string) {
    let config = null  
    // Check for the configuration if there is config apply texture from 
      // If not avilable set from the default config 
      let customizeConfig = null
      
      //For customizer
      // if(customizeconfiguration?.productConfiguration){
      //   if(customizeConfiguration?.productConfiguration[partName]?.material_type === "Default"){
      //     customizeConfig = null
      //   }else{
      //     customizeConfig = customizeConfiguration?.productConfiguration[partName]
      //   }
      // }else{
      //   customizeConfig = customizeConfiguration?.configuration[0]?.configuration[partName]  
      // }

      //if texture is not null 
      config = textureInfo
      // If texture is null 
      if(!textureInfo){
        //If customization already exits
        if(customizeConfig){
          config = customizeConfig
        }else{// Choose from default config 
          // config = DEFAULT_CONFIG[stringWithoutNumbers(partName)]
          config = getDefaultConfig(stringWithoutNumbers(partName),categoryName)
        }
      }
      return config
  }

  export function addModuleToConfiguration(module:any,object:Object3D,configuration:any) {
    // configuration?.addModule({module:module,objectId:object.uuid})
    let moduleInstance = configuration?.addModule(module,object)
    moduleInstance.moduleObjectId = object.uuid
    object.userData.configuration = moduleInstance
    setTimeout(() => {
      updateTotalPrice(configuration,applicationConfig)
    }, 200);

    if(STORE.getCurrCategory() === "Wardrobe"){
      moduleInstance.additionalConfiguration.door = object.userData.door
      moduleInstance.additionalConfiguration.shelf = object.userData.shelf
      moduleInstance.additionalConfiguration.frame = object.userData.frame
      moduleInstance.additionalConfiguration.handle = object.userData.handle
    }


    return moduleInstance
  }


  
export function loadAndApplyTexture(modelObject:any,textureInfo:any,productName:string,moduleName:string,categoryName:string,configuration:Configuration) {
    return new Promise((resolve,reject)=>{
        let i = 0
        let currCategory = STORE.getCurrCategory()
        //For swing stand is added to group
        let objectToTraverse = currCategory === "Swing"?group:modelObject
        let childrenLength = getObjectChildrensLength(modelObject)
        objectToTraverse.traverse((mesh:any) => { 
          if ( mesh.isMesh  && !mesh?.name?.toLowerCase().includes("leg")) {
            let meshName = mesh.name
            if((currCategory === "Swing" || currCategory === "Wardrobe") && textureInfo.materialType === "Metal"){
              updateColorValue(modelObject,mesh,textureInfo,moduleName)
              if(i === childrenLength - 1){
                resolve("completed")
              }
              i++
            }else{
              let isCurrPart = meshName?.toLowerCase().includes(STORE.currSelectedSwingPart?.toLowerCase()) || STORE.currSelectedSwingPart?.toLowerCase().includes(getPartNameFromMeshName(meshName,currCategory)?.toLowerCase()) 
              let canApplyFinish = ((currCategory === "Swing" || currCategory === "Wardrobe")) ? isCurrPart : true 
              if(meshName?.toLowerCase().includes("drawerdoor") && STORE.currSelectedSwingPart === "Door"){
                canApplyFinish = false
              }
              if(canApplyFinish){
                loadAndApplyTextureToMesh(modelObject,textureInfo,mesh,productName,moduleName,categoryName,configuration).then(data=>{
                  updateLoaderProgress("canvasLoader",i+1,modelObject.children.length,"Applying Finish")
                    if(i === childrenLength - 1){
                        resolve("completed")
                    }
                    i++
                })
              }else{
                if(i === childrenLength - 1){
                    resolve("completed")
                }
                i++
              }
          }
        }else{
          i++
        }
      });
    })
  }

  export function updateColorValue(modelObject:any,mesh:any,textureInfo:any,moduleName:string){
    if(mesh?.name?.toLowerCase().includes("metal") && mesh?.name?.toLowerCase().includes(STORE.currSelectedSwingPart?.toLowerCase())){
      let partName = getOriginalPartName(STORE.getCurrCategory(),mesh.name)
      if(partName){
        let result = getObjectByParameter(materialHexRelationship,"materialCode",textureInfo.materialCode)
        if(result){
          mesh.material.color.setHex(result.hex);
          mesh.material.needsUpdate = true;
          const baseColourHexCode = result.baseColourHexCode
          modulesConfiguration?.updateFinish(modelObject,partName,{baseColourHexCode:baseColourHexCode,...textureInfo},STORE.getCurrCategory(),moduleName)
        }
      }
    }
  }

  export function getObjectChildrensLength(modelObject:any) {
    let children = 0
    modelObject.traverse((mesh:any) => { 
      children += 1
    });
    return children
  }


  export function applyEnvMapInModel(modelObject:any) {
    modelObject.traverse((mesh:any) => { if ( mesh.isMesh ) {
      mesh.material.envMap = generatedEnvironmentMap.texture
    }});
  }
  
  export function getAllMeshesFromGroup(group:Group) {
    let meshes = []
    group.traverse((currMesh:any)=>{
      if(currMesh.isMesh && currMesh.material){
        meshes.push(currMesh)
      }
    })
    return meshes
  }
  
  export function loadAndApplyTextureToMesh(modelObject:any,textureInfo:any,mesh:any,productName:string,moduleName:string,categoryName:string,configuration:Configuration){
    return new Promise((resolve,reject)=>{
      let materialToBeApplied = null
      let currMesh = mesh.name
      if(currMesh.toLowerCase().includes("feetchrome")){
        currMesh = "Metal"
      } 
      if(currMesh.toLowerCase().includes("glass")){
        currMesh = "Wood"
      }
      if(currMesh.toLowerCase().includes("door") && STORE.getCurrCategory() === "Wardrobe"){
        currMesh = "Door"
      }
      if(currMesh.toLowerCase().includes("leg")){
        resolve("completed")
      }
      currMesh = currMesh.replace("_","")
      if((currMesh.toLowerCase().includes("drawer") || currMesh.toLowerCase().includes("shelf")) ){
        currMesh = "ExternalDrawer"
      }

      let partName = getPartNameFromMeshName(currMesh,categoryName)
      if(!partName){
        mesh.material.envMap = generatedEnvironmentMap.texture
        resolve("completed")
      }
      let config = getConfigToBeApplied(partName,textureInfo,categoryName)

      let partNameForMaterialTypes = partName === "Door"?"Base":partName
      let materialTypes = getMaterialTypesFromPartName(partNameForMaterialTypes,applicationConfig?.data?.objectMaterialTypes,categoryName)
      //When model loades initiallaly for some parts matrial type will be null 
      if(config.materialType){

          //check if material types exits for the part
        if(materialTypes.includes(config.materialType)){
          // materialToBeApplied = customizeconfiguration?.checkIfMaterialExists(JSON.stringify(config))
          materialToBeApplied = null
          if(materialToBeApplied){
            mesh.material = materialToBeApplied
            ALL_MATERIALS.push(materialToBeApplied)
            if(!configuration?.isViewProductMode){
              configuration?.updateFinish(modelObject,partName,config,categoryName,moduleName)
            }
            resolve("completed")
          }else{

            //create material if not exists in the config
            getMaterialUpdated(textureLoader,config,mesh.material).then((material:any)=>{
              // mesh.material = material
              config.uvScale = material?.map?.repeat?.x || 36
              config.roughness = material?.roughness || 0.8
              config.transmission = material?.transmission || 0
              config.sheen = material?.sheen || 0
              config.clearcoat = material?.clearcoat || 0
              material.userData.textureInfo = config

              ALL_MATERIALS.push(material)
              // customizeconfiguration?.addMaterialToConfig(material)
              // if(!modulesConfiguration?.isViewProductMode){
              let partNameForconfig = partName === "Door"?"Door":partName
              modulesConfiguration?.updateFinish(modelObject,partNameForconfig,config,categoryName,moduleName)
              // }
              resolve("completed")
            })
          }
        }else{
          resolve("completed")
        }
      }else{
        let materialType = materialTypes[0]
        // let allMaps = getDefaultMaterialMapValues(materialType)
        let values = getValuesFromMaterialType(materialType)
        let allMaps = getAllMapsArray(textureInfo,values)
 
        createMeshStandardMaterial(allMaps,textureLoader,JSON.stringify(allMaps)).then(material=>{
          mesh.material = material
          ALL_MATERIALS.push(material)
          // customizeconfiguration?.addMaterialToConfig(material)
          resolve("completed")
        })
      }
    })
  }
  


  export function translateObject(object:any,normal:Vector3,offset:number) {
    object.translateOnAxis(normal,offset)
    
  }
  
export function fitRendererIntoElement(renderer:any,camera:any,scale:number = 1) {

  try {
    // let canvasContainer = getCanvasContainerWidthheight(16,9.5)
    let width = $(".customize-canvas-wrapper").width();
    let height = $(".customize-canvas-wrapper").height();
    if(camera.isOrthographicCamera){
      camera.left = width / - (2 * scale) ;
      camera.right = width / (2 * scale);
      camera.top = height / (2 * scale);
      camera.bottom = - height / (2 * scale);
    }
    camera.aspect = width / height
    camera.updateProjectionMatrix()
    renderer.setSize( width,height)
  } catch (error) {
    
  }
 
}

export function expandControlsPanel(callback:()=>void) {
  controlsPanelWidth = getControlsPanelWidth()
  controlsPanelWidth = (getWindowWidth() / 3) > controlsPanelWidth ? controlsPanelWidth : getWindowWidth() / 3 
  if(!$(".customize-controls-wrapper").hasClass("--is-active")){
      isOpenControlsPanel = true
      let dimensionName = "width"
      let aspectRatio = getWindowWidth() / getWindowHeight()
      if(isPotraitMode() && isTouchDevice()){
        // if(getWindowWidth() <= 480 || (aspectRatio >= 0.6 && aspectRatio <= 0.8)){
          dimensionName = "height"
          controlsPanelWidth = getWindowHeight() * 0.42
          $(".customize-canvas-controls-wrapper").addClass("--is-potrait-mode")
          $(".customize-controls-wrapper").addClass("--is-potrait-mode")
          $(".customize-controls-wrapper").css("height","0")
      }else{
        $(".customize-canvas-controls-wrapper").removeClass("--is-potrait-mode")
        $(".customize-controls-wrapper").removeClass("--is-potrait-mode")
        $(".customize-controls-wrapper").css("height","auto")
      }

      if(IS_SPACE_PLANNER_MODE && getWindowWidth() < 480){
        $(".hide-in-panel-open").addClass("display-none")
      }else{
        $(".hide-in-panel-open").removeClass("display-none")
      }

      $(".customize-canvas-wrapper").animate({[dimensionName]:"-="+ controlsPanelWidth +"px"},{
          duration:100, 
          easing:"linear",
          complete :function(){
              callback()
              $(".customize-product-options").addClass("--is-active")
          },
          // step:function() {
          //   callback()
          // }
      })
      $(".customize-controls-wrapper").animate({[dimensionName]:"+="+ controlsPanelWidth +"px"},{
          duration:0,
          // complete:function(){
          //     callback()
          // }
      })
      $(".customize-controls-wrapper").addClass("--is-active")
      $(".customization-customize-button").removeClass("--is-active")

      $("#menuCloseButtonMobile").removeClass("display-none")
  }  
}

export function hideControlsPanel(callback:()=>void) {
  // $(".customization-cancel-button").removeClass("--is-active")
  $(".customize-product-options").removeClass("--is-active")
  let dimensionName = "width"
  controlsPanelWidth = (getWindowWidth() / 3) > controlsPanelWidth ? controlsPanelWidth : getWindowWidth() / 3 
  let aspectRatio = getWindowWidth() / getWindowHeight()


  if(isPotraitMode() && isTouchDevice()){
    // if(getWindowWidth() <= 480 || (aspectRatio >= 0.6 && aspectRatio <= 0.8)){
      dimensionName = "height"
      controlsPanelWidth = getWindowHeight() * 0.42
  }
  $(".hide-in-panel-open").removeClass("display-none")
  //Call only when the panel is opened
  if($(".customize-controls-wrapper").hasClass("--is-active")){
    isOpenControlsPanel = false
    $(".customize-canvas-wrapper").animate({[dimensionName]:"+="+ controlsPanelWidth +"px"},{
      easing:"linear",
      duration:0,
     
    })
    $(".customize-controls-wrapper").animate({[dimensionName]:"-="+ controlsPanelWidth +"px"},{
        duration:100,
        complete :function(){
            callback()
        }
    })
    $(".customize-controls-wrapper").removeClass("--is-active")
    $("#menuCloseButtonMobile").addClass("display-none")

  }

 
}


export function getControlsPanelWidth() {
  if(window.location.pathname.includes('/edit-moodboard')){
    if(getWindowHeight() < 420){
      return 200
    }

    return 300
  }
  if(getWindowWidth() >= 1920){
    return 480
  }
  return 380
}


export function updateFraming(group:any,boundingBox:any,camera:any,controls:any,isUpdateControls:boolean=true) {
  if(camera && controls && group.children.length > 0 && !sceneBackgrounds.enabled && !sprites.isEnabled){
    // let distance = getCameraDistanceForFraming(group,boundingBox,camera)

    let cameraTarget = getCameraDistanceForFraming(group,boundingBox,camera)
    // let controlsTarget = groupBoundingBox.getCenter(target)
    let controlsTarget = getObjectDimensionPosition(null,group).center

    if(camera?.type === "PerspectiveCamera"){
      tweenCamera(camera,cameraTarget,500)
      if(isUpdateControls){
        tweenControls(controls,controlsTarget,500)
      }
    }
  }
 
 
}


export function getCameraDistanceForFraming(group:any,boundingBox:any,camera:any){
  let data = getObjectDimensionPosition(null,group)
  let box:any = data.dimensions
  let positions = data.positions
  // Get the dimension 
  let objectSize = Math.max(box.dimX,box.dimZ) 

  let delta = STORE.getCurrCategory() === "Wardrobe" || STORE.getCurrCategory() === "Dresser"  ? 2.3 :STORE.getCurrCategory() === "Swing" ? 0.7 : 1
  let distance = 0

  var fov = camera.fov * ( Math.PI / 180 ); 

  let position = positions.max.z

  let cameraPos = camera.position


  if(configuration?.groupRotation === 270){
    position = positions.max.x
  }
  if(configuration?.groupRotation === 90){
    position = positions.min.x
  }
  if(configuration?.groupRotation === 180){
    position = positions.min.z
  }

  if(getWindowWidth() > 480 ){
    distance = position + (objectSize / Math.tan(Math.PI * fov / 360))
    distance = distance / 100
  }else{
    if($(".customize-controls-wrapper").hasClass("--is-active")){ // When controls panel is open
      distance = position + (objectSize / Math.tan(Math.PI * fov / 360))
      distance = distance / 100
    }else{
      distance = Math.abs( objectSize / Math.sin( fov / 2 ) )
    }
  }


  if(configuration?.groupRotation === 90){
    return {
      x:-distance + 3,
      y:cameraPos.y,
      z:cameraPos.z
    }
  }

  if(configuration?.groupRotation === 180){
    return {
      x:cameraPos.x,
      y:cameraPos.y,
      z:-distance
    }
  }

  if(configuration?.groupRotation === 270){
    return {
      x:distance + 2,
      y:cameraPos.y,
      z:cameraPos.z
    }
  }
  
  distance = distance + delta


  let defaultX = isNaN(cameraPos.x) ? positions.max.x + delta : cameraPos.x;
  let defaultY = isNaN(cameraPos.y) ? positions.max.y + delta : cameraPos.y;

  return {
    x:defaultX,
    y:defaultY,
    z:distance
  }
}

export function isPotraitMode() {
  if(getWindowHeight() > getWindowWidth()){
    return true
  }
  return false
}

export function updateDeviceElementsVisibility() {
  if(isTouchDevice()){
    $(".touch-device-element").removeClass("hide-in-desktop")
  }
}


export function iPadPotraitModeLayout(moduleName:string) {
  return
  // Mobile css should not conflict with this 
  if(getWindowWidth() > 480 && isPotraitMode()){
    let desktopElements = document.querySelectorAll(".hide-in-desktop")
    for (let i = 0; i < desktopElements.length; i++) {
      const element = desktopElements[i];
      $(element).addClass("--is-potrait-mode")
    }

    let mobileElements = document.querySelectorAll(".hide-in-mobile")
    for (let i = 0; i < mobileElements.length; i++) {
      const element = mobileElements[i];
      $(element).addClass("--is-potrait-mode")
    }
    $(".controls-tabs-mobile").addClass("--is-potrait-mode")
    $(".product-image-container").addClass("--is-potrait-mode")
    $(".finish-image-container").addClass("--is-potrait-mode")
    $(".moodboard-canvas-wrapper").addClass("--is-potrait-mode")
    $(".moodboard-canvas-container").addClass("--is-potrait-mode")
    $(".texture-label-container").addClass("--is-potrait-mode")
    $(".controls-tab-container").addClass("--is-potrait-mode")
    $(".module-container").addClass("--is-potrait-mode")
    $(".addon-container").addClass("--is-potrait-mode")
  }else{
    if(moduleName.toLowerCase() === "customizein3d"){
      customizein3dInitialLayout()
    }
    if(moduleName.toLowerCase() === "moodboard"){
      moodboardInitialLayout()
    }
  }
}

export function moodboardInitialLayout() {
  $(".controls-tabs-mobile").removeClass("--is-potrait-mode")
  $(".product-image-container").removeClass("--is-potrait-mode")
  $(".finish-image-container").removeClass("--is-potrait-mode")
  $(".moodboard-canvas-wrapper").removeClass("--is-potrait-mode")
  $(".moodboard-canvas-container").removeClass("--is-potrait-mode")
  $(".controls-tab-container").removeClass("--is-potrait-mode")

  $(".moodboard-canvas-container").css("margin-left","0")


  let desktopElements = document.querySelectorAll(".hide-in-desktop")
  for (let i = 0; i < desktopElements.length; i++) {
    const element = desktopElements[i];
    $(element).removeClass("--is-potrait-mode")
  }

  let mobileElements = document.querySelectorAll(".hide-in-mobile")
  for (let i = 0; i < mobileElements.length; i++) {
    const element = mobileElements[i];
    $(element).removeClass("--is-potrait-mode")
  }
}


export function customizein3dInitialLayout() {
  $(".customize-canvas-wrapper").css({"width":"100%","height":"100%"})
  $(".customize-controls-wrapper").css({"width":"0","height":"0"})

  // $(".customization-customize-button").addClass("--is-active")

  $(".customize-controls-wrapper").removeClass("--is-active")
  $(".customize-canvas-controls-wrapper").removeClass("--is-potrait-mode")
  $(".customize-controls-wrapper").removeClass("--is-potrait-mode")

}

 
export function releaseCommon() {
  draggableGroup =undefined
  dragIconControls = undefined
  fontLoader =undefined
  boundingBox = undefined
}


export function setModelBoundingBox(object:any) {
  let box = getModelPositions(object,new Box3())
  object.userData.boundingBox = box
}


export function getModelBoundingBox(object:any) {
  return object.userData.boundingBox
}

export function updateModelBoundingBox(object:any) {
  let boundingBox = getModelBoundingBox(object)
  if(object.geometry && boundingBox){
    boundingBox.copy(object.geometry.boundingBox).applyMatrix4(object.matrixWorld)
  }else{
    setModelBoundingBox(object)
  }
}

export function removeAllChildrenFromMesh(object:any) {
	if(object.children){
    for (let i = object.children.length - 1; i >= 0; i--) {
      object.remove(object.children[i]);
    }
  }
}

export function updateGroupCenter(group:Group,modulesList:Array<any> = null) {
  if(!modulesList){
    modulesList = [...group.children]
  }
  if(modulesList.length){
    let center = getObjectDimensionPosition(null,group).center
    detachModules(modulesList)
    group.position.set(center.x,group.position.y,center.z)
    attachModules(modulesList,group)
  }

}

export function detachModules(modulesList:Array<any>) {
  modulesList.forEach(object => {
    detachModule(object)
  });
}

export function detachModule(object:any) {
  if(object){
    object.parent?.remove(object)
    object.matrixWorld.decompose( object.position, object.quaternion, object.scale );
  }
 
}

export function attachModules(modulesList:Array<any>,group:Group) {
  modulesList.forEach(object => {
    if(object){
      group.attach(object)
    }
  });
}


export function removeObjectFromModulesList(object:any) {
  // MODULES_LIST = MODULES_LIST.filter(currModel=>currModel.uuid !== object.uuid)
}

export function removeGroupFromScene(scene:any,group:Group) {
  while (group.children.length > 0) {
    const child = group.children[0];
    group.remove(child);
    scene.remove(child);
  }
  scene.remove(group)
}

export function renderMultipleViews(scene:any,renderer:any,canvasDimensions:any,views:Array<any>) {
  const canvasWidth = canvasDimensions.width;
  const canvasHeight = canvasDimensions.height;

  for (const view of views) {
    let currCamera = view.camera;

    const left = Math.floor(canvasWidth * view.left);
    const height = Math.floor(canvasHeight * view.height);
    const width = view.aspectRatio ? height * view.aspectRatio : Math.floor(canvasWidth * view.width);
    const bottom = view.aspectRatio ? Math.floor(canvasHeight * view.bottom) : Math.floor(canvasHeight * view.bottom);

    renderer.setViewport(left, bottom, width, height);
    renderer.setScissor(left, bottom, width, height);
    renderer.setScissorTest(true);

    updateSceneCameraAspectRatio(currCamera, width, height, 1);

    // Use requestAnimationFrame for rendering
    // requestAnimationFrame(() => {
      renderer.render(scene, currCamera);
    // });
  }
}
    

export function setRendererDimensions(renderer:any) {
  renderer.getSize(rendererDimensions) 
}

export function toScreenPosition(vector: THREE.Vector3, camera: THREE.Camera, canvas: HTMLCanvasElement) {
  vector.project(camera);
  vector.x = Math.round((0.5 + vector.x / 2) * canvas.width);
  vector.y = Math.round((0.5 - vector.y / 2) * canvas.height);
  return vector;
}


export function convert3DpointsTo2D(renderer:any,perspectiveCamera:PerspectiveCamera,orthoCamera:OrthographicCamera,position: Vector3,isSpacePlannerMode:boolean){
	let camera:any = perspectiveCamera
	if(isSpacePlannerMode){
	  camera = orthoCamera
	}
	const vector = new Vector3();

	const canvas = renderer?.domElement
  
	vector?.set(position.x,position.y,position.z)
	// vector.setFromMatrixPosition(hotspot.matrixWorld);
	vector?.project(camera)
  
	vector.x = Math.round((0.5 + vector?.x / 2) * (canvas.width))
	vector.y = Math.round((0.5 - vector?.y / 2) * (canvas.height))
	if(isSpacePlannerMode){
	  vector.y = vector.y + ($(".main-header-wrapper").height() || 0)
	}

	return vector
  }



export function cleanupScene(scene:Scene) {
  scene.children.forEach(child => {
      scene.remove(child);
      if (child instanceof Mesh) {
          // Dispose of geometry and material
          child.geometry.dispose();
          if (child.material instanceof Material) {
              child.material.dispose();
          } else if (Array.isArray(child.material)) {
              child.material.forEach(material => material.dispose());
          }
      }
      // Dispose of textures
      disposeTextures(child);
  });
}

function disposeTextures(object) {
  if (object.material) {
      if (object.material instanceof Material) {
          disposeTexture(object.material.map);
          disposeTexture(object.material.envMap);
      } else if (Array.isArray(object.material)) {
          object.material.forEach(material => {
              disposeTexture(material.map);
              disposeTexture(material.envMap);
          });
      }
  }
}

function disposeTexture(texture) {
  if (texture) {
      texture.dispose();
  }
}

 