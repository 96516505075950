import React, { useEffect, useState } from "react";
import { getTextureInfo } from "../../../../../utils/customizein3d/UImethods";
import { FabricateConfig } from "../../../../../utils/fabricate/fabricate";
import { applicationConfig, getArrayOfDistinctValues, getObjectByParameter } from "../../../../../utils/methods";
import Materials from "../../../../common/shared-components/Materials/Materials";
import DropDown from "../../../../common/ui-components/filters/DropDown";
import SummaryFooter from "./SummaryFooter";
import HorizontalScroll from "../../../../common/ui-components/scrollbar/HorizontalScroll";
 

const Controls: React.FC<{
  fabricateConfig:FabricateConfig
}> = (props) => {

  const[currCategory,setCurrCategory] = useState("No selection")
  const[currPart,setCurrPart] = useState("")

  const[products] = useState(props.fabricateConfig.productsList)
  const[partsList,setPartsList] = useState([])

  const [allMaterials]: any = useState(applicationConfig?.data?.materials);
  const [allProductMaterials,setAllProductMaterials]: any = useState([]);

  useEffect(()=>{
    props.fabricateConfig.setCurrCategory = setCurrCategory
  },[props.fabricateConfig])

  useEffect(()=>{
    let collections = getObjectByParameter(props.fabricateConfig.productsList,"categoryName",currCategory)?.collections
    if(collections){
      let collectionArray = getArrayOfDistinctValues(collections,"collectionName")
      let materials =  allMaterials.filter(currMaterial => collectionArray.includes(currMaterial.collection_name))
      setAllProductMaterials(materials)
    }else{
      setAllProductMaterials([])
    }
    let parts = []
    let productConfigs = getFilteredConfigs(currCategory)
    for (const currConfig of productConfigs) {
      parts.push(currConfig.partName)
    }
    if(parts.length){
      setCurrPart(parts[0])
      if(parts.length === 1){
        setPartsList(parts)
      }else{
        setPartsList(["All",...parts])
      }
    }else{
      setCurrPart("")
      setPartsList([])
    }
  },[currCategory])


 


  function selectProduct(value:string) {
    setCurrCategory(value)
  }

  function getFilteredConfigs(category:string) {
    let allConfigs = props.fabricateConfig.foregroundProducts
    return allConfigs.filter(currconfig => currconfig.categoryName === category)
  }


  function updateTexture(event:any) {
    let textureInfo = getTextureInfo(event)
    let productConfigs = getFilteredConfigs(currCategory)
    let companyName = textureInfo.companyName
    let collectionName = textureInfo.collectionName
    let materialCode = textureInfo.materialCode
    for (const currconfig of productConfigs) {
      let productName = currconfig.productName
      let currPart = currconfig.partName
      let suffix = `fabricate/${props.fabricateConfig.currRoom}/Foreground/${productName}/${props.fabricateConfig.currRoom}_${productName}_${companyName}_${collectionName}_${materialCode}/${props.fabricateConfig.currRoom}_${productName}_${currPart}_${companyName}_${collectionName}_${materialCode}0000.webp`
      let src = applicationConfig.aws_public_url + suffix
      props.fabricateConfig.updateImage(currconfig,src,textureInfo)
    }

  }

    
  function AddonsCategories() {
    return(
        <>
          {partsList.map((part:any,index:number)=>{
            return(
              <div className={currPart===part?"marginleft10  marginTop5 bg-color-primary color-white":"marginleft10 marginTop5 border"} key={index} style={{padding:"5px 10px",borderRadius:"20px",whiteSpace:"nowrap"}}>
                <div className='heading4 font-small text-center' onClick={()=>setCurrPart(part)}>{part}</div>
              </div>
            )
          })}
        </>
    )
  }
 
  return (
      <div className="full-height-width">

        <div className="display-flex-row full-width header-content white-card shadow-medium position-relative padding5  space-between">
          {/* <div className="width50">
            <span className="heading3 color-black">Select product</span>
          </div> */}

          <div className="full-width marginTop5">
            <DropDown
              columns={1}
              classNames="full-width storefront-dropdwon dropdown-lg"
              values={["Select Product",...getArrayOfDistinctValues(products,"categoryName")]}
              currSelectedValue={currCategory}
              action={selectProduct}
              label="Select Product"
            />
          </div>
          </div>

          <div className="full-width body-content padding5">

            {currPart && partsList.length > 1?
            <div className="full-width marginBottom5" style={{marginTop:"-0.25rem"}}>
              <HorizontalScroll
                itemsClass="sub-categories-container"
                Component={AddonsCategories}   
                itemsList={partsList}
                id={`AddAddonsScrollBar`}     
              />
            </div>
            :null}

            <div className="full-width marginTop10 position-relative">
              {allProductMaterials.length?
                <Materials
                  materials={allProductMaterials}
                  action={updateTexture}
                  moduleName="customizein3d"
                />
              :
                <div className="middle heading4">Materials Not Available</div>
              }
            </div>
          </div>

          <div className="footer-content">
            
            <SummaryFooter
              fabricateConfig={props.fabricateConfig}
            />

          </div>

      
      </div>
  );
};

export default Controls;
