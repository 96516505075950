import { appsOutline, briefcaseOutline, colorFillOutline, colorPaletteOutline, peopleOutline, speedometerOutline } from "ionicons/icons";
import $ from "jquery";
import { areaPriorityList, backgoundParts, categoryTypesMapping, foregroundParts } from "./data";
import { convertHtmlToPdf } from "./html2canvas/html2pdf";
import { hideComponentLoader, showComponentLoader, updateLoaderProgress } from "./UI_methods/global";
import { applicationConfig as applicationConfigClass} from "./moodboard/applicationConfig";
import { logout } from "../services/api";

var LOADING = document.createElement("ion-loading");

export var applicationConfig:applicationConfigClass = null

export var logger:any = null

export function globalMethodsInit(configObj:any,logs:any) {
  applicationConfig = configObj
  if(logs){
    logger = logs
  }
}

// return the user data from the session storage
export const getUser = () => {
    const userStr = localStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
  }

  export const getUserType = () => {
    let user = getUser()
    if(applicationConfig?.isShareMode){
      return "customer"
    }
    return user?.userType || null 
  }
   
  // return the token from the session storage
  export const getToken = () => {
    return localStorage.getItem('token') || null;
  }
   
  // remove the token and user from the session storage
  export const removeUserSession = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('currProduct');
    localStorage.removeItem('moduleFilters');
    applicationConfig?.resetFilters()
  }
   
  // set the token and user from the session storage
  export const setUserSession = (token:any, user:any) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    // localStorage.setItem('currUserType',user.userType);
    // localStorage.setItem('recentActivities',null);
    localStorage.setItem('moduleFilters',null);
    applicationConfig?.setIsShareMode(false)
  }

  export function updateUserSession(val:boolean,moduleName:string) {
    let user = getUser()
    if(user){
      user.isShareMode = val
      user.moduleName = moduleName
      localStorage.setItem('user', JSON.stringify(user));
      // localStorage.setItem('recentActivities',null);
      localStorage.setItem('moduleFilters',null);
      applicationConfig?.setIsShareMode(val)
    }

  }

  export function disableShareMode(){
    // removeUserSession()
    // let user = getUser()
    // user.userType = "admin"
    // localStorage.setItem('user', JSON.stringify(user));
    window.location.href = ""
  }

  export function gotoPreviousPage() {
    window.history.back()
  }

  export function showWelcomeScreen(expiryTime:number = null){
    if(window.location.pathname.toLowerCase().includes("/product-3dcustomization") || window.location.pathname.toLowerCase().includes("/customizer")){
      return
    }
    $("#welcomeScreenShare").removeClass("display-none")
    if(expiryTime){
      let label = ""
      let suffix = " Minutes"
      let time:any = 0
      let currTime = Number(new Date())
      let diff = expiryTime - currTime
      diff = diff/1000/60/60
      // console.log(diff/1000/60/60)
      if(diff < 1){
        time = Math.floor(diff * 60)
      }
      if(diff > 1){
        time = Math.round(diff)
        suffix = " Hours"
      }
      label = time + suffix
      $("#shareTokenExpiryTime").text("This link will expire in: "+label)
    }
  }

  export function getLinkExpiryTimeLabel(expiryTime:number){
    if(expiryTime){
      let label = ""
      let suffix = " Minutes"
      let time:any = 0
      let currTime = Number(new Date())
      let diff = expiryTime - currTime
      diff = diff/1000/60/60
      // console.log(diff/1000/60/60)
      if(diff < 1){
        time = Math.floor(diff * 60)
      }
      if(diff > 1){
        time = Math.round(diff)
        suffix = " Hours"
      }
      label = time + suffix
      return label
    }
    return "1 Day"
  }

  export function hideWelcomeScreen(){
    $("#welcomeScreenShare").addClass("display-none")
  }

  export function showTokenExpiryScreen(){
    $("#tokenExpiryScreen").removeClass("display-none")
  }

  export function showLogoutScreen(){
    $("#logoutScreen").removeClass("display-none")
  }

  export function hideLogoutScreen(){
    $("#logoutScreen").addClass("display-none")
  }


  export function getMaterialTypes(objectMaterialTypes:any,categoryName:string,partName:string){
    let materialTypeArray: any = [];
    for (let productPart of objectMaterialTypes) {
      if (productPart?.object_name?.toLowerCase() === partName?.toLowerCase() && productPart?.category_name?.toLowerCase() === categoryName?.toLowerCase()) {
        materialTypeArray.push(productPart.material_type);
      }
    }
    return materialTypeArray || []
  }


  export function categoryPartTypeFilteredArray(textures:Array<any>,objectMaterialTypes:Array<any>,partName:string,categoryName:string){
    let updatedTextures:Array<any> = []



    let materialTypeArray: any = [];
    for (let productPart of objectMaterialTypes) {
      if (productPart?.object_name.toLowerCase().includes(partName?.toLowerCase()) && productPart?.category_name.toLowerCase() === categoryName.toLowerCase()) {
        materialTypeArray.push(productPart.material_type);
      }
    }

    updatedTextures = textures.filter(function (texture: any) {
      return materialTypeArray.includes(texture.material_type);
    });
    disposeVariables([materialTypeArray])
    return updatedTextures
  }

  export function categoryFilteredTextures(textures:Array<any>,objectMaterialTypes:Array<any>,categoryName:string){
    let updatedTextures:Array<any> = []


    //get all material types for the category 
    let materialTypeArray: any = [];
    for (let productPart of objectMaterialTypes) {
      if (productPart?.category_name?.toLowerCase() === categoryName?.toLowerCase()) {
        materialTypeArray.push(productPart.material_type);
      }
    }
    updatedTextures = textures.filter(function (texture: any) {
      return materialTypeArray.includes(texture.material_type);
    });

    disposeVariables([materialTypeArray])
    return updatedTextures
  }

  export function isObjectCustomizable(configObj:any,materials:Array<any>,categoryName:string,partName:string) {
    let materialTypes = getMaterialTypes(configObj.data.objectMaterialTypes,categoryName,partName)
    if(!materialTypes.length){
        return false
    }
    return materials.find(material=>materialTypes.includes(material.material_type)) || false
}

  export function disposeVariables(variables:Array<any>) {
    for (let i = 0; i < variables.length; i++) {
      variables[i] = null
    }
    variables = null
  }

  export function disposeReactVariables(disposeVariablesList) {
    for (const setFun of disposeVariablesList) {
      setFun(null)
    }
  }


  export function getMaterialTypesFromPartName(partName:string,objectMaterialTypes:any,categoryName:string) {
    let materialTypeArray: any = [];
    if(partName){
      partName = stringWithoutNumbers(partName)
      for (let productPart of objectMaterialTypes) {
        if (productPart.category_name === categoryName && (productPart.object_name.toLowerCase().includes(partName.toLowerCase()) || partName.toLowerCase().includes(productPart.object_name.toLowerCase()))) {
          // if (productPart.category_name === categoryName && productPart.object_name === partName) {
          materialTypeArray.push(productPart.material_type);
        }
      }
    }
   
    return materialTypeArray
  }

  export function materialTypeFilteredTextureArray(textures:Array<any>,objectMaterialTypes:Array<any>,material:string){
    return textures.filter(function (texture: any) {
      return texture.material_type===material;
    });

  }

  export function categoryFilteredArray(textures:Array<any>,categoryMaterialTypes:Array<any>,objectMaterialTypes:Array<any>,partName:string,categoryName:string){
    let materialTypeArray: any = getMaterialTypeArrayForPartname(categoryName,partName)
    
    return  textures.filter(function (texture: any) {
      return materialTypeArray.includes(texture.material_type);
    });
  }

  export function getMaterialTypeArrayForPartname(categoryName:string,partName:string) {
    let materialTypeArray: any = []
    const objectMaterialTypes = applicationConfig?.data?.objectMaterialTypes
    for (let productPart of objectMaterialTypes) {
      if (productPart.object_name.toLowerCase() === partName.toLowerCase() && productPart.category_name.toLowerCase() === categoryName.toLowerCase()) {
        materialTypeArray.push(productPart.material_type);
      }
    }
    return materialTypeArray
  }


  export function getMaterialsListFromCategoryName(textures:Array<any>,categoryMaterialTypes:Array<any>,categoryName:string){
    let updatedTextures:Array<any> = []
    let categoryMaterialTypeArray: any = categoryMaterialTypes[categoryName]
    for (let categoryMaterial in categoryMaterialTypes) {
      if (categoryMaterial.toLocaleLowerCase() == categoryName.toLocaleLowerCase()) {
        categoryMaterialTypeArray = categoryMaterialTypes[categoryMaterial];
        break;
      }
    }
    updatedTextures = textures.filter(function (texture: any) {
      return categoryMaterialTypeArray.includes(texture.material_type);
    });
    return updatedTextures
  }


  export function getMaterialTypeArray(objectMaterialTypes:any,partname:string){
    let materialTypeArray:any = []
    for (let productPart in objectMaterialTypes) {
      if (productPart.toLocaleLowerCase() == partname.toLocaleLowerCase()) {
        materialTypeArray = objectMaterialTypes[productPart];
        break;
      }
    }
    return materialTypeArray
  }

  export function compareArrays( arrayFirst:any, arraySecond:any ){

    // console.log(arrayFirst)
    // console.log(arraySecond)

    //check if lengths are different
    if(arrayFirst.length !== arraySecond.length) return false;

    
    if(arrayFirst.length===0 || arraySecond.length===0) return false;

    //slice so we do not effect the original
    //sort makes sure they are in order
    //join makes it a string so we can do a string compare
    var cA = arrayFirst.slice().sort().join(","); 
    var cB = arraySecond.slice().sort().join(",");
    

    return cA===cB;

}


export function getObjectNamesArray(objectCanvasMapping:any){
  let arr = [] 
  for (const objectName in objectCanvasMapping) {
    arr.push(objectName)
  }
  return arr
}

export function getCollectionsFilteredTextures(textures:Array<any>,collections:Array<any>) {
  return textures?.filter(texture=>{
    if(collections.includes(texture.collection_name)){
      return texture
    }
  })
}


  export function getFilteredConfiguration(foregroundSavedConfiguration:Array<any>,projectId:number,areaId:number,currViewId:number){
    try {
      return foregroundSavedConfiguration.filter((config:any)=>config.project_id===projectId&&config.area_id===areaId&&currViewId===config.view_id)
    } catch (error) {
      // console.log(error)      
    }
  }

  export function getFilteredConfigurationByProjectAreaName(foregroundSavedConfiguration:Array<any>,projectName:number,areaName:string){
    try {
      return foregroundSavedConfiguration.filter((config:any)=>config.project_name===projectName&&config.area_name===areaName)
    } catch (error) {
      console.log(error)      
    }
  }

  export function getFilteredConfigurationByProjectAreaViewName(foregroundSavedConfiguration:Array<any>,projectName:number,areaName:string,viewName:string){
    try {
      return foregroundSavedConfiguration.filter((config:any)=>config.project_name===projectName&&config.area_name===areaName&&config.view_name===viewName)
    } catch (error) {
      console.log(error)      
    }
  }


  export function getFilteredConfigurationFromConfigName(foregroundSavedConfiguration:Array<any>,configName:string){
    try {
      return foregroundSavedConfiguration.filter((config:any)=>config.config_name===configName)
    } catch (error) {
      console.log(error)      
    }
  }

  export function getSavedConfigByName(savedConfiguration:Array<any>,configName:string){
    try {
      return savedConfiguration.filter((config:any)=>config.config_name===configName)
    } catch (error) {
      console.log(error)      
    }
  }

  export   function getForegroundImageUrl(BASE_PATH:string,clientName: string,projectName: string,areaName:string,viewName:string,productName:string,collectionName: string,materialCode: string,objectLabel: string) {
    return `./assets/images/${clientName}/${projectName}/${areaName}_${viewName}/Foreground/${productName}/${areaName}_${viewName}_${productName}_${collectionName}_${materialCode}/${areaName}_${viewName}_${productName}_${objectLabel}_${collectionName}_${materialCode}0000.png`;
  }


  export function stringWithoutNumbers(string:string) {
    return string.replace(/[0-9]/g, '');
  }

  export function stringWithoutQuotes(string:string) {
    string?.replace(" ' ", '');
    return string?.replace(' " ', '');
  }
  
  export function getTextureUrl(BASE_URL:string,companyName:string,collectionName:string,materialCode:string) {
    return `textures/${companyName}/${collectionName}/${materialCode}/${materialCode}.png`
  }

  export function getRoughnessMapURL(BASE_PATH:string,companyName:string,collectionName:string,roughness:string,){
    let url = 'thumbnails/' + "OVL" + "/" + companyName + '/' + collectionName + '/' + collectionName+"_" + 'Roughness.webp'
    // let url = 'assets/Cooper_Roughness.webp'
    return url;   
  }

  export function getNormalMapURL(BASE_PATH:string,companyName:string,collectionName:string,normalmap:string){
    // let url = 'textures/' +  companyName + '/' + collectionName + '/' + collectionName+"Common/" + 'Normal.png'
    let url = 'thumbnails/' + "OVL" + "/" + companyName + '/' + collectionName + '/' + collectionName+"_" + 'Normal.webp'
    return url;
  }

  export function getMetalnessMapURL(BASE_PATH:string,companyName:string,collectionName:string,normalmap:string){
    let url = 'textures/' +  companyName + '/' + collectionName + '/' + collectionName+"Common/" + 'Metalness.png'
    return url;
  }

  export function getSpecularMapURL(BASE_PATH:string,companyName:string,collectionName:string,normalmap:string){
    let url = 'textures/' +  companyName + '/' + collectionName + '/' + collectionName+"Common/" + 'Specular.png'
    return url;
  }

  export function getBackgroundurl(BASE_PATH:string,clientName: string,projectName: string,areaName:string,viewName:string,objectName:string) {
      return `./assets/images/${clientName}/${projectName}/${areaName}_${viewName}/Background/Background/${areaName}_${viewName}_Background0000.png`;
  }

  export function getRandomArray(array:any){
    let length = array.length
    let randomNumber = Math.floor(Math.random() * length)
    return array[randomNumber]
  }

  
  export   function getMaterialsImageUrl(BASE_PATH:string,companyName: string,collectionName:string,materialCode:string) {
    return `thumbnails/OVL/${companyName}/${collectionName}/${materialCode}.png`;
  }

  export function getObjectByParameter(array:any,paramenterName:string,value:any) {
    try {
      let res = null
      array?.forEach(function(object:any){
        if(stringWithoutWhiteSpace(String(object[paramenterName]))===stringWithoutWhiteSpace(String(value))){
          res = object
        }
      })
      return res 
    } catch (error) {
      console.log(error)
    }
  }

  export function getObjectIndexByParameter(array:any,paramenterName:string,value:any) {
    try {
      for (const index in array) {
        let currItem = array[index]
        if(currItem[paramenterName] === value){
          return index
        }
      }
      return 0
    } catch (error) {
      console.log(error)
    }
  }


  export function getProductName(objectName:string){
    let array = objectName.split(".")
    return array[3] 
    // return  objectName.substring(0,objectName.indexOf('.'))
  }

  export async function waitFor(time:number) {
    return new Promise(resolve=>{
      setTimeout(() => {
        resolve("Done")
      }, time);
    })
  }

  export async function waitForDummyLoader(id:string,message:string,time:number = 1000) {
    showComponentLoader(id)
    updateLoaderProgress(id,1,1,message)
    return new Promise(resolve=>{
      setTimeout(() => {
        hideComponentLoader(id)
        resolve("Done")
      }, time);
    })
  }

  export function getCategoryName(objectName:string){
    let array = objectName.split(".")
    return array[2] 
    // return  objectName.substring(0,objectName.indexOf('.'))
  }

  export function searchTexture(string:string,array:Array<any>){
    let updatedArray = array.filter((texture:any)=>{
      return texture.company_name.toLowerCase().includes(string.toLowerCase()) || texture.collection_name.toLowerCase().includes(string.toLowerCase()) || texture.material_code.toLowerCase().includes(string.toLowerCase()) 
    })
    return updatedArray
  }

  export function getViewName(objectName:string){
    let array = objectName?.split(".")
    return array[1] 
    // return  objectName.substring(0,objectName.indexOf('.'))
  }


  export function getPartName(objectName:string){
    return objectName?.split(".")[4] || null
    // return objectName.substr(objectName.indexOf('.')+1)
  }

  export function getProductConfigMappingArray(currentConfigObject:any,projectName:string,areaName:string,viewName:string){
    let key = getCurrentConfigurationKey(projectName,areaName)
    let configuration = getCurrentConfigurationFromLocalStorage(key)
    let filteredConfig:any = {}
    for (const config in configuration) {
      if(config.includes(viewName)){
        filteredConfig[config] = configuration[config]
      }
    }
    configuration = filteredConfig
    // let productSet = new Set()
    // for (const objectName in configuration) {
    //   if(viewName===getViewName(objectName)){
    //     let productName = getProductName(objectName)
    //     productSet.add(productName)
    //   }
    // }
    // productArray = Array.from(productSet)

    // let productConfigMapping:any = {}
    // let productConfigMappingArray = []
    // for (const product of productArray) {
    //   let partConfigArray = []
    //   for (const objectName in configuration) {
    //     if(viewName===getViewName(objectName)){
    //       if(objectName.includes(String(product))){
    //         let partName = getPartName(objectName)
    //         partConfigArray.push({partname:partName,config:configuration[objectName]})
    //       }
    //     }
    //   }
    //   productConfigMapping.productName = product
    //   productConfigMapping.productParts = partConfigArray
    //   productConfigMappingArray.push(productConfigMapping)
    //   productConfigMapping = {}
    //   partConfigArray = []
    // }

    let formatedConfiguration:any = []

    for (const configkey in configuration) {
      let productName = getProductName(configkey)
      let productCategory = getCategoryName(configkey)
      let partName = getPartName(configkey)
      let currObj = configuration[configkey]
      let configObj:any = {
        area_id:'',
        area_name:areaName,
        collection_name:currObj.collectionName,
        color_option_id:currObj.colorOptionId,
        company_name:currObj.companyName,
        config_name:'',
        customizable_object_id:'',
        hex_code:'',
        id:'',
        material_code:currObj.materialCode,
        material_id:currObj.materialId,
        part_name:partName,
        product_id:currObj.productId,
        product_name:productName,
        project_id:'',
        project_name:projectName,
        view_id:'',
        view_name:viewName,
        category_name:productCategory,
        config_image:currObj.configImage
      }
      formatedConfiguration.push(configObj)
    }
    return formatedConfiguration
  }


  export function getProductConfigMappingArrayFromLocalStorage(projectName:string,areaName:string,viewName:string){
    let key = getCurrentConfigurationKey(projectName,areaName)
    let configuration = getCurrentConfigurationFromLocalStorage(key)
    let filteredConfig:any = {}
    for (const config in configuration) {
      if(config.includes(viewName)){
        filteredConfig[config] = configuration[config]
      }
    }
    configuration = filteredConfig
  
    let formatedConfiguration:any = []

    for (const configkey in configuration) {
      let productName = getProductName(configkey)
      let productCategory = getCategoryName(configkey)
      let partName = getPartName(configkey)
      let currObj = configuration[configkey]
      let configObj:any = {
        area_id:'',
        area_name:areaName,
        collection_name:currObj.collectionName,
        color_option_id:currObj.colorOptionId,
        company_name:currObj.companyName,
        config_name:'',
        customizable_object_id:'',
        hex_code:'',
        id:'',
        material_code:currObj.materialCode,
        material_id:currObj.materialId,
        part_name:partName,
        product_id:currObj.productId,
        product_name:productName,
        project_id:'',
        project_name:projectName,
        view_id:'',
        view_name:viewName,
        category_name:productCategory,
        config_image:currObj.configImage
      }
      formatedConfiguration.push(configObj)
    }

    disposeVariables([configuration,filteredConfig,configuration])
    return formatedConfiguration
  }


  export function setCurrentConfigurationInLocalStorage(key:string,data:any){
    localStorage.setItem(key,JSON.stringify(data))
  }

  export function getCurrentConfigurationFromLocalStorage(key:string){
    return JSON.parse(localStorage.getItem(key))! || null
  }
  
  export function   getCurrentConfigurationFromClass(currentConfigObject:any,key:any){
    return currentConfigObject.configuration[key]
  }

  
//   export function getAreaViewList(products:Array<any>){
//     let views:any = new Set()
//     products.forEach(product=>{
//         views.add(product.view_name)
//     })
//     return Array.from(views)
// }


  export function getFilteredProducts(products:Array<any>,projectId:number,areaId:number){

    return products.filter((product:any)=>product.project_id===projectId&&product.area_id===areaId)

  }


  export function getFilteredArray(array:Array<any>,key:string,value:any){
    return array?.filter((arrayItem:any)=>String(arrayItem[key])===String(value))
  }

  export function getClientName(product:any){
    return product?.storefront_name === "OVL" ? "OVL" : applicationConfig?.clientName
  }

  export function getCurrentConfigurationKey(projectName:string,areaName:string){
    return `Config_${projectName}_${areaName}`
  }

  export function getProductPartConfigKey(areaName:string,viewName:string,productName:string,partname:string,categoryName:string){
    return stringWithoutWhiteSpace(`${areaName}.${viewName}.${categoryName}.${productName}.${partname}`)
  }
  
  export async function dismissLoading() {
    await LOADING?.dismiss();
  }

  export function getViewIdFromName(viewName:string,allViews:any){
    let arr = allViews.filter((view:any)=>view.view_name.toLowerCase()===viewName.toLowerCase())
    return arr[0].id
  }

  export function getPartIdFromName(partName:string,allParts:any){
    let arr = allParts.filter((part:any)=>part.object_name.toLowerCase()===partName.toLowerCase())
    return arr.length?arr[0].id:null
  }

  export function getProductParts(categoryName:string){
    return foregroundParts[categoryName]
  }

  export async function getColorDetailsFromId(colorOptions:Array<any>,colorId:any){
    return colorOptions.filter((color:any)=>color.id===parseInt(colorId))[0] || []
  }

  export function getViewFromProjectAreaId(allProducts:Array<any>,projectId:number,areaId:number){
    let viewSet = new Set()
    allProducts.filter((product:any)=>{
      if(product.project_id===projectId&&product.area_id===areaId){
        viewSet.add(product.view_name)
      }
    })
    return Array.from(viewSet)
  }

  export async function createLoader(message:string) {
    LOADING.cssClass = "main-loader";
    LOADING.message = message;
    LOADING.translucent = true;
    LOADING.mode="ios"
    document.body.appendChild(LOADING);
    await LOADING.present();
  }

  export function getArrayOfDistinctValuesFromArray(array:Array<any>){
    if(array){
      let set = new Set();
      for (let values of array) {
        set.add(values);
      }
      return Array.from(set)
    }
    return []
  }
  export function getObjectArrayOfDistictValues(array:Array<any>,parameter:string){
    if(!array){
      return []
    }
    let distictValues = []
    let resultArray  = []
    for (let values of array) {
      if(!distictValues.includes(values[parameter])){
        resultArray.push(values)
      }
      distictValues.push(values[parameter]);
    }
    disposeVariables([distictValues])
    return resultArray
  }
  export function getArrayOfDistinctValues(array:Array<any>,parameter:string){
    if(array){
      let set = new Set();
      for (let values of array) {
        set.add(values[parameter]);
      }
      return Array.from(set)
    }
    return []
  }

  export function getArrayOfDistinctValuesFromObject(object:Array<any>,parameter:string){
    let set = new Set();
    for (let key in object) {
      set.add(object[key][parameter]);
    }
    return Array.from(set)
  }



  export function getFormatedSavedConfigs(foregroundSavedConfiguration:any,viewName:string){
    // if(foregroundSavedConfiguration.length===0){
    //   return null
    // }
    let mainArray:any = []
    let formatedConfigs:any = {}
    let configsArray:any = []
    let currentConfigName:any = null

    let flag = true


    let configsSet = new Set()

    let distinctConfigsArray = [] 
    foregroundSavedConfiguration.forEach((object:any,index:number) => {
      // if(object.view_name===viewName){
        configsSet.add(object.config_name)
      // }
    });

    distinctConfigsArray = Array.from(configsSet)

    distinctConfigsArray.forEach((configName:any)=>{

      let configsArray:any = []
      foregroundSavedConfiguration.forEach((object:any,index:number) => {
        if(object.config_name===configName){
          configsArray.push(object)
        }
      });
      mainArray.push({configName:configName,configs:configsArray})
    })


    disposeVariables([formatedConfigs,configsArray,distinctConfigsArray,configsArray])

    return mainArray

    foregroundSavedConfiguration.forEach((object:any,index:number) => {
        //For the current view
        if(object.view_name===viewName){
          //Initial config name first one 
          if(index===0){
            currentConfigName = object.config_name
          }
          //check if the config name is same append the array 
          if(currentConfigName===object.config_name){
            configsArray.push(object)
            formatedConfigs[object.config_name] = configsArray
          }else{//Else change config name 
            flag = false
            mainArray.push({configName:currentConfigName,configs:configsArray})
            currentConfigName = object.config_name
            configsArray = []
            configsArray.push(object)
          }
        }
      });
      if(flag){
        mainArray.push({configName:currentConfigName,configs:configsArray})
      }
    return mainArray
  }

  
  export function getLoggedInUserName(){
    let user:any = localStorage.getItem("user")
    user = JSON.parse(user)
    return user?.username || "guest"
  }


  export function getIdFromName(array:Array<any>,idName:string,parameter:string,value:string) {
    try {
      let filterdData = array.filter((object:any)=>object[parameter]===value)
      return filterdData[0][idName]
    } catch (error) {
      console.log(error)
    }
   
  }

  export function stringWithoutWhiteSpace(string:string) {
    try {
      if(!string || string===""){
        return ""
      }
      return string?.replace(/ /g,'')
    } catch (error) {
      return ""
    }

  }

  export function getAllProductsFromConfig(configuration:any){
    let productSet:any = []
    let productsArray:any = []

    for (const key in configuration) {
      let config = configuration[key]
      let productName = getProductName(key)
      let categoryName = getCategoryName(key)
      let myObj = {productName:productName,categoryName:categoryName}
      // if(config.view_name===props.currentConfigObject?.viewName){
          if(!productsArray.includes(JSON.stringify(myObj))){
              productsArray.push(JSON.stringify(myObj))
              productSet.push(myObj)
          }
      // }
    
  }

    
 

    // for (const product of productSet) {
    //     for (const config of configuration) {
    //             if(config.product_name===product.productName){
    //                 let myObj = {collectionName:config.collection_name,materialCode:config.material_code}
    //                 product.configuration.push(myObj)
    //             }
    //     }
    // }

    return productSet
}


export function getApplicationFiltersMaterials(applicationName:string,allMaterials:Array<any>,applicationMaterials:Array<any>) {
  applicationMaterials = getFilteredArray(applicationMaterials,"application_name",applicationName)
  // let companyList = getArrayOfDistinctValuesFromObject(applicationMaterials,"company_id")
  // let collectionList = getArrayOfDistinctValuesFromObject(applicationMaterials,"collection_id")
  // return allMaterials.filter(currMaterial=> companyList.includes(currMaterial.company_id) && collectionList.includes(currMaterial.collection_id))
  let companyCollectionMaterialsType = getArrayOfDistinctValuesFromObject(applicationMaterials,"company_collection_materialtype_id")
  return allMaterials?.filter(currMaterial=> companyCollectionMaterialsType.includes(currMaterial.company_collection_materialtype_id)) || []
}

export function getProjectFiltersMaterials(materialsList:Array<any>,projectMaterials:Array<any>,projectId:number) {
  // projectMaterials = getFilteredArray(projectMaterials,"project_id",projectId)
  // let companyList = getArrayOfDistinctValuesFromObject(projectMaterials,"company_id")
  // let collectionList = getArrayOfDistinctValuesFromObject(projectMaterials,"collection_id")
  // return materialsList?.filter(currMaterial=> companyList.includes(currMaterial.company_id) && collectionList.includes(currMaterial.collection_id)) || []
  let projectMaterialsList = getFilteredArray(applicationConfig?.data?.projectMaterials,"project_id",projectId)
  let companyCollectionMaterialsType = getArrayOfDistinctValuesFromObject(projectMaterialsList,"company_collection_materialtype_id")
  return materialsList?.filter(currMaterial=> companyCollectionMaterialsType.includes(currMaterial.company_collection_materialtype_id))

}

export function getCategoryFiltersMaterials(materialsList:Array<any>,projectMaterials:Array<any>,categoryId:number) {
  projectMaterials = getFilteredArray(projectMaterials,"category_id",categoryId)
  let companyList = getArrayOfDistinctValuesFromObject(projectMaterials,"company_id")
  let collectionList = getArrayOfDistinctValuesFromObject(projectMaterials,"collection_id")
  return materialsList?.filter(currMaterial=> companyList.includes(currMaterial.company_id) && collectionList.includes(currMaterial.collection_id)) || []
}

export function getModulePrice(productModulesPrice:any,collectionName:string,moduleId:number) {
  return productModulesPrice?.filter((currdata:any)=>currdata.collection_name===collectionName  && currdata.module_id===moduleId )[0]?.price || 5482
}

export function removeElementFromArray(array:Array<any>,value:string) {
  let index = array.indexOf(value)
  
  if(index === -1){
    return array
  }
  return array.splice(index,1)
}

export function getCategoryMaterialTypesArray(categoryName:any,categoryMaterials:any){
  let array:any = []
  categoryMaterials.forEach(function(currValue:any){
      if(currValue.category_name==categoryName){
          array.push(currValue.material_type)
      }
  })
  return array
}
export function getMaterialTypesArray(objectName:any,objectMaterials:any){
  let array:any = []
  objectMaterials.forEach(function(currValue:any){
      if(currValue.object_name==objectName){
          array.push(currValue.material_type)
      }
  })
  return array
}


export function getBackgroundParts(areaId:any){
  return backgoundParts[areaId]
}



export function getForegroundParts(products:Array<any>,areaName:string,viewName:string){
  let data = []
  // viewName = viewName.substring(areaName.length)
  //update products based on view
  products = products.filter((currProduct,index)=>currProduct.view_name===viewName)
  for (const product of products) {
  //   productIds.push(product.product_id)
      let productName = product.product_name
      // if(product.product_variant){
      //   productName = productName + "_" + product.product_variant
      // }
    let productParts = foregroundParts[product.category_name]
    productParts?.push("Other")
    if(productParts){
      for (const partname of productParts) {
        
          let productKey = getProductPartConfigKey(areaName,viewName,productName,partname,product.category_name)
          data.push({label:partname,partname:productKey,category:product.category_name})
        }
    }
    
  }
  return data
}



export async function shareProject(configObj:any,moduleName:string) {
  showComponentLoader("sharePageLoader")
  await waitFor(500)
  let navigatorObject:any = window.navigator
  if(!navigatorObject?.share){
    return
  }

  let shareItemName = configObj?.projectName || "Summary"
  if(moduleName==="Customizein3D"){
    shareItemName = configObj?.productName
  }
  let doc = null
  doc = convertHtmlToPdf(moduleName,configObj,[],shareItemName,false)

  await doc.then(url=>{
    fetch(url).then(res=>{
      return res.blob()
    }).then(async data=>{
        let file = new File([data], "share.pdf", {type: 'application/pdf'});
        let filesArray = [file];
        await navigatorObject.share({
          title: `Sharing details for ${shareItemName}`,
          files:filesArray
        }).then(() => {
            hideComponentLoader("sharePageLoader")
        }).catch(err => {
            console.log(err);
            hideComponentLoader("sharePageLoader")
        });
    }).catch(err=>{
      console.log(err)
      hideComponentLoader("sharePageLoader")
    })
  }).catch(err=>{
    console.log(err)
    hideComponentLoader("sharePageLoader")
  })
}


export async function share2DRender(cameraName:any,src:string) {
  showComponentLoader("sharePageLoader")
  await waitFor(500)
  let navigatorObject:any = window.navigator
  if(!navigatorObject?.share){
    return
  }

  let shareItemName = cameraName || "Summary"
  

  fetch(src).then(res=>{
    return res.blob()
  }).then(async data=>{
      let file = new File([data], "render.png", {type: 'application/pdf'});
      let filesArray = [file];
      await navigatorObject.share({
        title: `Sharing details for ${shareItemName}`,
        files:filesArray
      }).then(() => {
          hideComponentLoader("sharePageLoader")
      }).catch(err => {
          console.log(err);
          hideComponentLoader("sharePageLoader")
      });
  }).catch(err=>{
    console.log(err)
    hideComponentLoader("sharePageLoader")
  })
}


export function goback() {
  window.history.back()
}


export function checkIfLastElement(array:Array<any>,value:string) {
  if(value === array[array.length-1]){
    return true
  }
  return false
}

export function checkIfFirstElement(array:Array<any>,value:string) {
  if(value === array[0]){
    return true
  }
  return false
}


export async function openNativeShareOptions(url:string,shareItemName:string,loaderId:string) {
  showComponentLoader(loaderId)
  let navigatorObject:any = window.navigator
  if(!navigatorObject?.share){
    return
  }
  await navigatorObject.share({
    title: `Sharing details for ${shareItemName}`,
    url:url
  }).then(() => {
      hideComponentLoader(loaderId)
  }).catch(err => {
      console.log(err);
      hideComponentLoader(loaderId)
  });
}


// export function getFormatedPrice(price:any) {
//   price = String(price)
//   if(!price || price === "undefined"){
//     return "0"
//   }
//   if(price.length <= 3){
//     return price
//   }
//   let array = price?.split("").reverse() 
//   array?.splice(3,0,",")
//   return array?.reverse().join("") || "0"
// }

export function generateSessionId(length){
  //edit the token allowed characters
  var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
  var b = [];  
  for (var i=0; i<length; i++) {
      var j = (Math.random() * (a.length-1)).toFixed(0);
      b[i] = a[j];
  }
  return b.join("");
}

export function getCollectionLabel(moodboardId:string) {
  //Saved configurations of projectID
  let filteredConfig = getFilteredArray(applicationConfig?.data.moodBoardSavedConfigs,"moodboard_id",moodboardId);
  //Get Areas for which configs area saved
  let savedConfigAreas = getArrayOfDistinctValues(filteredConfig,"area_name") || []

  let labelName = "Edit Collections";
  if (savedConfigAreas.length) {
    labelName = "Continue Designing";
  }
  if (!savedConfigAreas.length) {
    labelName = "Start Designing";
  }
  return labelName
}

export function getRouteFromSubDomain() {
  let domain = window.location.hostname
  domain = domain.toLowerCase()

  if(domain.includes("collections")){
    return "/collections"
  }

  if(domain.includes("products")){
  return "/product-categories"
  }


  return "/"
}


export function getSubDomainRouteList(subDomain:string) {

  switch (subDomain.toLowerCase()) {
    case "collections":
      return ["/collections","/edit-moodboard"]
    break;

    case "products":
      return ["/products","/product-categories","/product-3dcustomization"]
    break;
  
    default:
    break;
  }

  return []
}

export function getSubDomainName(){
  
  let subdomain = window.location.hostname.split('.')[0]
  if(window.location.hostname.includes("ssh") || window.location.hostname.includes("thelevelfurniture")){
    subdomain = "store"
  }
  return subdomain
}

export function isRouteExistsInSubDomain(routeName:string) {

  let subDomain = getSubDomainName()
 
  //For subdomain collection
  if(subDomain === "collections"){
    if(getSubDomainRouteList(subDomain).includes(routeName)){
      return true
    }else{
      return false 
    }
  }

  if(subDomain === "products"){
    if(getSubDomainRouteList(subDomain).includes(routeName)){
      return true
    }else{
      return false 
    }
  }

  //For opus return true .. allroutes available
  return true 
}


export function getTabslistFromSubDomain() {
  let domain = window.location.hostname
  domain = domain.toLowerCase()
  let subDomain = domain.substring(domain.indexOf(".")+1,domain.indexOf(".ovlapps"))
  let user = getUser()
  let userType = getUserType()
  let moduleName = user.moduleName || null
  let userTabs = []

  //For subdomain collection
  if(subDomain === "collections"){
    userTabs = [{tabName:"Collections",tabIcon:appsOutline,link:"/collections"}]
    
  }

  if(subDomain === "products"){
    userTabs = [
      {tabName:"Products",tabIcon:colorFillOutline,link:"/product-categories"},
    ]
  }
  //For opus return true .. allroutes available
  userTabs = [
    // {tabName:"Dashboard",tabIcon:speedometerOutline,link:"/home"},
    {tabName:"Projects",tabIcon:briefcaseOutline,link:"/projects"},
    {tabName:"Products",tabIcon:colorFillOutline,link:"/product-categories"},
    // {tabName:"Collections",tabIcon:appsOutline,link:"/collections"},
    // {tabName:"Finish",tabIcon:colorPaletteOutline,link:"/finish"},
    // {tabName:"store",tabIcon:briefcaseOutline,link:"/store/products"},
    // {tabName:"Users",tabIcon:peopleOutline,link:"/users"},
  ]

  if(userType==="customer" ){
    for (const tab of userTabs) {
      if(tab.tabName.toLowerCase() === moduleName.toLowerCase()){
        return [tab]
      }
    }
  }

  return userTabs
  
}



export function sideBarToggle(){
  let subDomain = getSubDomainName()

  if(subDomain === "collections"){
    $(".main-side-bar").css({"display":"none","overflow":"hidden"})
    $(".header-content-wrapper").css({"width":"100%"})
    
  }

  if(subDomain === "products"){
    $(".main-side-bar").css({"display":"none","overflow":"hidden"})
    $(".header-content-wrapper").css({"width":"100%"})
  }
  // $(".main-side-bar").css({"display":"none","overflow":"hidden"})
  // $(".header-content-wrapper").css({"width":"100%"})

}


export function sortAreasByPriority(areaList: Array<any>) {
  let newList = areaList.map((area) => {
    area["priority"] = areaPriorityList[area.area_name] || 999;
    return area;
  });
  newList.sort((a: any, b: any) => (a.priority > b.priority ? 1 : -1));
  return newList.sort((a: any, b: any) => (a.priority > b.priority ? 1 : -1));
}

export function getRandomString(length:number,charset:string = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789") {
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    result += charset.charAt(randomIndex);
  }
  return result;
}


export function getCleanedStringForDatabase(string:any) {
  return string.replace(/['*&^%$#@!~:;]/g, '');
}



export const logoutHandle = () =>{
  logout()
    .then((response:any) => {
      removeUserSession();
      window.location.href = ""
    })
    .catch((error:any) => {
      removeUserSession();
      window.location.href = ""
    });
}

export function filterProductsWithCategoryType(username:string,productsList:Array<any>) {
  let filteredProducts = []
  if(applicationConfig?.clientName === "DemoClient2"){
    let result = categoryTypesMapping.find(currValue => currValue.username === username)
    if(result){
      let categories = result.categories
      filteredProducts = productsList.filter(currProduct => categories.includes(currProduct.category_name))
      return filteredProducts
    }
  }
  return productsList
}

export function getClientFilteredMaterials(materials:any) {
  if(applicationConfig.clientName === "SiddhiSofas"){
    materials = materials.filter(currMaterial=> currMaterial.company_name === "Tesa" || currMaterial.company_name === "OVL" || currMaterial.company_name === "Sarom" || currMaterial.company_name === "Click")
  }
  if(applicationConfig.clientName === "GoodTimeFurniture"){
    materials = materials.filter(currMaterial=> currMaterial.company_name === "Stanley" || currMaterial.company_name === "GoodTime")
  }
  if(applicationConfig.clientName === "DashSquare"){
    materials = materials.filter(currMaterial=> currMaterial.company_name === "HTL" || currMaterial.company_name === "Kuka")
  }
  if(applicationConfig.clientName === "WoodmartFurniture"){
    materials = materials.filter(currMaterial=> currMaterial.company_name === "Darpan" || currMaterial.company_name === "OVL" || currMaterial.company_name === "Sarom")
  }
  if(applicationConfig.clientName === "AttalsFurnitureWorks"){
    materials = materials.filter(currMaterial=> currMaterial.company_name === "Darpan" || currMaterial.company_name === "OVL" || currMaterial.company_name === "Sarom")
  }
  if(applicationConfig.clientName === "Encaasa"){
    materials = materials.filter(currMaterial=> currMaterial.company_name === "Sarom" || currMaterial.company_name === "OVL")
  }
  if(applicationConfig.clientName === "OVL"){
    materials = materials.filter(currMaterial=> currMaterial.company_name === "Mittal" ||  currMaterial.company_name === "Sarom" || currMaterial.company_name === "OVL" || currMaterial.company_name === "Darpan" || currMaterial.company_name === "Tesa")
  }
  if(applicationConfig.clientName === "ZulaHouse"){
    materials = materials.filter(currMaterial=> currMaterial.company_name !== "Darpan" && currMaterial.company_name !== "Sarom" && currMaterial.company_name !== "Acrylic")
  }
  return materials
}

export function getSubCategoryDisplayName(name:string) {
  if(name?.toLowerCase().includes("lshape")){
    return "Sectional"
  }
  return name
}